const handleChange = async (event, input, fileName) => {
  event.preventDefault();
  const file = event.target.files[0];
  file.id = fileName;
  input.onChange(event.target.files[0]);
};

export const ReduxFormFileField = ({ input, fileName, itemname }) => {
  console.log(input, fileName, itemname);
  return (
    <>
      <button
        className="flex rounded-lg bg-secondaryDark text-white  font-semibold justify-center items-center"
        type="button"
      >
        <label className="flex-1 px-5 py-2" htmlFor={`${input?.name}`}>
          {`Select ${itemname}`}
        </label>
      </button>
      <input
        id={`${input?.name}`}
        className="hidden"
        name={input.name}
        type="file"
        //accept="image/*"
        onChange={(event) => handleChange(event, input, fileName)}
      />
    </>
  );
};
