import LoginPage from "components/login/LoginPage";
import Navbar from "components/navbar/Navbar";
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";
import "./App.css";

const App = () => {
  return (
    <>
      <Router>
        <Routes>
          <Route path="/login" element={<LoginPage />} exact />
          <Route path="*" element={<Navbar />} />
          <Route path="" element={<Navigate to="/login" />} />
        </Routes>
      </Router>
    </>
  );
};

export default App;
