import { Button, Checkbox, Select } from "antd";
import { useState, useEffect } from "react";
import { MdDelete } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { getFilteredColorList, masterSelectors } from "slices/masterSlice";

function useDebounce(value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
}

const EntryTableV2 = ({
  addRow,
  data,
  setData,
  mainItemsOptions1,
  colorOptions,
  readyStockData,
  setReadyStockData,
  tempStockData,
  isFetchingDropdownList,
  isUpdatingMaster,
  handleFormSubmission,
  isUpdated,
  setTempStockData,
}) => {
  const { id } = useParams();
  const filteredColorList = useSelector(
    masterSelectors?.getFilteredColorDropdownList
  );

  const dispatch = useDispatch();

  const sizes = [85, 90, 95, 100, 105, 110, 115, 120];

  const [debouncedData, setDebouncedData] = useState(data);
  const [hasNegativeStock, setHasNegativeStock] = useState(false);
  const [negativeCells, setNegativeCells] = useState({});

  const debouncedValue = useDebounce(debouncedData, 500);

  useEffect(() => {
    updateReadyStockData(debouncedValue);
  }, [debouncedValue]);
  const updateReadyStockData = (currentData) => {
    let tempReady = JSON.parse(JSON.stringify(tempStockData));
    let hasNegative = false;
    let newNegativeCells = {};

    const groupedEntries = {};

    currentData.forEach((item) => {
      if (item.mainitem && item.color) {
        const key = `${item.mainitem}-${item.color}`;
        if (!groupedEntries[key]) {
          groupedEntries[key] = {
            mainitem: item.mainitem,
            color: item.color,
            sizes: {},
            rowIndices: [],
          };

          sizes.forEach((size) => {
            groupedEntries[key].sizes[`size${size}`] = 0;
          });
        }
        groupedEntries[key].rowIndices.push(currentData.indexOf(item));

        sizes.forEach((size) => {
          const sizeKey = `size${size}`;
          const itemValue = Number(item[sizeKey] || 0);
          groupedEntries[key].sizes[sizeKey] += itemValue;
        });
      }
    });

    Object.values(groupedEntries).forEach((group) => {
      const readyIndex = tempReady.findIndex(
        (stockItem) =>
          stockItem.color === group.color &&
          stockItem.mainitem === group.mainitem
      );

      if (readyIndex !== -1) {
        const negativeSizeFlags = {};

        sizes.forEach((size) => {
          const sizeKey = `size${size}`;
          const stockValue = Number(tempStockData[readyIndex]?.[sizeKey]) || 0;
          const deductionValue = group.sizes[sizeKey];

          const newValue = stockValue - deductionValue;

          if (newValue < 0) {
            hasNegative = true;
            negativeSizeFlags[sizeKey] = true;

            group.rowIndices.forEach((rowIndex) => {
              if (!newNegativeCells[rowIndex]) {
                newNegativeCells[rowIndex] = {};
              }
              newNegativeCells[rowIndex][sizeKey] = true;
            });
          }

          tempReady[readyIndex][sizeKey] = newValue;
        });

        tempReady[readyIndex].backgroundColor = "yellow";
      }
    });

    setReadyStockData(tempReady);
    setHasNegativeStock(hasNegative);
    setNegativeCells(newNegativeCells);
  };

  const handleChange = (value, index, type, obj) => {
    let tempData = [...data];
    if (type === "partyName") tempData[index].partyName = value;
    if (type === "comment") tempData[index].comment = value;

    if (type.startsWith("size")) {
      tempData[index][type] = value;

      const readyIndex = readyStockData.findIndex(
        (item) =>
          item.color === tempData[index]?.color &&
          item.mainitem === tempData[index]?.mainitem
      );

      if (readyIndex !== -1) {
        const updatedReadyStock = [...readyStockData];
        const stockValue = Number(tempStockData[readyIndex]?.[type]) || 0;
        const itemValue = Number(value || 0);

        // Calculate the difference and update it in real-time
        const deductedValue = stockValue - itemValue;

        tempData[index].font = deductedValue < 0 ? "bold" : "";
        updatedReadyStock[readyIndex] = {
          ...updatedReadyStock[readyIndex],
          [type]: deductedValue,
        };

        setReadyStockData(updatedReadyStock);
      }
    }

    setData(tempData);
    setDebouncedData(tempData); // Update the debounced data
  };

  const handleDelete = (index) => {
    let tempReady = [...readyStockData];
    let tempStock = [...tempStockData];

    let tempData = data;

    const readyIndex = tempStock?.findIndex(
      (item) =>
        item.color === tempData[index]?.color &&
        item.mainitem === tempData[index]?.mainitem
    );

    const mainitem = tempData[index]?.mainitem;
    const color = tempData[index]?.color;

    const itemCount = tempData.reduce((count, item) => {
      if (item?.mainitem === mainitem && item?.color === color) {
        count++;
      }
      return count;
    }, 0);

    if (tempReady[readyIndex] !== undefined) {
      tempReady[readyIndex] = {
        ...tempReady[readyIndex],
        size85:
          Number(tempReady[readyIndex]?.size85) +
          Number(tempData[index].size85),
        size90:
          Number(tempReady[readyIndex]?.size90) +
          Number(tempData[index].size90),
        size95:
          Number(tempReady[readyIndex]?.size95) +
          Number(tempData[index].size95),
        size100:
          Number(tempReady[readyIndex]?.size100) +
          Number(tempData[index].size100),
        size105:
          Number(tempReady[readyIndex]?.size105) +
          Number(tempData[index].size105),
        size110:
          Number(tempReady[readyIndex]?.size110) +
          Number(tempData[index].size110),
        size115:
          Number(tempReady[readyIndex]?.size115) +
          Number(tempData[index].size115),
        size120:
          Number(tempReady[readyIndex]?.size120) +
          Number(tempData[index].size120),
        backgroundColor: itemCount >= 2 ? "yellow" : "",
      };
      setReadyStockData([...tempReady]);
    }
    tempData.splice(index, 1);
    setData([...tempData]);
  };

  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const onMainItemChange = (value, index) => {
    let tempData = data;
    tempData[index].mainitem = value;
    setData([...tempData]);
    dispatch(
      getFilteredColorList({
        masterName: "outward/color_dropdown",
        mainItem: value,
      })
    );
  };

  const onChangeDefaultColor = (index) => {
    let tempData = data;
    console.log(tempData[index]);
    let tempReady1 = [...readyStockData];
    const readyIndex1 = readyStockData?.findIndex(
      (item) =>
        item.color === tempData[index]?.color &&
        item.mainitem === tempData[index]?.mainitem
    );

    if (tempReady1[readyIndex1] !== undefined) {
      tempReady1[readyIndex1] = {
        ...tempReady1[readyIndex1],
        size85:
          Number(tempReady1[readyIndex1]?.size85) +
          Number(tempData[index].size85),
        size90:
          Number(tempReady1[readyIndex1]?.size90) +
          Number(tempData[index].size90),
        size95:
          Number(tempReady1[readyIndex1]?.size95) +
          Number(tempData[index].size95),
        size100:
          Number(tempReady1[readyIndex1]?.size100) +
          Number(tempData[index].size100),
        size105:
          Number(tempReady1[readyIndex1]?.size105) +
          Number(tempData[index].size105),
        size110:
          Number(tempReady1[readyIndex1]?.size110) +
          Number(tempData[index].size110),
        size115:
          Number(tempReady1[readyIndex1]?.size115) +
          Number(tempData[index].size115),
        size120:
          Number(tempReady1[readyIndex1]?.size120) +
          Number(tempData[index].size120),
        backgroundColor: "",
      };
      setReadyStockData([...tempReady1]);
    }
  };
  const onColorChange = (value, index) => {
    let tempData = data;
    //onChangeDefaultColor(index);
    let tempReady = [...readyStockData];
    const readyIndex1 = readyStockData?.findIndex(
      (item) =>
        item.color === tempData[index]?.color &&
        item.mainitem === tempData[index]?.mainitem
    );

    const mainitem = tempData[index]?.mainitem;
    const color = tempData[index]?.color;

    const itemCount = tempData.reduce((count, item) => {
      if (item?.mainitem === mainitem && item?.color === color) {
        count++;
      }
      return count;
    }, 0);

    if (tempReady[readyIndex1] !== undefined) {
      tempReady[readyIndex1] = {
        ...tempReady[readyIndex1],
        size85:
          Number(tempReady[readyIndex1]?.size85) +
          Number(tempData[index].size85),
        size90:
          Number(tempReady[readyIndex1]?.size90) +
          Number(tempData[index].size90),
        size95:
          Number(tempReady[readyIndex1]?.size95) +
          Number(tempData[index].size95),
        size100:
          Number(tempReady[readyIndex1]?.size100) +
          Number(tempData[index].size100),
        size105:
          Number(tempReady[readyIndex1]?.size105) +
          Number(tempData[index].size105),
        size110:
          Number(tempReady[readyIndex1]?.size110) +
          Number(tempData[index].size110),
        size115:
          Number(tempReady[readyIndex1]?.size115) +
          Number(tempData[index].size115),
        size120:
          Number(tempReady[readyIndex1]?.size120) +
          Number(tempData[index].size120),
        backgroundColor: itemCount >= 2 ? "yellow" : "",
      };
      setReadyStockData([...tempReady]);
    }
    tempData[index].color = value;
    setData([...tempData]);

    //	let tempReady = [...readyStockData];
    const readyIndex = readyStockData?.findIndex(
      (item) =>
        item.color === value && item.mainitem === tempData[index].mainitem
    );

    if (tempReady[readyIndex] !== undefined) {
      const updatedItem = {
        ...tempReady[readyIndex],
        size85:
          Number(tempReady[readyIndex]?.size85) -
          Number(tempData[index].size85),
        size90:
          Number(tempReady[readyIndex]?.size90) -
          Number(tempData[index].size90),
        size95:
          Number(tempReady[readyIndex]?.size95) -
          Number(tempData[index].size95),
        size100:
          Number(tempReady[readyIndex]?.size100) -
          Number(tempData[index].size100),
        size105:
          Number(tempReady[readyIndex]?.size105) -
          Number(tempData[index].size105),
        size110:
          Number(tempReady[readyIndex]?.size110) -
          Number(tempData[index].size110),
        size115:
          Number(tempReady[readyIndex]?.size115) -
          Number(tempData[index].size115),
        size120:
          Number(tempReady[readyIndex]?.size120) -
          Number(tempData[index].size120),
        backgroundColor: "yellow",
      };

      const updatedTempReady = tempReady.map((item, idx) =>
        idx === readyIndex
          ? updatedItem
          : {
              ...item,
              backgroundColor:
                item.backgroundColor === "yellow" ? "" : item.backgroundColor,
            }
      );

      setReadyStockData(updatedTempReady);
    }
  };

  const [idx, setIdx] = useState();

  const totalValue = (index) => {
    let tempData = data;
    let row = tempData[index];
    // console.log(row);
    return sizes.reduce(
      (total, size) => total + Number(row[`size${size}`] || 0),
      0
    );
  };

  const handleCheck = (value, index) => {
    let tempData = data;
    tempData[index].delete = value === true ? 1 : 0;
    setData([...tempData]);
  };

  const handleSubmit = () => {
    handleFormSubmission();
  };

  return (
    <div className="entry-table-v2 ">
      <h6 className="text-xl mb-2">
        Entry Form <Button onClick={addRow}>Add Row</Button>{" "}
        <Button
          className=" text-white bg-redBase font-semibold"
          onClick={handleSubmit}
          loading={isUpdated}
          disabled={hasNegativeStock}
          title={hasNegativeStock ? "Insufficient stock available" : ""}
        >
          Submit
        </Button>
        {hasNegativeStock && (
          <span className="text-redBase  ml-2">
            Insufficient stock available
          </span>
        )}
      </h6>

      <div className="flex items-center">
        <div className="custom-w-lg">
          <h6>Party</h6>
        </div>
        <div className="custom-w-lg">
          <h6>Main Item</h6>
        </div>
        <div className="custom-w-lg">
          <h6>Color</h6>
        </div>
        {sizes?.map((item, idx) => (
          <div className="custom-w-sm" key={idx}>
            <h6>{item}</h6>
          </div>
        ))}
        <div className="custom-w-md">
          <h6>Total</h6>
        </div>
        <div className="custom-w-lg">
          <h6>Comments</h6>
        </div>
        <div className="custom-w-md">
          <h6>Delete</h6>
        </div>
      </div>
      <div className="scroll-v2">
        {data?.map((item, index) => (
          <div className="flex items-center " key={item?.id}>
            <div className="custom-w-lg">
              <textarea
                type="text"
                className="custom-inp h-11  max-w-full custom-text "
                wrap="soft"
                spellCheck="false"
                value={item?.partyName}
                onChange={(e) =>
                  handleChange(e.target.value, index, "partyName", item)
                }
              />
            </div>
            <div className="custom-w-lg">
              <Select
                value={item?.mainitem}
                style={{ width: "148px" }}
                showSearch
                placeholder="Select main item"
                optionFilterProp="children"
                onChange={(e) => onMainItemChange(e, index)}
                filterOption={filterOption}
                options={mainItemsOptions1}
                allowClear
              />
            </div>
            <div className="custom-w-lg">
              <Select
                value={item?.color}
                style={{ width: "148px" }}
                loading={isFetchingDropdownList}
                showSearch
                placeholder="Select Color"
                optionFilterProp="children"
                onChange={(e) => {
                  //onChangeDefaultColor(index);

                  onColorChange(e, index);
                }}
                filterOption={filterOption}
                options={filteredColorList || colorOptions || []}
                allowClear
              />
            </div>

            {sizes.map((size) => (
              <div className="custom-w-sm" key={size}>
                <input
                  type="number"
                  className={`custom-inp ${
                    negativeCells[index] && negativeCells[index][`size${size}`]
                      ? "text-redBase font-bold"
                      : tempStockData[index]?.backgroundColor || "white"
                  }`}
                  value={item[`size${size}`]}
                  onChange={(e) =>
                    handleChange(e.target.value, index, `size${size}`, item)
                  }
                />
              </div>
            ))}
            <div className="custom-w-md">
              <input
                type="number"
                className="custom-inp"
                disabled
                placeholder={totalValue(index)}
              />
            </div>

            <div className="custom-w-lg">
              <input
                type="text"
                className="custom-inp"
                value={item?.comment}
                onChange={(e) => handleChange(e.target.value, index, "comment")}
              />
            </div>
            <div className="custom-w-xsm">
              {id ? (
                <Checkbox
                  onChange={(e) => handleCheck(e.target.checked, index)}
                />
              ) : (
                <Button danger onClick={() => handleDelete(index)}>
                  <MdDelete size={22} />
                </Button>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default EntryTableV2;
