import React, { useRef, useState, useEffect } from "react";
import { Table, Image, Input, Button, Upload } from "antd";
import {
  MdDelete,
  MdEdit,
  MdSave,
  MdClose,
  MdFileUpload,
} from "react-icons/md";

export const ColourSelector = ({
  setTotalRatio,
  rawMaterialColours,
  removeItemImage,
  isViewOnly,
  setRawMaterialColours,
  additionalColorImage,
  totalRatio,
}) => {
  const inputRefs = useRef([]);
  const [editIndex, setEditIndex] = useState(null);
  const [editValue, setEditValue] = useState("");
  const [prevColorsLength, setPrevColorsLength] = useState(0);
  const [ratioError, setRatioError] = useState(false);

  // Calculate total ratio whenever rawMaterialColours changes
  useEffect(() => {
    const total = rawMaterialColours.reduce((sum, colour) => {
      return sum + (parseFloat(colour.ratio) || 0);
    }, 0);

    setTotalRatio(total);
    setRatioError(total !== 100);
  }, [rawMaterialColours]);

  useEffect(() => {
    if (additionalColorImage && rawMaterialColours.length > prevColorsLength) {
      const updatedColours = [...rawMaterialColours];
      const newColorIndex = updatedColours.length - 1;

      updatedColours[newColorIndex] = {
        ...updatedColours[newColorIndex],
        item_images: additionalColorImage,

        imageUrl: URL.createObjectURL(additionalColorImage),
      };

      setRawMaterialColours(updatedColours);
    }

    setPrevColorsLength(rawMaterialColours.length);
  }, [
    additionalColorImage,
    rawMaterialColours,
    setRawMaterialColours,
    prevColorsLength,
  ]);

  const handleChange = (event, index) => {
    const file = event.target.files[0];
    if (file) {
      const updatedColours = [...rawMaterialColours];
      updatedColours[index] = {
        ...updatedColours[index],
        item_images: file,
        imageUrl: URL.createObjectURL(file),
      };
      setRawMaterialColours(updatedColours);
    }
  };

  const startEdit = (index) => {
    setEditIndex(index);
    setEditValue(rawMaterialColours[index].color_name);
  };

  const cancelEdit = () => {
    setEditIndex(null);
    setEditValue("");
  };

  const saveEdit = (index) => {
    if (editValue.trim()) {
      const updatedColours = [...rawMaterialColours];
      updatedColours[index] = {
        ...updatedColours[index],
        color_name: editValue.toUpperCase(),
      };
      setRawMaterialColours(updatedColours);
      setEditIndex(null);
      setEditValue("");
    }
  };

  const handleRatioChange = (value, index) => {
    const ratioValue = value === "" ? 0 : parseFloat(value);

    if (isNaN(ratioValue)) return;

    const updatedColours = [...rawMaterialColours];
    updatedColours[index] = {
      ...updatedColours[index],
      ratio: value === "" ? "" : ratioValue,
    };

    setRawMaterialColours(updatedColours);
  };

  const columns = [
    {
      title: "Color Name",
      dataIndex: "color_name",
      key: "color_name",
      render: (text, record, index) =>
        editIndex === index ? (
          <Input
            value={editValue}
            onChange={(e) => setEditValue(e.target.value)}
            onPressEnter={() => saveEdit(index)}
            className="w-32"
          />
        ) : (
          <span className="font-bold">{text}</span>
        ),
    },
    {
      title: "Ratio",
      dataIndex: "ratio",
      key: "ratio",
      render: (text, record, index) => (
        <Input
          type="number"
          value={text}
          onChange={(e) => handleRatioChange(e.target.value, index)}
          disabled={isViewOnly}
          className="w-20"
          status={ratioError ? "error" : ""}
          min="0"
          max="100"
          step="0.1"
        />
      ),
    },
    {
      title: "Family",
      dataIndex: "color_family",
      key: "color_family",
      render: (colorFamily) => <span>{colorFamily?.colorFamily}</span>,
    },
    {
      title: "Image",
      dataIndex: "item_images",
      key: "item_images",
      render: (_, record, index) => {
        const imageUrl =
          record.item_images instanceof File
            ? record.imageUrl
            : record.item_images;

        return (
          <div className="flex items-center gap-2">
            {imageUrl ? (
              <Image
                src={imageUrl}
                alt="colour img"
                width={60}
                height={60}
                className="!object-cover"
              />
            ) : (
              <span className="text-gray-500">No Image</span>
            )}
            <Upload
              showUploadList={false}
              beforeUpload={(file) => {
                handleChange({ target: { files: [file] } }, index);
                return false;
              }}
            >
              <Button icon={<MdFileUpload />} disabled={isViewOnly} />
            </Upload>
          </div>
        );
      },
    },
    {
      title: "Actions",
      key: "actions",
      render: (_, record, index) => (
        <div className="flex gap-2">
          {editIndex === index ? (
            <>
              <Button
                type="text"
                onClick={() => saveEdit(index)}
                className="text-green-600"
              >
                <MdSave className="text-xl" />
              </Button>
              <Button
                type="text"
                onClick={cancelEdit}
                className="text-gray-600"
              >
                <MdClose className="text-xl" />
              </Button>
            </>
          ) : (
            <>
              <Button
                type="text"
                onClick={() => startEdit(index)}
                className="text-blue-600"
                disabled={isViewOnly}
              >
                <MdEdit className="text-xl" />
              </Button>
              <Button
                type="text"
                onClick={() => removeItemImage(index)}
                className="text-red-600"
                disabled={isViewOnly}
              >
                <MdDelete className="text-xl" />
              </Button>
            </>
          )}
        </div>
      ),
    },
  ];

  return (
    <div className="flex flex-col px-[8rem]">
      {/* <h4 className="text-left text-2xl font-semibold mb-4">Colours</h4> */}

      {rawMaterialColours.length === 0 ? (
        <h6 className="m-3 align-center">No colors added.</h6>
      ) : (
        <>
          <Table
            dataSource={rawMaterialColours.map((colour, index) => ({
              ...colour,
              key: index,
            }))}
            columns={columns}
            pagination={false}
            bordered
          />
          <div
            className={`mt-4 font-bold ${
              ratioError ? "text-red-500" : "text-green-500"
            }`}
          >
            Total Ratio: {totalRatio.toFixed(1)}%
            {ratioError && (
              <span className="ml-2 text-red-500">
                (Total must be exactly 100%)
              </span>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default ColourSelector;
