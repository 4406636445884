import { Image } from "antd";
import { useRef } from "react";
import { MdDelete } from "react-icons/md";
import { Field } from "redux-form";
import { ReduxFormFileField } from "utils/ReduxFormFileField";

export const RenderColours = ({
  fields,
  isViewOnly,
  rawMaterialColours,
  removeItemImage,
  isUsingReduxform,
}) => {
  const inputRef = useRef(null);

  function openFileExplorer() {
    inputRef.current?.click();
  }

  return (
    <>
      <div className="flex flex-col" style={{ overflowX: "auto" }}>
        <h4 className="text-left text-2xl font-semibold mb-10">Colours</h4>

        {fields?.length === 0 ? (
          <h6 className="m-3 align-center">No colors added.</h6>
        ) : null}
        <div className="flex flex-wrap">
          {fields.map((raw_material_colors, index) => {
            const imageUrl =
              rawMaterialColours?.[index]?.item_images instanceof File
                ? URL.createObjectURL(rawMaterialColours?.[index]?.item_images)
                : rawMaterialColours?.[index]?.item_images;

            return (
              <div
                key={index}
                className="flex flex-wrap flex-col mb-5 w-80 max-w-[95%] rounded shadow-xl mr-10"
              >
                {imageUrl ? (
                  <>
                    <Image
                      src={imageUrl}
                      alt="colour img"
                      className="!object-contain !h-56"
                      preview={{ toolbarRender: () => null }}
                    />
                  </>
                ) : isUsingReduxform ? (
                  <Field
                    name={`${raw_material_colors}.item_images`}
                    component={ReduxFormFileField}
                    className="mt-1"
                    placeholder="Item Image"
                    disabled={isViewOnly}
                    fileName={rawMaterialColours?.[index]?.color_id}
                    itemname="image"
                  />
                ) : (
                  <>
                    <button
                      className="flex rounded-lg bg-secondaryDark text-white  font-semibold justify-center items-center"
                      onClick={openFileExplorer}
                    >
                      <span className="flex-1 px-5 py-2">Select Image</span>
                    </button>
                    <input
                      ref={inputRef}
                      type="file"
                      hidden
                      style={{ display: "none" }}
                    />
                  </>
                )}
                <div className="px-6 py-4 flex justify-between border-t border-[#d1d5db]">
                  <div className="font-bold text-xl self-center">
                    {isUsingReduxform
                      ? rawMaterialColours?.[index]?.color_name
                      : rawMaterialColours?.[index]}
                  </div>
                  {imageUrl && (
                    <button
                      className="bg-redBase text-white font-semibold rounded py-2 px-4"
                      type="button"
                      onClick={() => removeItemImage(index)}
                    >
                      <MdDelete className="text-2xl" />
                    </button>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};
