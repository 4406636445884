import React from "react";
import { Button } from "antd";
import { Form, Field } from "react-final-form";
import InputFieldV2 from "utils/v2/InputFieldV2";
import { useDispatch, useSelector } from "react-redux";
import {
  addMasterList,
  getMasterList,
  masterSelectors,
} from "slices/masterSlice";
import { Helmet } from "react-helmet";
import { useHotkeys } from "react-hotkeys-hook";

const masterName = "tax";

const AddTax = () => {
  const dispatch = useDispatch();
  const loading = useSelector(masterSelectors?.getIsUpdatingMasterList);

  const validateFloat = (value) => {
    if (!value) return "Required";
    const floatValue = parseFloat(value);
    if (isNaN(floatValue)) return "Must be a number";
    return undefined;
  };

  const onSubmit = async (values, form) => {
    const payload = {
      taxCode: values.taxCode,
      slab: parseFloat(values.slab),
    };

    let res = await dispatch(
      addMasterList({
        masterName,
        formData: payload,
      })
    );
    if (res?.type === "master/add/fulfilled") {
      form.restart();
      dispatch(
        getMasterList({
          masterName,
        })
      );
    }
  };

  const required = (value) => (value ? undefined : "Required");

  useHotkeys("alt+l", () => window.location.reload());
  useHotkeys("alt+s", (event) => {
    event.preventDefault();
    document
      .getElementById("form")
      .dispatchEvent(new Event("submit", { cancelable: true, bubbles: true }));
  });

  const NumberField = ({ input, meta, label }) => (
    <InputFieldV2
      input={{
        ...input,
        onChange: (e) => {
          const value = e.target.value;
          if (value === "" || /^\d*\.?\d*$/.test(value)) {
            input.onChange(value);
          }
        },
      }}
      meta={meta}
      type="text"
      label={label}
    />
  );

  return (
    <>
      <Helmet>
        <title>TAX | Add</title>
      </Helmet>
      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit, form }) => (
          <form onSubmit={handleSubmit} id="form">
            <>
              <h1 className="text-2xl mb-5">Add Tax Code</h1>
              <div className="flex gap-5">
                <div className="p-1">
                  <Field name="taxCode" validate={required}>
                    {({ input, meta }) => (
                      <InputFieldV2
                        input={input}
                        meta={meta}
                        type="text"
                        label="Tax Code"
                      />
                    )}
                  </Field>
                </div>
                <div className="p-1">
                  <Field name="slab" validate={validateFloat}>
                    {({ input, meta }) => (
                      <NumberField input={input} meta={meta} label="Slab" />
                    )}
                  </Field>
                </div>
              </div>

              <div className="flex gap-5 justify-end mt-10">
                <Button
                  type="primary"
                  size="small"
                  className="bg-secondaryBase w-[80px]"
                  htmlType="button"
                  onClick={form.reset}
                >
                  Clear
                </Button>
                <Button
                  type="primary"
                  size="small"
                  className="bg-redBase w-[80px]"
                  htmlType="submit"
                  loading={loading}
                  disabled={loading}
                >
                  Add
                </Button>
              </div>
            </>
          </form>
        )}
      />
    </>
  );
};

export default AddTax;
