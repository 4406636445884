import React, { useState, useCallback } from "react";
import { Pagination, Table, Button, Modal, Tooltip } from "antd";
import { Helmet } from "react-helmet";
import { renderMobileColumn } from "utils/renderMobileColumn";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Field, formValueSelector, reduxForm } from "redux-form";
import {
  getMasterList,
  masterSelectors,
  ImportExcelFile,
  ExportExcelFile,
} from "slices/masterSlice";
import useMasterLogic from "customHooks/useMasterLogic";
import { ReduxFormFileField } from "utils/ReduxFormFileField";
import { TfiReload } from "react-icons/tfi";
import { renderActions } from "utils/renderActions";
import AgentDetails from "./AgentDetails";
import { useHotkeys } from "react-hotkeys-hook";
import StatusSegments from "components/StatusSegments";

const masterName = "agent";

export const masterStatuses = [
  { label: "Active", value: 1 },
  { label: "Inactive", value: 0 },
];

const Agent = () => {
  const formSelector = formValueSelector(masterName);
  const dispatch = useDispatch();
  const { status } = useParams();
  const loading = useSelector(masterSelectors?.getIsFetchingMasterList);
  const importing = useSelector(masterSelectors?.getIsImportingExcel);
  const exporting = useSelector(masterSelectors?.getIsExportingExcel);
  const list = useSelector(masterSelectors?.getAgentMasterList);
  const { pageSize, totalElements, currentPage } = useSelector(
    masterSelectors?.getAgentPagination
  );

  const getListData = useCallback(
    (pageNum = 1, itemsPerPage = 10) => {
      dispatch(
        getMasterList({
          masterName,
          query: {
            page: pageNum,
            status: status,
            pageSize: itemsPerPage,
          },
        })
      );
    },
    [status, dispatch]
  );

  const { handlePageChange } = useMasterLogic(getListData, masterName);

  const [data, setData] = useState({});
  const [type, setType] = useState("update");

  const handleSetData = (value) => {
    setType("update");
    let temp = { ...value };
    temp.agentContactPerson = value?.toolSubForm;
    temp.cityId = value?.city?.id;
    setData(temp);
  };

  const handleDuplicate = (value) => {
    setType("new");
    let temp = { ...value };
    temp.agentContactPerson = value?.toolSubForm;
    temp.cityId = value?.city?.id;
    setData(temp);
  };

  const handleImport = () => {
    dispatch(
      ImportExcelFile({
        masterName: "agent/export/excel",
        name: "agent",
      })
    );
  };
  const [show, setShow] = useState(false);
  const excelFile = useSelector((state) => formSelector(state, "item_excel"));
  const handleExport = () => {
    setShow(false);
    const form = new FormData();
    form.append("file", excelFile);
    dispatch(
      ExportExcelFile({
        masterName: "agent/upload/import",
        formData: form,
        configType: "multipart/form-data",
      })
    );
  };

  const columns = [
    {
      title: "Agent",
      render: (data) => {
        const primaryText = data?.agentName || "-";
        const secondaryText = `${data?.emailId || "-"}`;
        return renderMobileColumn(primaryText, secondaryText);
      },
      responsive: ["xs"],
    },
    {
      title: "Agent Name",
      dataIndex: "agentName",
      key: "agentName",
      responsive: ["sm"],
      render: (value, data) => (
        <p onDoubleClick={() => handleSetData(data)} className="cursor-pointer">
          {value || "-"}
        </p>
      ),
    },
    {
      title: "Address",
      dataIndex: "addressLine1",
      key: "addressLine1",
      responsive: ["sm"],
      render: (value, data) => (
        <p onDoubleClick={() => handleSetData(data)} className="cursor-pointer">
          {value || "-"}
        </p>
      ),
    },
    {
      title: "City",
      dataIndex: ["city", "cityName"],
      key: ["city", "cityName"],
      responsive: ["sm"],
      render: (value, data) => (
        <p onDoubleClick={() => handleSetData(data)} className="cursor-pointer">
          {value || "-"}
        </p>
      ),
    },
    {
      title: "Pincode",
      dataIndex: "pincode",
      key: "pincode",
      responsive: ["sm"],
      render: (value, data) => (
        <p onDoubleClick={() => handleSetData(data)} className="cursor-pointer">
          {value || "-"}
        </p>
      ),
    },
    {
      title: "Country",
      dataIndex: "country",
      key: "country",
      responsive: ["sm"],
      render: (value, data) => (
        <p onDoubleClick={() => handleSetData(data)} className="cursor-pointer">
          {value || "-"}
        </p>
      ),
    },
    {
      title: "Telephone",
      dataIndex: "telephone",
      key: "telephone",
      responsive: ["sm"],
      render: (value, data) => (
        <p onDoubleClick={() => handleSetData(data)} className="cursor-pointer">
          {value || "-"}
        </p>
      ),
    },
    {
      title: "Email",
      dataIndex: "emailId",
      key: "emailId",
      responsive: ["sm"],
      render: (value, data) => (
        <p onDoubleClick={() => handleSetData(data)} className="cursor-pointer">
          {value || "-"}
        </p>
      ),
    },
    {
      title: "Mobile",
      dataIndex: "mobile",
      key: "mobile",
      responsive: ["sm"],
      render: (value, data) => (
        <p onDoubleClick={() => handleSetData(data)} className="cursor-pointer">
          {value || "-"}
        </p>
      ),
    },
    {
      title: "GSTIN",
      dataIndex: "gstin",
      key: "gstin",
      responsive: ["sm"],
      render: (value, data) => (
        <p onDoubleClick={() => handleSetData(data)} className="cursor-pointer">
          {value || "-"}
        </p>
      ),
    },
    {
      title: "Pan",
      dataIndex: "pan",
      key: "pan",
      responsive: ["sm"],
      render: (value, data) => (
        <p onDoubleClick={() => handleSetData(data)} className="cursor-pointer">
          {value || "-"}
        </p>
      ),
    },
    {
      title: "Notes",
      dataIndex: "notes",
      key: "notes",
      responsive: ["sm"],
      render: (value, data) => (
        <p onDoubleClick={() => handleSetData(data)} className="cursor-pointer">
          {value || "-"}
        </p>
      ),
    },
    {
      title: "Actions",
      key: "Actions",
      dataIndex: "action",
      width: "auto",
      render: (_, data, index) => {
        const items = [
          {
            label: "Duplicate",
            onClick: () => handleDuplicate(data),
          },
        ];

        return renderActions(items);
      },
    },
  ];

  const nestedColumn = [
    {
      title: "Contact Person",
      dataIndex: "name",
      key: "name",
      width: "auto",
    },
    {
      title: "Email",
      dataIndex: "emailId",
      key: "emailId",
      width: "auto",
    },
    {
      title: "Mobile No.",
      dataIndex: "mobileNo",
      key: "mobileNo",
      width: "auto",
    },
    {
      title: "Designation",
      dataIndex: "designation",
      key: "designation",
      width: "auto",
    },
    {
      title: "Department",
      dataIndex: "department",
      key: "department",
      width: "auto",
    },
  ];

  useHotkeys("alt+n", () => window.open("/agent/add", "_blank"));

  return (
    <>
      <Helmet>
        <title>Agents</title>
      </Helmet>
      <div className="flex justify-between items-center mb-2">
        <h1 className="text-2xl">Agents List</h1>
        <StatusSegments masterName={masterName} options={masterStatuses} />

        <div className="flex gap-5">
          <Tooltip title="Reload">
            <Button
              type="default"
              size="small"
              loading={loading}
              disabled={loading}
              onClick={() => getListData(1, 10)}
            >
              <TfiReload />
            </Button>
          </Tooltip>

          <Button
            type="default"
            size="small"
            loading={exporting}
            disabled={exporting}
            onClick={() => setShow(true)}
          >
            Import Excel
          </Button>
          <Button
            onClick={handleImport}
            loading={importing}
            disabled={exporting}
            type="default"
            size="small"
          >
            Export Excel
          </Button>
          <Link to="/agent/add" target="_blank">
            <Button type="primary" size="small" className="w-[80px]">
              Add New
            </Button>
          </Link>
        </div>
      </div>
      <Table
        dataSource={list}
        columns={columns}
        rowKey={(item) => item?.id}
        expandable={{
          expandedRowRender: (record) => (
            <>
              <p className="mb-0 font-bold text-primaryBase">
                Agent Contact Persons:
              </p>
              <Table
                dataSource={record?.toolSubForm}
                columns={nestedColumn}
                pagination={false}
                bordered
              />
            </>
          ),
        }}
        pagination={false}
        loading={loading}
        scroll={{ y: 400 }}
        bordered
        style={{ minHeight: "360px" }}
      />
      {list?.length ? (
        <div className="flex justify-end">
          <Pagination
            pageSize={pageSize}
            total={totalElements}
            current={currentPage}
            onChange={handlePageChange}
          />
        </div>
      ) : null}
      <div className="mb-20"></div>
      <AgentDetails
        data={data}
        setData={setData}
        type={type}
        setType={setType}
      />
      <Modal
        title="Upload Excel File"
        open={show}
        onOk={handleExport}
        onCancel={() => setShow(false)}
      >
        <Field
          name="item_excel"
          component={ReduxFormFileField}
          className="mt-1"
          placeholder="Item Image"
          itemname="excel file"
        />
        <p className="mt-2 text-lg font-bold">{excelFile?.name}</p>
      </Modal>
    </>
  );
};

export default reduxForm({
  form: masterName,
  enableReinitialize: true,
})(Agent);
