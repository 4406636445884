import React, { useEffect } from "react";
import { Button, Select, Typography } from "antd";
import { Form, Field } from "react-final-form";
import InputFieldV2 from "utils/v2/InputFieldV2";
import { useDispatch, useSelector } from "react-redux";
import {
  addMasterList,
  getMasterDropDownList,
  getMasterList,
  masterSelectors,
} from "slices/masterSlice";
import { Helmet } from "react-helmet";
import { useHotkeys } from "react-hotkeys-hook";

const { Text } = Typography;

const masterName = "city";
const AddCity = () => {
  const dispatch = useDispatch();
  const loading = useSelector(masterSelectors?.getIsUpdatingMasterList);
  const loadingDropdown = useSelector(masterSelectors?.getIsFetchingDropdown);

  const stateDropdown = useSelector(masterSelectors?.getStateDropdown);

  useEffect(() => {
    dispatch(getMasterDropDownList({ masterName: "state_dropdown" }));
  }, [dispatch]);

  const onSubmit = async (values, form) => {
    console.log({ values });

    let res = await dispatch(
      addMasterList({
        masterName,
        formData: values,
      })
    );
    if (res?.type === "master/add/fulfilled") {
      form.restart();
      dispatch(
        getMasterList({
          masterName,
        })
      );
    }
  };

  const required = (value) => (value ? undefined : "Required");
  useHotkeys("alt+l", () => window.location.reload());
  useHotkeys("alt+s", (event) => {
    event.preventDefault();
    document
      .getElementById("form")
      .dispatchEvent(new Event("submit", { cancelable: true, bubbles: true }));
  });

  return (
    <>
      <Helmet>
        <title>City | Add</title>
      </Helmet>
      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit, form }) => (
          <form onSubmit={handleSubmit} id="form">
            <>
              <h1 className="text-2xl mb-5">Add City</h1>
              <div className="flex gap-5">
                <div className="">
                  <Field name="cityName" validate={required}>
                    {({ input, meta }) => (
                      <InputFieldV2
                        input={input}
                        meta={meta}
                        type="text"
                        label="City name"
                      />
                    )}
                  </Field>
                </div>
                <div className="flex flex-col">
                  <Text className="">State</Text>
                  <Field name="state">
                    {({ input, meta }) => (
                      <Select
                        style={{
                          width: "200px",
                          border: `${
                            meta.touched && meta.error ? "1px solid red" : ""
                          }`,
                          borderRadius: "6px",
                        }}
                        showSearch
                        loading={loadingDropdown}
                        placeholder="Select State"
                        allowClear
                        options={stateDropdown}
                        value={input.value}
                        onChange={(value) => {
                          input.onChange(value);
                        }}
                      />
                    )}
                  </Field>
                </div>
                <div className="">
                  <Field name="distance" validate={required}>
                    {({ input, meta }) => (
                      <InputFieldV2
                        input={input}
                        meta={meta}
                        type="text"
                        label="Distance"
                      />
                    )}
                  </Field>
                </div>
              </div>
              <div className="flex gap-5 justify-end mt-10">
                <Button
                  type="primary"
                  size="small"
                  className="bg-secondaryBase w-[80px]"
                  htmlType="button"
                  onClick={form.reset}
                >
                  Clear
                </Button>
                <Button
                  type="primary"
                  size="small"
                  className="bg-redBase w-[80px]"
                  htmlType="submit"
                  loading={loading}
                  disabled={loading}
                >
                  Add
                </Button>
              </div>
            </>
          </form>
        )}
      />
    </>
  );
};

export default AddCity;
