import { Tooltip } from "antd";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import Select from "react-select";
import { masterSelectors } from "slices/masterSlice";
import MenuListComponent from "./MenuListComponent";
import OptionComponent from "./OptionComponent";
import ValueContainerComponent from "./ValueContainerComponent";

const classNames = {
  control: () => "!shadow-none  !border-0 !h-9 !min-h-0",
  valueContainer: () => "!px-0 ",
  indicatorSeparator: () => "!w-0",
  option: () => "!bg-white !text-black !flex justify-between !h-full",
};

export const ReduxFormSelectField = ({
  input,
  options,
  disabled,
  label = null,
  isMulti = false,
  menuPosition = "relative",
  className = "",
  isLoading,
  onChange: customOnChange,
  meta: { touched, error },
}) => {
  const [selectInput, setSelectInput] = useState("");
  const isAllSelected = useRef(false);
  const selectAllLabel = useRef("Select all");
  const allOption = { value: "*", label: selectAllLabel.current };

  const generatedRawMaterialColorsOptions = useSelector(
    masterSelectors?.getGeneratedRawMaterialColorsDropdownList
  );
  const filterOptions = (options, searchInput) =>
    Array.isArray(options)
      ? options.filter(({ label }) =>
          label?.toLowerCase().includes(searchInput?.toLowerCase())
        )
      : [];

  const comparator = (v1, v2) => v1.value - v2.value;
  let filteredOptions = filterOptions(options, selectInput);

  let filteredSelectedOptions = filterOptions(input?.value, selectInput);

  const customFilterOption = ({ value, label }, input) =>
    (value !== "*" && label?.toLowerCase().includes(input?.toLowerCase())) ||
    (value === "*" && filteredOptions?.length > 0);

  const handleSingleChange = (val) => {
    input?.onChange(val);
    if (customOnChange) customOnChange(val);
    console.log("Selected value:", val);
  };

  const onInputChange = (inputValue, event) => {
    if (event.action === "input-change") setSelectInput(inputValue);
    else if (event.action === "menu-close" && selectInput !== "")
      setSelectInput("");
  };

  const onKeyDown = (e) => {
    if ((e.key === " " || e.key === "Enter") && !selectInput)
      e.preventDefault();
  };

  const handleChange = (selected) => {
    if (
      selected.length > 0 &&
      !isAllSelected.current &&
      (selected[selected.length - 1].value === allOption.value ||
        filteredOptions?.length === selected.sort(comparator)?.length)
    ) {
      return input?.onChange(
        [
          ...(input?.value || []),
          ...options?.filter(
            (o) =>
              o?.label?.toLowerCase().includes(selectInput?.toLowerCase()) &&
              (input?.value || [])?.filter((opt) => opt?.value === o?.value)
                ?.length === 0
          ),
        ].sort(comparator)
      );
    } else if (
      selected.length > 0 &&
      selected[selected.length - 1].value !== allOption.value &&
      selected.sort(comparator)?.length !== filteredOptions?.length
    ) {
      return input?.onChange(selected);
    } else {
      return input?.onChange([
        ...input?.value?.filter(
          ({ label }) =>
            !label?.toLowerCase().includes(selectInput?.toLowerCase())
        ),
      ]);
    }
  };

  useEffect(() => {
    if (
      isMulti &&
      label === "Generated Colors" &&
      input.name === "generatedColors"
    ) {
      input.value = generatedRawMaterialColorsOptions;
      handleChange([
        {
          value: "*",
          label: "Selete all",
        },
      ]);
    }
  }, [label, isMulti, generatedRawMaterialColorsOptions]);

  isAllSelected.current =
    filteredSelectedOptions?.length === filteredOptions?.length;

  if (filteredSelectedOptions?.length > 0) {
    if (filteredSelectedOptions?.length === filteredOptions?.length)
      selectAllLabel.current = `All (${filteredOptions.length}) selected`;
    else
      selectAllLabel.current = `${filteredSelectedOptions?.length} / ${filteredOptions.length} selected`;
  } else selectAllLabel.current = "Select all";

  allOption.label = selectAllLabel.current;

  return (
    <>
      {label && (
        <label htmlFor="textInput" className="flex font-medium">
          <span className="text-md text-secondaryDark">{label}</span>
        </label>
      )}
      <Tooltip title={touched && error ? error : null}>
        <div>
          {isMulti ? (
            <Select
              {...input}
              id="selectInput"
              className={`mt-0 md:w-60 border-secondaryDark w-full py-0 px-0.5 border border-b-[1px] ${
                touched && error ? "border-redBase" : "border-secondaryDark"
              } ${className}`}
              classNames={classNames}
              inputValue={selectInput}
              onChange={handleChange}
              onInputChange={onInputChange}
              onBlur={() => input.onBlur()}
              options={[allOption, ...options]}
              onKeyDown={onKeyDown}
              filterOption={customFilterOption}
              isMulti={isMulti}
              isDisabled={disabled}
              menuPosition={menuPosition}
              isLoading={isLoading}
              isClearable
              hideSelectedOptions={false}
              backspaceRemovesValue={false}
              closeMenuOnSelect={!isMulti}
              blurInputOnSelect={false}
              isSearchable
              selectInput={selectInput}
              isAllSelected={isAllSelected}
              filteredSelectedOptions={filteredSelectedOptions}
              components={{
                Option: OptionComponent,
                MenuList: MenuListComponent,
                ValueContainer: ValueContainerComponent,
              }}
            />
          ) : (
            <Select
              {...input}
              id="selectInput"
              className={`mt-0 md:w-60 w-full  border-secondaryDark py-0 px-0.5 border border-b-[1px] ${
                touched && error ? "border-redBase" : "border-secondaryBase"
              } ${className}`}
              classNames={classNames}
              inputValue={selectInput}
              onChange={handleSingleChange}
              onInputChange={onInputChange}
              onBlur={() => input.onBlur()}
              options={options}
              onKeyDown={onKeyDown}
              filterOption={customFilterOption}
              isMulti={isMulti}
              isDisabled={disabled}
              menuPosition={menuPosition}
              isLoading={isLoading}
              isClearable
              hideSelectedOptions={false}
              backspaceRemovesValue={false}
              closeMenuOnSelect={!isMulti}
              blurInputOnSelect={false}
              isSearchable
            />
          )}
        </div>
      </Tooltip>
    </>
  );
};

export const ReduxFormSelectField1 = ({
  input,
  options,
  disabled,
  label = null,
  isMulti = false,
  menuPosition = "relative",
  className = "",
  isLoading,
  meta: { touched, error },
}) => {
  const [selectInput, setSelectInput] = useState("");
  const isAllSelected = useRef(false);
  const selectAllLabel = useRef("Select all");
  const allOption = { value: "*", label: selectAllLabel.current };

  const generatedRawMaterialColorsOptions = useSelector(
    masterSelectors?.getGeneratedRawMaterialColorsDropdownList
  );

  const filterOptions = (options, searchInput) =>
    options?.length &&
    options?.filter(({ label }) =>
      label?.toLowerCase().includes(searchInput?.toLowerCase())
    );

  const comparator = (v1, v2) => v1.value - v2.value;
  let filteredOptions = filterOptions(options, selectInput);

  let filteredSelectedOptions = filterOptions(input?.value, selectInput);

  const customFilterOption = ({ value, label }, input) =>
    (value !== "*" && label?.toLowerCase().includes(input?.toLowerCase())) ||
    (value === "*" && filteredOptions?.length > 0);

  const onInputChange = (inputValue, event) => {
    if (event.action === "input-change") setSelectInput(inputValue);
    else if (event.action === "menu-close" && selectInput !== "")
      setSelectInput("");
  };

  const onKeyDown = (e) => {
    if ((e.key === " " || e.key === "Enter") && !selectInput)
      e.preventDefault();
  };

  const handleChange = (selected) => {
    if (
      selected.length > 0 &&
      !isAllSelected.current &&
      (selected[selected.length - 1].value === allOption.value ||
        filteredOptions?.length === selected.sort(comparator)?.length)
    ) {
      return input?.onChange(
        [
          ...(input?.value || []),
          ...options?.filter(
            (o) =>
              o?.label?.toLowerCase().includes(selectInput?.toLowerCase()) &&
              (input?.value || [])?.filter((opt) => opt?.value === o?.value)
                ?.length === 0
          ),
        ].sort(comparator)
      );
    } else if (
      selected.length > 0 &&
      selected[selected.length - 1].value !== allOption.value &&
      selected.sort(comparator)?.length !== filteredOptions?.length
    ) {
      return input?.onChange(selected);
    } else {
      return input?.onChange([
        ...input?.value?.filter(
          ({ label }) =>
            !label?.toLowerCase().includes(selectInput?.toLowerCase())
        ),
      ]);
    }
  };

  useEffect(() => {
    if (
      isMulti &&
      label === "Generated Colors" &&
      input.name === "generatedColors"
    ) {
      input.value = generatedRawMaterialColorsOptions;
      handleChange([
        {
          value: "*",
          label: "Selete all",
        },
      ]);
    }
  }, [label, isMulti, generatedRawMaterialColorsOptions]);

  isAllSelected.current =
    filteredSelectedOptions?.length === filteredOptions?.length;

  if (filteredSelectedOptions?.length > 0) {
    if (filteredSelectedOptions?.length === filteredOptions?.length)
      selectAllLabel.current = `All (${filteredOptions.length}) selected`;
    else
      selectAllLabel.current = `${filteredSelectedOptions?.length} / ${filteredOptions.length} selected`;
  } else selectAllLabel.current = "Select all";

  allOption.label = selectAllLabel.current;

  const customStyles = {
    menu: (styles) => ({
      ...styles,
      backgroundColor: "#7aa2c9", // Change this to your desired background color
    }),
    // Add other styles as needed
    option: (styles) => ({
      ...styles,
      backgroundColor: "#7aa2c9", // Change this to your desired background color
    }),
  };

  return (
    <>
      {label && (
        <label htmlFor="textInput" className="flex font-medium">
          <span className="text-md text-secondaryDark">{label}</span>
        </label>
      )}
      <Tooltip title={touched && error ? error : null}>
        <div>
          {isMulti ? (
            <Select
              {...input}
              id="selectInput"
              className={`mt-0 md:w-60 w-full py-0 px-0.5 border-0 border-b-[1px] ${
                touched && error ? "border-redBase" : "border-secondaryBase"
              } ${className}`}
              classNames={classNames}
              inputValue={selectInput}
              onChange={handleChange}
              onInputChange={onInputChange}
              onBlur={() => input.onBlur()}
              options={[allOption, ...options]}
              onKeyDown={onKeyDown}
              filterOption={customFilterOption}
              isMulti={isMulti}
              isDisabled={disabled}
              menuPosition={menuPosition}
              isLoading={isLoading}
              isClearable
              hideSelectedOptions={false}
              backspaceRemovesValue={false}
              closeMenuOnSelect={!isMulti}
              blurInputOnSelect={false}
              isSearchable
              selectInput={selectInput}
              isAllSelected={isAllSelected}
              filteredSelectedOptions={filteredSelectedOptions}
              styles={customStyles}
              components={{
                Option: OptionComponent,
                MenuList: MenuListComponent,
                ValueContainer: ValueContainerComponent,
              }}
            />
          ) : (
            <Select
              {...input}
              id="selectInput"
              className={`mt-0 md:w-35 w-full py-0 px-0.5 border-0 border-b-[1px] ${
                touched && error ? "border-redBase" : "border-secondaryBase"
              } ${className}`}
              classNames={classNames}
              inputValue={selectInput}
              onChange={(val) => input?.onChange(val)}
              onInputChange={onInputChange}
              onBlur={() => input.onBlur()}
              options={options}
              onKeyDown={onKeyDown}
              filterOption={customFilterOption}
              isMulti={isMulti}
              isDisabled={disabled}
              menuPosition={menuPosition}
              isLoading={isLoading}
              isClearable
              hideSelectedOptions={false}
              backspaceRemovesValue={false}
              closeMenuOnSelect={!isMulti}
              blurInputOnSelect={false}
              isSearchable
            />
          )}
        </div>
      </Tooltip>
    </>
  );
};

export const ReduxFormSelectField2 = ({
  input,
  options,
  disabled,
  label = null,
  isMulti = false,
  menuPosition = "relative",
  className = "",
  isLoading,
  meta: { touched, error },
}) => {
  const [selectInput, setSelectInput] = useState("");
  const isAllSelected = useRef(false);
  const selectAllLabel = useRef("Select all");
  const allOption = { value: "*", label: selectAllLabel.current };

  const generatedRawMaterialColorsOptions = useSelector(
    masterSelectors?.getGeneratedRawMaterialColorsDropdownList
  );
  const mainItemsOptions = useSelector(
    masterSelectors?.getMainItemsDropdownList
  );

  const filterOptions = (options, searchInput) =>
    options?.length &&
    options?.filter(({ label }) =>
      label?.toLowerCase().includes(searchInput?.toLowerCase())
    );

  const comparator = (v1, v2) => v1.value - v2.value;
  let filteredOptions = filterOptions(options, selectInput);

  let filteredSelectedOptions = filterOptions(input?.value, selectInput);

  const customFilterOption = ({ value, label }, input) =>
    (value !== "*" && label?.toLowerCase().includes(input?.toLowerCase())) ||
    (value === "*" && filteredOptions?.length > 0);

  const onInputChange = (inputValue, event) => {
    if (event.action === "input-change") setSelectInput(inputValue);
    else if (event.action === "menu-close" && selectInput !== "")
      setSelectInput("");
  };

  const onKeyDown = (e) => {
    if ((e.key === " " || e.key === "Enter") && !selectInput)
      e.preventDefault();
  };

  const handleChange = (selected) => {
    if (
      selected.length > 0 &&
      !isAllSelected.current &&
      (selected[selected.length - 1].value === allOption.value ||
        filteredOptions?.length === selected.sort(comparator)?.length)
    ) {
      return input?.onChange(
        [
          ...(input?.value || []),
          ...options?.filter(
            (o) =>
              o?.label?.toLowerCase().includes(selectInput?.toLowerCase()) &&
              (input?.value || [])?.filter((opt) => opt?.value === o?.value)
                ?.length === 0
          ),
        ].sort(comparator)
      );
    } else if (
      selected.length > 0 &&
      selected[selected.length - 1].value !== allOption.value &&
      selected.sort(comparator)?.length !== filteredOptions?.length
    ) {
      return input?.onChange(selected);
    } else {
      return input?.onChange([
        ...input?.value?.filter(
          ({ label }) =>
            !label?.toLowerCase().includes(selectInput?.toLowerCase())
        ),
      ]);
    }
  };

  useEffect(() => {
    if (
      isMulti &&
      label === "Generated Colors" &&
      input.name === "generatedColors"
    ) {
      input.value = generatedRawMaterialColorsOptions;
      handleChange([
        {
          value: "*",
          label: "Selete all",
        },
      ]);
    }
  }, [label, isMulti, generatedRawMaterialColorsOptions]);

  useEffect(() => {
    if (isMulti && label === "Main Item" && input.name === "mainItems") {
      input.value = mainItemsOptions;
      handleChange([
        {
          value: "*",
          label: "Selete all",
        },
      ]);
    }
  }, [label, isMulti, mainItemsOptions]);

  isAllSelected.current =
    filteredSelectedOptions?.length === filteredOptions?.length;

  if (filteredSelectedOptions?.length > 0) {
    if (filteredSelectedOptions?.length === filteredOptions?.length)
      selectAllLabel.current = `All (${filteredOptions.length}) selected`;
    else
      selectAllLabel.current = `${filteredSelectedOptions?.length} / ${filteredOptions.length} selected`;
  } else selectAllLabel.current = "Select all";

  allOption.label = selectAllLabel.current;

  return (
    <>
      {label && (
        <label htmlFor="textInput" className="flex font-medium">
          <span className="text-md text-secondaryDark">{label}</span>
        </label>
      )}
      <Tooltip title={touched && error ? error : null}>
        <div>
          {isMulti ? (
            <Select
              {...input}
              id="selectInput"
              className={`mt-0 md:w-60 w-full py-0 px-0.5 border-0 border-b-[1px] ${
                touched && error ? "border-redBase" : "border-secondaryBase"
              } ${className}`}
              classNames={classNames}
              inputValue={selectInput}
              onChange={handleChange}
              onInputChange={onInputChange}
              onBlur={() => input.onBlur()}
              options={[allOption, ...options]}
              onKeyDown={onKeyDown}
              filterOption={customFilterOption}
              isMulti={isMulti}
              isDisabled={disabled}
              menuPosition={menuPosition}
              isLoading={isLoading}
              isClearable
              hideSelectedOptions={false}
              backspaceRemovesValue={false}
              closeMenuOnSelect={!isMulti}
              blurInputOnSelect={false}
              isSearchable
              selectInput={selectInput}
              isAllSelected={isAllSelected}
              filteredSelectedOptions={filteredSelectedOptions}
              components={{
                Option: OptionComponent,
                MenuList: MenuListComponent,
                ValueContainer: ValueContainerComponent,
              }}
            />
          ) : (
            <Select
              {...input}
              id="selectInput"
              className={`mt-0 md:w-60 w-full py-0 px-0.5 border-0 border-b-[1px] ${
                touched && error ? "border-redBase" : "border-secondaryBase"
              } ${className}`}
              classNames={classNames}
              inputValue={selectInput}
              onChange={(val) => input?.onChange(val)}
              onInputChange={onInputChange}
              onBlur={() => input.onBlur()}
              options={options}
              onKeyDown={onKeyDown}
              filterOption={customFilterOption}
              isMulti={isMulti}
              isDisabled={disabled}
              menuPosition={menuPosition}
              isLoading={isLoading}
              isClearable
              hideSelectedOptions={false}
              backspaceRemovesValue={false}
              closeMenuOnSelect={!isMulti}
              blurInputOnSelect={false}
              isSearchable
            />
          )}
        </div>
      </Tooltip>
    </>
  );
};
