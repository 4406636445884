import React, { useEffect, useState } from "react";
import { Button, Select, Table, Tooltip, Card, Divider } from "antd";
import { Form, Field } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import arrayMutators from "final-form-arrays";
import InputFieldV2 from "utils/v2/InputFieldV2";
import { useDispatch, useSelector } from "react-redux";
import {
  addMasterList,
  getMasterDropDownList,
  getMasterList,
  masterSelectors,
} from "slices/masterSlice";
import { Helmet } from "react-helmet";
import { useHotkeys } from "react-hotkeys-hook";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";

const masterName = "hsn";

const AddHsn = () => {
  const dispatch = useDispatch();
  const loading = useSelector(masterSelectors?.getIsUpdatingMasterList);

  useEffect(() => {
    dispatch(getMasterDropDownList({ masterName: "slab_dropdown" }));
  }, []);

  const percentageOptions = useSelector(masterSelectors?.getSlabDropdown);

  const validateFloat = (value) => {
    if (!value) return "Required";
    const floatValue = parseFloat(value);
    if (isNaN(floatValue)) return "Must be a number";
    return undefined;
  };
  const getLabelByValue = (val) => {
    const option = percentageOptions.find((opt) => opt.value === val);
    return option ? option.label : 0;
  };

  const onSubmit = async (values) => {
    const payload = {
      hsncode: values.hsncode,
      hsnTable: values.taxEntries
        ? values.taxEntries.map((entry) => ({
            fromMrp: entry.fromMrp || "0",
            toMrp: entry.toMrp || "0",
            igst: String(getLabelByValue(entry.tax) || "0"),
            cgst: String(getLabelByValue(entry.tax) / 2 || "0"),
            sgst: String(getLabelByValue(entry.tax) / 2 || "0"),
            taxId: String(entry.tax || "0"),
          }))
        : [],
    };

    let res = await dispatch(
      addMasterList({
        masterName,
        formData: payload,
      })
    );

    if (res?.type === "master/add/fulfilled") {
      dispatch(
        getMasterList({
          masterName,
        })
      );
    }
  };

  const required = (value) => (value ? undefined : "Required");

  useHotkeys("alt+l", () => window.location.reload());
  useHotkeys("alt+s", (event) => {
    event.preventDefault();
    document
      .getElementById("form")
      .dispatchEvent(new Event("submit", { cancelable: true, bubbles: true }));
  });
  useHotkeys("alt+a", (event) => {
    event.preventDefault();
    document.getElementById("add-row-button").click();
  });

  const NumberField = ({ input, meta, placeholder, readOnly = false }) => (
    <div>
      <input
        {...input}
        onChange={(e) => {
          if (readOnly) return;
          const value = e.target.value;
          if (value === "" || /^\d*\.?\d*$/.test(value)) {
            input.onChange(value);
          }
        }}
        type="text"
        placeholder={placeholder}
        className={`border border-gray-300 rounded p-2 w-full ${
          readOnly ? "bg-gray-100" : ""
        }`}
        readOnly={readOnly}
      />
      {meta.error && meta.touched && !readOnly && (
        <span className="text-red-500 text-sm">{meta.error}</span>
      )}
    </div>
  );

  const initialValues = {
    hsncode: "",
    taxEntries: [],
  };

  return (
    <>
      <Helmet>
        <title>HSN Code | Add</title>
      </Helmet>
      <Form
        onSubmit={onSubmit}
        initialValues={initialValues}
        mutators={{
          ...arrayMutators,
        }}
        render={({ handleSubmit, form, values }) => (
          <form onSubmit={handleSubmit} id="form">
            <Card className="mb-5">
              <h1 className="text-2xl mb-5">Add HSN Code</h1>

              <div className="w-1/3 mb-4">
                <Field name="hsncode" validate={required}>
                  {({ input, meta }) => (
                    <InputFieldV2
                      input={input}
                      meta={meta}
                      type="text"
                      label="HSN Code"
                    />
                  )}
                </Field>
              </div>

              <Divider />

              <div className="flex justify-between items-center mb-3">
                <h2 className="text-lg font-medium">Tax Details</h2>
                <Tooltip title="Add Tax Entry (Alt+A)">
                  <Button
                    id="add-row-button"
                    type="primary"
                    icon={<PlusOutlined />}
                    onClick={() => {
                      form.mutators.push("taxEntries", {
                        fromMrp: "",
                        toMrp: "",
                        tax: "",
                        igst: "",
                        sgst: "",
                        cgst: "",
                      });
                    }}
                  >
                    Add Tax Entry
                  </Button>
                </Tooltip>
              </div>

              <FieldArray name="taxEntries">
                {({ fields }) => (
                  <>
                    {fields.length > 0 ? (
                      <div className="overflow-x-auto">
                        <table className="min-w-full border border-gray-300">
                          <thead>
                            <tr className="bg-gray-100">
                              <th className="p-2 border">From MRP</th>
                              <th className="p-2 border">To MRP</th>
                              <th className="p-2 border">Tax</th>
                              <th className="p-2 border">IGST</th>
                              <th className="p-2 border">SGST</th>
                              <th className="p-2 border">CGST</th>
                              <th className="p-2 border">Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {fields.map((name, index) => (
                              <tr key={name}>
                                <td className="py-2 px-10 border ">
                                  <Field
                                    name={`${name}.fromMrp`}
                                    validate={validateFloat}
                                    component={NumberField}
                                    placeholder="From MRP"
                                  />
                                </td>
                                <td className="py-2 px-10 border">
                                  <Field
                                    name={`${name}.toMrp`}
                                    validate={validateFloat}
                                    component={NumberField}
                                    placeholder="To MRP"
                                  />
                                </td>
                                <td className="py-2 px-10 border">
                                  <Field
                                    name={`${name}.tax`}
                                    validate={validateFloat}
                                  >
                                    {({ input, meta }) => {
                                      const getLabelByValue = (val) => {
                                        const option = percentageOptions.find(
                                          (opt) => opt.value === val
                                        );
                                        return option ? option.label : 0;
                                      };

                                      return (
                                        <Select
                                          placeholder="Select Tax %"
                                          options={percentageOptions}
                                          value={input.value}
                                          onChange={(selectedValue) => {
                                            input.onChange(selectedValue);

                                            const percentValue =
                                              getLabelByValue(selectedValue);

                                            form.change(
                                              `${name}.igst`,
                                              percentValue
                                            );
                                            form.change(
                                              `${name}.sgst`,
                                              percentValue / 2
                                            );
                                            form.change(
                                              `${name}.cgst`,
                                              percentValue / 2
                                            );
                                          }}
                                          style={{
                                            width: "160px",
                                            height: "40px",
                                          }}
                                          status={
                                            meta.error && meta.touched
                                              ? "error"
                                              : ""
                                          }
                                        />
                                      );
                                    }}
                                  </Field>
                                </td>
                                <td className="py-2 px-10 border">
                                  <Field
                                    name={`${name}.igst`}
                                    validate={validateFloat}
                                  >
                                    {({ input, meta }) => (
                                      <NumberField
                                        input={input}
                                        meta={meta}
                                        placeholder="IGST"
                                        readOnly={true}
                                      />
                                    )}
                                  </Field>
                                </td>
                                <td className="py-2 px-10 border">
                                  <Field
                                    name={`${name}.sgst`}
                                    validate={validateFloat}
                                  >
                                    {({ input, meta }) => (
                                      <NumberField
                                        input={input}
                                        meta={meta}
                                        placeholder="SGST"
                                        readOnly={true}
                                      />
                                    )}
                                  </Field>
                                </td>
                                <td className="py-2 px-10 border">
                                  <Field
                                    name={`${name}.cgst`}
                                    validate={validateFloat}
                                  >
                                    {({ input, meta }) => (
                                      <NumberField
                                        input={input}
                                        meta={meta}
                                        placeholder="CGST"
                                        readOnly={true}
                                      />
                                    )}
                                  </Field>
                                </td>
                                <td className="p-2 border text-center">
                                  <Button
                                    type="text"
                                    danger
                                    icon={<DeleteOutlined />}
                                    onClick={() => fields.remove(index)}
                                  />
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    ) : (
                      <div className="text-center py-5 border rounded bg-gray-50">
                        <p className="text-gray-500">
                          No tax entries added. Click "Add Tax Entry" to begin.
                        </p>
                      </div>
                    )}
                  </>
                )}
              </FieldArray>

              <div className="flex gap-5 justify-end mt-10">
                <Button
                  type="default"
                  size="middle"
                  className="w-[80px]"
                  htmlType="button"
                  onClick={() => form.reset()}
                >
                  Clear
                </Button>
                <Button
                  type="primary"
                  size="middle"
                  className="w-[80px]"
                  htmlType="submit"
                  loading={loading}
                  disabled={loading}
                >
                  Submit
                </Button>
              </div>
            </Card>
          </form>
        )}
      />
    </>
  );
};

export default AddHsn;
