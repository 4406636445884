export const statusOptions = [
  { label: "Ready", value: "Ready" },
  // { label: "WIP", value: "WIP" },
  { label: "Fabric", value: "Fabric" },
];

export const chalanOptions = [
  { label: "WIP", value: "WIP" },
  { label: "Set", value: "Set" },
];

export const specificationOptions = [
  { label: "Katkut", value: "Katkut" },
  { label: "Lot", value: "Lot" },
];

export const previewOptions = [
  { label: "Comment", value: "Comment" },
  { label: "Ready Stock", value: "Ready Stock" },
  { label: "WIP Stock", value: "WIP Stock" },
  { label: "Katkut Stock", value: "Katkut Stock" },
];

export const dateOptions = [
  { label: "No of Days", value: "days" },
  { label: "Specific Date", value: "date" },
];

export const mainItemStatusesOptions = [
  { label: "Active", value: 1 },
  { label: "Inactive", value: 0 },
];

export const colorPerPageOptions = [{ label: "1", value: 1 }];

export const SizesOptions = [
  { label: "Sets", value: "Sets" },
  { label: "Sizes", value: "Sizes" },
  { label: "Quantities", value: "Quantities" },
];

export const sizesSelect = [
  { label: "34", value: "34" },
  { label: "36", value: "36" },
  { label: "38", value: "38" },
  { label: "40", value: "40" },
  { label: "42", value: "42" },
  { label: "44", value: "44" },
  { label: "46", value: "46" },
  { label: "48", value: "48" },
];

export const outputTypeOptions = [
  { label: "pdf", value: "pdf" },
  { label: "image", value: "image" },
];
