import React, { useState } from "react";
import { Button, Empty } from "antd";
import { Form, Field } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import arrayMutators from "final-form-arrays";
import InputFieldV2 from "utils/v2/InputFieldV2";
import { useDispatch, useSelector } from "react-redux";
import {
  addMasterList,
  getMasterList,
  masterSelectors,
} from "slices/masterSlice";
import { Helmet } from "react-helmet";
import { useHotkeys } from "react-hotkeys-hook";
import { PlusOutlined, DeleteOutlined } from "@ant-design/icons";

const masterName = "pricelist";

const AddPrice = () => {
  const dispatch = useDispatch();
  const loading = useSelector(masterSelectors?.getIsUpdatingMasterList);

  const validateFloat = (value) => {
    if (!value) return "Required";
    const floatValue = parseFloat(value);
    if (isNaN(floatValue)) return "Must be a number";
    return undefined;
  };

  const onSubmit = async (values, form) => {
    const priceRanges = values.priceRanges || [];
    const payload = {
      name: values.name,
      priceListTable: priceRanges.map((range) => ({
        fromMrp: parseFloat(range.fromMrp),
        toMrp: parseFloat(range.toMrp),
        markDown: parseFloat(range.markDown),
      })),
    };

    let res = await dispatch(
      addMasterList({
        masterName,
        formData: payload,
      })
    );
    if (res?.type === "master/add/fulfilled") {
      form.restart();
      dispatch(
        getMasterList({
          masterName,
        })
      );
    }
  };

  const required = (value) => (value ? undefined : "Required");

  useHotkeys("alt+l", () => window.location.reload());
  useHotkeys("alt+s", (event) => {
    event.preventDefault();
    document
      .getElementById("form")
      .dispatchEvent(new Event("submit", { cancelable: true, bubbles: true }));
  });

  const NumberField = ({ input, meta, label }) => (
    <InputFieldV2
      input={{
        ...input,
        onChange: (e) => {
          const value = e.target.value;
          if (value === "" || /^\d*\.?\d*$/.test(value)) {
            input.onChange(value);
          }
        },
      }}
      meta={meta}
      type="text"
      label={label}
    />
  );

  return (
    <>
      <Helmet>
        <title>Price | Add</title>
      </Helmet>
      <Form
        onSubmit={onSubmit}
        mutators={{ ...arrayMutators }}
        initialValues={{ priceRanges: [] }}
        render={({ handleSubmit, form, values }) => {
          return (
            <form onSubmit={handleSubmit} id="form">
              <>
                <h1 className="text-2xl mb-5">Add Price</h1>
                <div className="mb-6">
                  <Field name="name" validate={required}>
                    {({ input, meta }) => (
                      <InputFieldV2
                        input={input}
                        meta={meta}
                        type="text"
                        label="Name"
                      />
                    )}
                  </Field>
                </div>

                <div className="border p-4 rounded mb-4">
                  <div className="flex justify-between items-center mb-3">
                    <h2 className="text-lg font-medium">Price Ranges</h2>
                    <Button
                      type="primary"
                      icon={<PlusOutlined />}
                      onClick={() => form.mutators.push("priceRanges", {})}
                      className="bg-blueBase"
                    >
                      Add Range
                    </Button>
                  </div>

                  <FieldArray name="priceRanges">
                    {({ fields }) => (
                      <div>
                        {fields.length === 0 && (
                          <Empty
                            description="No price ranges added. Click the 'Add Range' button to add one."
                            className="my-5"
                          />
                        )}

                        {fields.map((name, index) => (
                          <div className="grid grid-cols-4 gap-4 my-5">
                            <div>
                              <Field
                                name={`${name}.fromMrp`}
                                validate={validateFloat}
                              >
                                {({ input, meta }) => (
                                  <NumberField
                                    input={input}
                                    meta={meta}
                                    label="From MRP"
                                  />
                                )}
                              </Field>
                            </div>
                            <div>
                              <Field
                                name={`${name}.toMrp`}
                                validate={validateFloat}
                              >
                                {({ input, meta }) => (
                                  <NumberField
                                    input={input}
                                    meta={meta}
                                    label="To MRP"
                                  />
                                )}
                              </Field>
                            </div>
                            <div>
                              <Field
                                name={`${name}.markDown`}
                                validate={validateFloat}
                              >
                                {({ input, meta }) => (
                                  <NumberField
                                    input={input}
                                    meta={meta}
                                    label="Markdown"
                                  />
                                )}
                              </Field>
                            </div>
                            <Button
                              type="text"
                              className="w-10 h-10 items-center"
                              danger
                              icon={<DeleteOutlined />}
                              onClick={() => fields.remove(index)}
                            />
                          </div>
                        ))}
                      </div>
                    )}
                  </FieldArray>
                </div>

                <div className="flex gap-5 justify-end mt-5">
                  <Button
                    type="primary"
                    size="small"
                    className="bg-secondaryBase w-[80px]"
                    htmlType="button"
                    onClick={form.reset}
                  >
                    Clear
                  </Button>
                  <Button
                    type="primary"
                    size="small"
                    className="bg-redBase w-[80px]"
                    htmlType="submit"
                    loading={loading}
                    disabled={loading}
                  >
                    Submit
                  </Button>
                </div>
              </>
            </form>
          );
        }}
      />
    </>
  );
};

export default AddPrice;
