import { Button } from "antd";
import React, { useState, useEffect } from "react";

const SizeRatioTable = ({ sizes = [], onClose, onUpdate }) => {
  const [ratio, setRatio] = useState({});

  const handleRatioChange = (size, value) => {
    setRatio((prev) => ({ ...prev, [size]: Number(value) }));
  };

  useEffect(() => {
    const ratioData = sizes.map((size) => ({
      ratio: (ratio[size.id] || 0).toString(),
      id: size.id.toString(),
    }));

    if (ratioData.some((item) => parseFloat(item.ratio) > 0)) {
      onUpdate?.(ratioData);
    }
  }, [ratio, sizes]);

  return (
    <div className="overflow-x-auto p-4 border rounded-lg shadow-sm">
      <div className="flex justify-between mb-4">
        <h3 className="text-lg font-semibold">Size Ratio Details</h3>
        <Button
          variant="outline"
          size="sm"
          onClick={onClose}
          className="hover:bg-gray-100"
        >
          Cancel
        </Button>
      </div>

      <table className="w-auto min-w-[400px] border-collapse">
        <thead>
          <tr className="bg-secondaryBase ">
            {sizes.map((size) => (
              <th
                key={size.id}
                className="border border-grey px-4 py-2 text-sm font-medium text-gray-700"
              >
                {size.size}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          <tr>
            {sizes.map((size) => (
              <td key={size.id} className="border border-grey p-2">
                <input
                  type="number"
                  value={ratio[size.id] || ""}
                  onChange={(e) => handleRatioChange(size.id, e.target.value)}
                  className="w-full text-center border rounded p-1 focus:outline-none focus:ring-2 focus:ring-blue-500"
                  placeholder="Enter ratio"
                />
              </td>
            ))}
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default SizeRatioTable;
