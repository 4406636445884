import { Tooltip } from "antd";

export const ReduxFormTextField = ({
	input,
	label,
	type = "text",
	maxLength = 200,
	placeholder,
	meta: { touched, error },
	className = "",
	...inputProps
}) => {
	// console.log(label,inputProps.value,input)
	return (
		<>
			{label && (
				<label htmlFor="textInput" className="flex font-medium">
					<span className="text-md text-secondaryDark">{label}</span>
				</label>
			)}
			<Tooltip title={touched && error ? error : null}>
				<input
					{...input}
					id="textInput"
					className={`mt-0 md:w-60 w-full pt-3 pb-0 px-0.5 border-0 border-b-[1px] focus:ring-0 focus:border-black ${
						touched && error ? "border-redBase" : "border-secondaryBase"
					} ${className}`}
					type={type}
					placeholder={placeholder}
					maxLength={maxLength}
					{...inputProps}
				/>
			</Tooltip>
		</>
	);
};

export const ReduxFormTextField1 = ({
	input,
	label,
	type = "text",
	maxLength = 200,
	placeholder,
	meta: { touched, error },
	className = "",
	...inputProps
}) => {
	// console.log(input)
	return (
		<>
			{label && (
				<label htmlFor="textInput" className="flex font-medium">
					<span className="text-md text-secondaryDark">{label}</span>
				</label>
			)}
			<Tooltip title={touched && error ? error : null}>
				<input
					{...input}
					id="textInput"
					className={`mt-0 md:w-25 w-full pt-3 pb-0 px-0.5 border-0 border-b-[1px] focus:ring-0 focus:border-black ${
						touched && error ? "border-redBase" : "border-secondaryBase"
					} ${className}`}
					type={type}
					placeholder={placeholder}
					maxLength={maxLength}
					{...inputProps}
				/>
			</Tooltip>
		</>
	);
};


export const ReduxFormTextField3 = ({
	input,
	label,
	type = "text",
	maxLength = 200,
	placeholder,
	meta: { touched, error },
	className = "",
	...inputProps
}) => {
	return (
		<>
			{label && (
				<label htmlFor="textInput" className="flex font-medium">
					<span className="text-md text-secondaryDark">{label}</span>
				</label>
			)}
			<Tooltip title={touched && error ? error : null}>
				<input
					{...input}
					id="textInput"
					className={`mt-0 w-full pt-3 pb-0 px-0.5 border-0 border-b-[1px] focus:ring-0 focus:border-black ${
						touched && error ? "border-redBase" : "border-secondaryBase"
					} ${className}`}
					type={type}
					placeholder={placeholder}
					maxLength={maxLength}
					{...inputProps}
				/>
			</Tooltip>
		</>
	);
};
