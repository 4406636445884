import { notification } from "antd";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { formValueSelector, getFormValues, reduxForm } from "redux-form";
import {
  addReadyStockEntry,
  editOutwardStockEntry,
  getDropdownList,
  getFilteredColorList,
  getOutwardDetails,
  masterSelectors,
} from "slices/masterSlice";
import { renderMobileColumn } from "utils/renderMobileColumn";
import EntryTableV2 from "./outwardV2/EntryTableV2";
import MainFilter from "./outwardV2/MainFilter";
import SaleOrderTable from "./outwardV2/SaleOrderTable";
import StockTable from "./outwardV2/StockTable";

const formName = "outward";
const masterName = "outward";
const OutwardStockEntryName = "outwardStock";
const formSelector = formValueSelector(formName);

const OutwardV2 = ({ initialData }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const outwardStockDetails = useSelector(
    masterSelectors?.getOutwardStockDetails
  );

  const ready = outwardStockDetails?.data?.map((item) => item?.Readystock);
  const sales = outwardStockDetails?.data?.map((item) => item?.Saleorder);

  const outwardList = useSelector(masterSelectors?.getOutwardList);
  const readyStock = outwardList?.readystock;
  const saleOrder = outwardList?.saleorder;
  let [readyStockData, setReadyStockData] = useState([]);
  let [tempStockData, setTempStockData] = useState([]);
  const [loading, setLoading] = useState(id ? true : false);

  const isUpdatingMaster = useSelector(
    masterSelectors?.getIsUpdatingReadyStockEntryList
  );

  const [update, setUpdate] = useState();

  const [isUpdated, setIsUpdated] = useState(false);

  useEffect(() => {
    if (id) {
      setReadyStockData(ready?.[0]);
      setTempStockData(ready?.[0]);
    } else {
      setReadyStockData(readyStock);
      setTempStockData(readyStock);
    }
  }, [readyStock, id, outwardStockDetails]);

  // useEffect(() => {
  // 	if (id) {
  // 		dispatch(getMasterDetails({ masterName: OutwardStockEntryName, id }));
  // 		setLoading(false);
  // 	}
  // 	return () => dispatch(resetMasterDetails(masterName));
  // }, [dispatch, id]);

  const subCategoryOptions = useSelector(
    masterSelectors?.getSubCategoriesDropdownList
  );

  const partyNameOptions = useSelector(
    masterSelectors?.getPartyNameDropdownList
  );

  const mainItemsOptions = useSelector(
    masterSelectors?.getMainItemsDropdownList
  );

  const mainItemsOptions1 = mainItemsOptions?.map((item) => {
    return { value: item?.label, label: item?.label };
  });

  const colorOptions = useSelector(masterSelectors?.getColorsDropdownList);
  const colorOptions1 = colorOptions?.map((item) => {
    return { value: item?.label, label: item?.label };
  });

  const isFetchingDropdownList = useSelector(
    masterSelectors?.getIsFetchingMasterList
  );
  const isFetchingMasterList = useSelector(
    masterSelectors?.getIsFetchingMasterList
  );

  const subCategory = useSelector((state) =>
    formSelector(state, "subCategory")
  );
  const mainItem = useSelector((state) => formSelector(state, "mainItems"));
  const partyName = useSelector((state) => formSelector(state, "party"));

  const formValues = useSelector(getFormValues(formName));

  const mainitemsale = saleOrder?.length
    ? Array.from(new Set(saleOrder.map((item) => item.mainitem)))
    : [];

  const mainitemsaleFilters = mainitemsale.map((mainitemsale) => ({
    text: mainitemsale,
    value: mainitemsale,
  }));

  const party = saleOrder?.length
    ? Array.from(new Set(saleOrder.map((item) => item.party)))
    : [];

  const partyFilters = party.map((party) => ({
    text: party,
    value: party,
  }));

  const instruction = saleOrder?.length
    ? Array.from(new Set(saleOrder.map((item) => item.instruction)))
    : [];

  const instructionFilters = instruction.map((instruction) => ({
    text: instruction,
    value: instruction,
  }));

  const subcategory = saleOrder?.length
    ? Array.from(new Set(saleOrder.map((item) => item.subcategory)))
    : [];

  const subCategoryFilters = subcategory.map((subCategory) => ({
    text: subCategory,
    value: subCategory,
  }));
  const readyStatus = saleOrder?.length
    ? Array.from(new Set(saleOrder.map((item) => item.ReadyStatus)))
    : [];

  const readyStatusFilters = readyStatus.map((readyStatus) => ({
    text: readyStatus,
    value: readyStatus,
  }));
  const mainItems = readyStock?.length
    ? Array.from(new Set(readyStock.map((item) => item.mainitem)))
    : [];

  const mainItemFilters = mainItems.map((mainItems) => ({
    text: mainItems,
    value: mainItems,
  }));
  const color = readyStock?.length
    ? Array.from(new Set(readyStock.map((item) => item.color)))
    : [];

  const colorFilters = color.map((color) => ({
    text: color,
    value: color,
  }));

  const katkut = readyStock?.length
    ? Array.from(new Set(readyStock.map((item) => item.Katkut)))
    : [];

  const katkutFilters = katkut.map((katkuts) => ({
    text: katkuts,
    value: katkuts,
  }));

  useEffect(() => {
    dispatch(
      getDropdownList({
        masterName: "subcategory",
      })
    );
  }, [dispatch]);

  // useEffect(() => {
  // 	dispatch(
  // 		getDropdownList({
  // 			masterName: "mainitem",
  // 		})
  // 	);
  // }, [dispatch]);

  useEffect(() => {
    let subcategoryList = subCategory?.map((subCat) => subCat?.value);
    if (subcategoryList?.[0] === "*")
      subcategoryList = subcategoryList?.splice(1);

    if (subcategoryList?.length) {
      dispatch(
        getDropdownList({
          masterName: "mainitem",
          query: { subcategoryList },
        })
      );
    }
  }, [subCategory, dispatch]);

  useEffect(() => {
    dispatch(
      getDropdownList({
        masterName: "partyName",
      })
    );
  }, [dispatch]);

  useEffect(
    (key) => {
      let mainItemList = mainItem?.map((item) => item?.value);
      if (mainItemList?.[0] === "*") mainItemList = mainItemList?.splice(1);

      if (mainItemList?.length)
        dispatch(
          getDropdownList({
            masterName: "colors",
            query: { mainitemList: mainItemList },
          })
        );
    },
    [dispatch, mainItem]
  );

  const handleSubmit = () => {
    const formValues = {
      mainItem: mainItem?.map((item) => item?.value) || [],
      partyName: partyName?.map((item) => item?.value) || [],
    };
    dispatch(
      getOutwardDetails({
        masterName,
        formData: formValues,
      })
    );
  };

  const [api, contextHolder] = notification.useNotification();
  const openNotification = () => {
    api.open({
      message: "Stock Insufficient",
      duration: 2,
      className: "notification",
    });
  };

  const handleFormSubmission = async () => {
    let val = entryData;
    let errorsExist = false;

    val.forEach((entry) => {
      const selectedColor = entry?.color;
      const selectedItem = entry?.mainitem;

      const stockDetailsForColor = readyStock?.find(
        (item) => item.color === selectedColor && item.mainitem === selectedItem
      );

      if (stockDetailsForColor) {
        const {
          size85,
          size90,
          size95,
          size100,
          size105,
          size110,
          size115,
          size120,
        } = entry;

        if (
          size85 > stockDetailsForColor.size85 ||
          size90 > stockDetailsForColor.size90 ||
          size95 > stockDetailsForColor.size95 ||
          size100 > stockDetailsForColor.size100 ||
          size105 > stockDetailsForColor.size105 ||
          size110 > stockDetailsForColor.size110 ||
          size115 > stockDetailsForColor.size115 ||
          size120 > stockDetailsForColor.size120
        ) {
          console.log(
            `Entered quantity exceeds for available stock of ${selectedColor}`
          );
          errorsExist = true;
          openNotification();
        }
      }
    });

    if (!errorsExist) {
      if (id) {
        let temp = {
          stock: val.map((item) => ({
            id: item?.id,
            mainitem: item?.mainitem,
            color: item?.color,
            partyName: item?.partyName,
            size34: item?.size85 || 0,
            size36: item?.size90 || 0,
            size38: item?.size95 || 0,
            size40: item?.size100 || 0,
            size42: item?.size105 || 0,
            size44: item?.size110 || 0,
            size46: item?.size115 || 0,
            size48: item?.size120 || 0,
            comment: item?.comment || "-",
            total:
              Number(item?.size85) +
                Number(item?.size90) +
                Number(item?.size95) +
                Number(item?.size100) +
                Number(item?.size105) +
                Number(item?.size110) +
                Number(item?.size115) +
                Number(item?.size120) || 0,
            status: 1,
            delete: item?.delete || 0,
          })),
        };
        const formValues2 = {
          stock: temp?.stock,
          id: id,
        };

        if (temp?.stock?.length !== 0) {
          const isConfirmed = window.confirm(
            "Are you sure you want to update this stock entry?"
          );

          if (isConfirmed) {
            setIsUpdated(true);
            try {
              const result = await dispatch(
                editOutwardStockEntry({
                  masterName: OutwardStockEntryName,
                  formData: formValues2,
                  navigate: navigate,
                })
              ).unwrap();

              // if (result) {
              //   notification.success({
              //     message: "Success",
              //     description: "Stock entry updated successfully",
              //   });
              //   // navigate("/working/outward/outwardStockEntry");
              // }
            } catch (error) {
              notification.error({
                message: "Error",
                description: "Failed to update stock entry",
              });
            } finally {
              setIsUpdated(false);
            }
          }
        }
      } else {
        let temp = {
          stock: val.map((item) => ({
            mainitem: item?.mainitem,
            color: item?.color,
            partyName: item?.partyName,
            size34: item?.size85 || 0,
            size36: item?.size90 || 0,
            size38: item?.size95 || 0,
            size40: item?.size100 || 0,
            size42: item?.size105 || 0,
            size44: item?.size110 || 0,
            size46: item?.size115 || 0,
            size48: item?.size120 || 0,
            comment: item?.comment || "-",
            total:
              Number(item?.size85) +
                Number(item?.size90) +
                Number(item?.size95) +
                Number(item?.size100) +
                Number(item?.size105) +
                Number(item?.size110) +
                Number(item?.size115) +
                Number(item?.size120) || 0,
            status: 1,
          })),
        };
        if (temp?.stock?.length !== 0) {
          const isConfirmed = window.confirm(
            "Are you sure you want to add this new stock entry?"
          );

          if (isConfirmed) {
            setIsUpdated(true);
            try {
              const result = await dispatch(
                addReadyStockEntry({
                  masterName: OutwardStockEntryName,
                  formData: temp,
                  navigate: navigate,
                })
              ).unwrap();

              // if (result) {
              //   notification.success({
              //     message: "Success",
              //     description: "New stock entry added successfully",
              //   });
              // navigate("outward/working/outwardStockEntry");
              // }
            } catch (error) {
              notification.error({
                message: "Error",
                description: "Failed to add stock entry",
              });
            } finally {
              setIsUpdated(false);
            }
          }
        }
      }
    }
  };

  const [entryData, setEntryData] = useState([]);

  useEffect(() => {
    if (id && initialData?.entry?.length) setEntryData([...initialData?.entry]);
  }, [id, initialData]);

  const addRow = () => {
    const newData = {
      id: Math.random().toString(36).substr(2, 9),
      partyName: "",
      mainitem: null,
      color: null,
      size85: "",
      size90: "",
      size95: "",
      size100: "",
      size105: "",
      size110: "",
      size115: "",
      size120: "",
      comment: "-",
    };
    let temp = entryData;
    temp.unshift(newData);
    setEntryData([...temp]);
  };

  const [filterValue, setFilterValue] = useState([]);
  const [tempColorFilter, setTempColorFilter] = useState([]);
  const [tempKatkutFilter, setTempKatkutFilter] = useState([]);
  const handleClick = (value) => {
    const newData = {
      id: Math.random().toString(36).substr(2, 9),
      partyName: value?.party,
      mainitem: value?.mainitem,
      color: null,
      size85: "",
      size90: "",
      size95: "",
      size100: "",
      size105: "",
      size110: "",
      size115: "",
      size120: "",
      comment: "",
    };
    setEntryData([newData, ...entryData]);
    setFilterValue([value.mainitem]);
    setTempColorFilter([...tempColorFilter]);
    setTempKatkutFilter([...tempKatkutFilter]);
  };

  useEffect(() => {
    if (filterValue?.length) {
      dispatch(
        getFilteredColorList({
          masterName: "outward/color_dropdown",
          mainItem: filterValue[0],
        })
      );
    }
  }, [filterValue, dispatch]);

  useEffect(() => {
    if (id && initialData?.entry?.length)
      dispatch(
        getFilteredColorList({
          masterName: "outward/color_dropdown",
          mainItem: initialData?.entry[0].mainitem,
        })
      );
  }, [id, initialData?.entry, dispatch]);

  return (
    <>
      <Helmet>
        <title>Outward Form</title>
      </Helmet>
      <MainFilter
        subCategoryOptions={subCategoryOptions}
        partyNameOptions={partyNameOptions}
        mainItemsOptions={mainItemsOptions}
        isFetchingDropdownList={isFetchingDropdownList}
        handleSubmit={handleSubmit}
        subCategory={subCategory}
        partyName={partyName}
      />

      <SaleOrderTable
        data={id ? sales?.[0] : saleOrder}
        loading={isFetchingMasterList}
        handleClick={handleClick}
        mainitemsaleFilters={mainitemsaleFilters}
        partyFilters={partyFilters}
        subCategoryFilters={subCategoryFilters}
        readyStatusFilters={readyStatusFilters}
      />

      <div className="flex overflow-x-auto">
        <StockTable
          data={readyStockData} //id ? ready?.[0] :
          loading={isFetchingMasterList}
          renderMobileColumn={renderMobileColumn}
          mainItemFilters={mainItemFilters}
          colorFilters={colorFilters}
          katkutFilters={katkutFilters}
          filterValue={[...filterValue]}
          setFilterValue={setFilterValue}
          tempColorFilter={tempColorFilter}
          setTempColorFilter={setTempColorFilter}
          tempKatkutFilter={tempKatkutFilter}
          setTempKatkutFilter={setTempKatkutFilter}
        />
        <EntryTableV2
          addRow={addRow}
          data={entryData}
          setData={setEntryData}
          mainItemsOptions1={mainItemsOptions1}
          colorOptions={colorOptions1}
          readyStockData={readyStockData || []}
          setReadyStockData={setReadyStockData}
          tempStockData={tempStockData || []}
          isFetchingDropdownList={isFetchingDropdownList}
          handleFormSubmission={handleFormSubmission}
          isUpdatingMaster={isUpdatingMaster}
          isUpdated={isUpdated}
          setTempStockData={setTempStockData}
        />
      </div>
      {contextHolder}
      {/* <div className="md:mr-10 mb-5 flex justify-end">
				<Button
					className="mt-7 text-white bg-redBase hover:text-white font-semibold"
					onClick={handleFormSubmission}
					loading={isUpdatingMaster}
				>
					Submit
				</Button>
			</div> */}
    </>
  );
};

export default reduxForm({
  form: formName,
  enableReinitialize: true,
})(OutwardV2);
