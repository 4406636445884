import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  Modal,
  Form as AntForm,
  Checkbox,
  Card,
  Typography,
  Row,
  Col,
  message,
  Tooltip,
  Select,
} from "antd";
import { Form, Field } from "react-final-form";
import InputFieldV2 from "utils/v2/InputFieldV2";
import { useDispatch, useSelector } from "react-redux";
import {
  addMasterList,
  getMasterList,
  masterSelectors,
  getMasterDropDownList,
  getAllMasterList,
} from "slices/masterSlice";
import { Helmet } from "react-helmet";
import TextareaFieldV2 from "utils/v2/TextareFieldV2";
import { ColourSelector } from "./ColourSelector";
import SizeRatioGrid from "../size/SizeRatioGrid";
import { MdDelete } from "react-icons/md";
import { ReduxFormSelectField } from "utils/ReduxFormSelectField";
import {
  InfoCircleOutlined,
  PlusOutlined,
  SaveOutlined,
  ClearOutlined,
  ShoppingOutlined,
  TagOutlined,
  BgColorsOutlined,
  SisternodeOutlined,
  FileTextOutlined,
} from "@ant-design/icons";

const { Title, Text } = Typography;

const masterName = "mainitem/new";
const hsnDropdownMaster = "hsn_dropdown";
const categoryDropdownMaster = "categorydropdown";
const subCategoryDropdownMaster = "subcategory_dropdown";
const collectionDropdownMaster = "collection_dropdown";
const mainItemNewDropdown = "mainitemnew_dropdown";

const AddMainItem = () => {
  const dispatch = useDispatch();
  const loading = useSelector(masterSelectors?.getIsUpdatingMasterList);

  const colorList = useSelector(masterSelectors?.getAllColorsMasterList);
  const loadingDropdown = useSelector(masterSelectors?.getIsFetchingDropdown);
  const hsnDropdown = useSelector(masterSelectors?.getHsnDropdown);
  const categoryDropdown = useSelector(masterSelectors?.getCategoryDropdown);
  const subCategoryDropdown = useSelector(
    masterSelectors?.getSubCategoryDropdown
  );
  const collectionDropdown = useSelector(
    masterSelectors?.getCollectionDropdown
  );

  const mainItemsOptions = useSelector(masterSelectors?.getMainitemNewDropdown);

  const [item_images, setImage] = useState(null);
  const [colors, setColors] = useState([]);
  const [inputColor, setInputColor] = useState("");
  const [showSizeGrid, setShowSizeGrid] = useState(false);
  const [categorySpecificSizes, setCategorySpecificSizes] = useState([]);
  const [sizeRatios, setSizeRatios] = useState([]);
  const [mrp, setMrp] = useState(0);
  const [category, setCategory] = useState("");
  const [code, setCode] = useState("");
  const [name, setName] = useState("");
  const [totalRatio, setTotalRatio] = useState(0);

  const [isColorModalVisible, setIsColorModalVisible] = useState(false);
  const [colorForm] = AntForm.useForm();

  // console.log({ colorList, colors });

  const [colorImage, setColorImage] = useState(null);
  const [selectedColorIds, setSelectedColorIds] = useState([]);

  const handleColorSelect = (colorId, isChecked) => {
    if (isChecked) {
      setSelectedColorIds([...selectedColorIds, colorId]);
    } else {
      setSelectedColorIds(selectedColorIds.filter((id) => id !== colorId));
    }
  };

  const formRef = useRef(null);

  useEffect(() => {
    dispatch(getMasterDropDownList({ masterName: categoryDropdownMaster }));
    dispatch(getMasterDropDownList({ masterName: subCategoryDropdownMaster }));
    dispatch(getMasterDropDownList({ masterName: hsnDropdownMaster }));
    dispatch(getMasterDropDownList({ masterName: collectionDropdownMaster }));
    dispatch(getMasterDropDownList({ masterName: mainItemNewDropdown }));

    dispatch(
      getMasterList({
        masterName: "allcolors",
      })
    );
  }, [dispatch]);

  useEffect(() => {
    if (name && code !== null && code !== undefined) {
      const last3 = name.length >= 3 ? name.slice(-3) : name.padStart(3, "");

      const rawCode = `${code}${last3}`;

      if (formRef.current) {
        formRef.current.change("rawMaterialCode", rawCode);
      }
    }
  }, [code, name]);

  useEffect(() => {
    if (categorySpecificSizes.length > 0) {
      setShowSizeGrid(true);
    }
  }, [categorySpecificSizes]);

  const fetchSizesForCategory = async (categoryName) => {
    if (!categoryName) return;

    try {
      const response = await dispatch(
        getMasterList({
          masterName: "size",
          query: { is_active: 1 },
        })
      );

      if (response?.payload?.data?.data) {
        const filteredSizes = response?.payload?.data?.data
          .filter(
            (size) =>
              size.categoryId?.name?.toUpperCase() ===
              categoryName?.toUpperCase()
          )
          .map((item) => ({
            id: item.id,
            size: item.size,
          }))
          .sort((a, b) => a.id - b.id);

        console.log("Fetching sizes for:", categoryName);
        console.log("Filtered Sizes:", filteredSizes);
        setCategorySpecificSizes(filteredSizes);
      }
    } catch (error) {
      console.error("Error fetching sizes:", error);
    }
  };

  const removeItemImage = (index) => {
    const updatedColors = colors.filter((_, i) => i !== index);
    setColors(updatedColors);
  };

  console.log({ colors });
  const onSubmit = async (values, form) => {
    if (colors.length > 0 && totalRatio !== 100) {
      message.error("Please enter exact 100 ratio");
      return;
    }

    console.log({ values });
    const payload = {
      comment: values?.comment || null,
      wsp: parseFloat(values.wsp || 0),
      mrp: parseFloat(values.mrp || 0),
      collectionId: values?.collection || null,
      alternateMainItemId: values?.alternateMainItem || null,
      rawMaterialCode: values?.rawMaterialCode || null,
      rawMaterialComment: values?.rawMaterialComment || null,
      name: values.name,
      categoryId: values.category_name || null,
      subcategoryId: values.sub_category_name,
      hsnId: values.hsnId,
      rawMaterialColors: colors?.map((color) => ({
        colorId: color.color_id,
        colorFamilyId: color.color_family.id,
        ratio: color.ratio,
      })),
      rateMaster: sizeRatios?.map((ratio) => ({
        mrp: ratio.mrp.toString(),
        ratio: ratio.ratio.toString(),
        sizeId: ratio.sizeId.toString(),
        wsp: ratio.wsp.toString(),
        priceListId: ratio.priceListId.toString(),
        sizeRatioId: ratio.sizeRatioId,
        markDown: ratio.markDown.toString(),
      })),
    };

    console.log({ payload });

    const formData = new FormData();

    colors.forEach((color, index) => {
      if (color.item_images) {
        formData.append(`colorImages`, color.item_images);
      }
    });

    formData.append("mainitemImage", item_images);

    formData.append(
      "mainitem",
      new Blob([JSON.stringify(payload)], {
        type: "application/json",
      })
    );

    try {
      const res = await dispatch(
        addMasterList({
          masterName,
          formData,
          configType: "multipart/form-data",
        })
      );

      if (res?.type === "master/add/fulfilled") {
        message.success("Main item added successfully");
        form.restart();
        setColors([]);
        setInputColor("");
        setImage(null);
        setCategorySpecificSizes([]);
        setShowSizeGrid(false);
        setSizeRatios([]);
        dispatch(getMasterList({ masterName: "newMainitem" }));
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      message.error("Failed to add main item");
    }
  };

  const required = (value) => (value ? undefined : "Required");

  const handleSizeRatioUpdate = (ratios) => {
    setSizeRatios(ratios);
  };

  const addColor = () => {
    if (inputColor.trim() !== "") {
      setColors([
        ...colors,
        { color_name: inputColor.toUpperCase(), item_images: null },
      ]);
      setInputColor("");
    }
  };

  const handleAddColor = () => {
    setIsColorModalVisible(true);
  };

  const handleColorModalOk = () => {
    if (selectedColorIds.length === 0) {
      message.warning("Please select at least one color");
      return;
    }

    const selectedColors = colorList
      .filter((color) => selectedColorIds?.includes(color.id))
      .map((color) => ({
        color_id: color.id,
        color_name: color.colorName,
        color_family: color.colorFamily,
        item_images: null,
        imageUrl: null,
      }));

    setColors([...colors, ...selectedColors]);
    setSelectedColorIds([]);
    setColorImage(null);
    setIsColorModalVisible(false);
    message.success(`${selectedColors.length} colors added`);
  };

  const handleColorModalCancel = () => {
    setIsColorModalVisible(false);
    colorForm.resetFields();
    setColorImage(null);
    setSelectedColorIds([]);
  };

  const resetForm = (form) => {
    form.reset();
    setColors([]);
    setImage(null);
    setCategorySpecificSizes([]);
    setShowSizeGrid(false);
    setSizeRatios([]);
    message.info("Form cleared");
  };

  return (
    <>
      <Helmet>
        <title>Main Item | Add</title>
      </Helmet>
      <Form
        onSubmit={onSubmit}
        subscription={{ values: true }}
        render={({ handleSubmit, form, values }) => {
          if (formRef.current !== form) {
            formRef.current = form;
          }

          return (
            <form onSubmit={handleSubmit} className="px-[10rem] py-5">
              <Card
                className="mb-6 "
                title={
                  <div className="flex items-center ">
                    <ShoppingOutlined className="mr-2 text-blue-600" />
                    <Title level={4} style={{ margin: 0 }}>
                      Add Main Item
                    </Title>
                  </div>
                }
              >
                <Card
                  className="mb-4"
                  type="inner"
                  title={
                    <div className="flex items-center">
                      <InfoCircleOutlined className="mr-2 text-blue-500" />
                      <Text strong>Basic Information</Text>
                    </div>
                  }
                >
                  <Row
                    gutter={[16, 16]}
                    className="px-[8rem] py-5 flex justify-between"
                  >
                    <Col xs={12} md={8} lg={6}>
                      <Field name="name" validate={required}>
                        {({ input, meta }) => (
                          <InputFieldV2
                            input={{
                              ...input,
                              onChange: (e) => {
                                input.onChange(e);
                                setName(e.target.value);
                              },
                            }}
                            meta={meta}
                            type="text"
                            label="Name"
                            required
                            style="border-secondaryBase"
                            // customWidth={"full"}
                          />
                        )}
                      </Field>
                    </Col>

                    <Col xs={12} md={8} lg={6}>
                      <div>
                        <label>Category</label>
                        <Field name="category_name">
                          {({ input, meta }) => (
                            <Tooltip
                              title={
                                meta.touched && meta.error ? meta.error : null
                              }
                            >
                              <Select
                                style={{
                                  width: "100%",
                                  borderRadius: "6px",
                                }}
                                showSearch
                                loading={loadingDropdown}
                                placeholder="Category"
                                allowClear
                                disabled={showSizeGrid}
                                filterOption={(input, option) =>
                                  (option?.label ?? "")
                                    .toLowerCase()
                                    .includes(input.toLowerCase())
                                }
                                options={categoryDropdown}
                                {...input}
                                onChange={(value) => {
                                  input.onChange(value);
                                  setCategory(value);
                                  const selectedCategory =
                                    categoryDropdown.find(
                                      (cat) => cat.value === value
                                    )?.label;
                                  const markdown =
                                    categoryDropdown.find(
                                      (cat) => cat.value === value
                                    )?.markDown || 0;

                                  form.change("wsp", markdown);

                                  fetchSizesForCategory(selectedCategory);
                                }}
                              />
                            </Tooltip>
                          )}
                        </Field>
                      </div>
                    </Col>
                    <Col xs={12} md={8} lg={6}>
                      <div>
                        <label>Sub-category</label>
                        <Field name="sub_category_name">
                          {({ input, meta }) => (
                            <Tooltip
                              title={
                                meta.touched && meta.error ? meta.error : null
                              }
                            >
                              <Select
                                style={{
                                  width: "100%",
                                  border: `${
                                    meta.touched && meta.error
                                      ? "1px solid red"
                                      : ""
                                  }`,
                                  // border: `${
                                  //   meta.touched && meta.error ? "1px solid red" : ""
                                  // }`,
                                  borderRadius: "6px",
                                }}
                                showSearch
                                filterOption={(input, option) =>
                                  (option?.label ?? "")
                                    .toLowerCase()
                                    .includes(input.toLowerCase())
                                }
                                loading={loadingDropdown}
                                placeholder="Sub-category"
                                allowClear
                                // onSearch={(e) =>
                                //   dispatch(
                                //     getMasterDropDownList({
                                //       masterName: subCategoryDropdownMaster,
                                //       query: { name: e },
                                //     })
                                //   )
                                // }
                                options={subCategoryDropdown}
                                {...input}
                                onChange={(value) => {
                                  input.onChange(value);
                                  setCode(
                                    () =>
                                      subCategoryDropdown.find(
                                        (subcat) => subcat.value === value
                                      )?.code || 0
                                  );
                                }}
                              />
                            </Tooltip>
                          )}
                        </Field>
                      </div>
                    </Col>
                  </Row>
                  <Row
                    gutter={[16, 16]}
                    className="px-[8rem] py-5 flex justify-between"
                  >
                    <Col xs={12} md={8} lg={6}>
                      <div>
                        <label>HSN Code</label>
                        <Field name="hsnId">
                          {({ input, meta }) => (
                            <Tooltip
                              title={
                                meta.touched && meta.error ? meta.error : null
                              }
                            >
                              <Select
                                style={{
                                  width: "100%",
                                  border: `${
                                    meta.touched && meta.error
                                      ? "1px solid red"
                                      : ""
                                  }`,
                                  // border: `${
                                  //   meta.touched && meta.error ? "1px solid red" : ""
                                  // }`,
                                  borderRadius: "6px",
                                }}
                                showSearch
                                loading={loadingDropdown}
                                placeholder="HSN code"
                                allowClear
                                // onSearch={(e) =>
                                //   dispatch(
                                //     getMasterDropDownList({
                                //       masterName: hsnDropdownMaster,
                                //       query: { name: e },
                                //     })
                                //   )
                                // }
                                filterOption={(input, option) =>
                                  (option?.label ?? "")
                                    .toLowerCase()
                                    .includes(input.toLowerCase())
                                }
                                options={hsnDropdown}
                                {...input}
                              />
                            </Tooltip>
                          )}
                        </Field>
                      </div>
                    </Col>

                    <Col xs={12} md={8} lg={6}>
                      <div>
                        <label>Collection</label>
                        <Field name="collection">
                          {({ input, meta }) => (
                            <Tooltip
                              title={
                                meta.touched && meta.error ? meta.error : null
                              }
                            >
                              <Select
                                style={{
                                  width: "100%",
                                  border: `${
                                    meta.touched && meta.error
                                      ? "1px solid red"
                                      : ""
                                  }`,
                                  // border: `${
                                  //   meta.touched && meta.error ? "1px solid red" : ""
                                  // }`,
                                  borderRadius: "6px",
                                }}
                                showSearch
                                loading={loadingDropdown}
                                placeholder="Collection"
                                allowClear
                                filterOption={(input, option) =>
                                  (option?.label ?? "")
                                    .toLowerCase()
                                    .includes(input.toLowerCase())
                                }
                                options={collectionDropdown}
                                {...input}
                              />
                            </Tooltip>
                          )}
                        </Field>
                      </div>
                    </Col>

                    <Col xs={12} md={8} lg={6}>
                      <div>
                        <label>Alternate Main Item</label>
                        <Field name="alternateMainItem">
                          {({ input, meta }) => (
                            <Tooltip
                              title={
                                meta.touched && meta.error ? meta.error : null
                              }
                            >
                              <Select
                                style={{
                                  width: "100%",
                                  border: `${
                                    meta.touched && meta.error
                                      ? "1px solid red"
                                      : ""
                                  }`,
                                  // border: `${
                                  //   meta.touched && meta.error ? "1px solid red" : ""
                                  // }`,
                                  borderRadius: "6px",
                                }}
                                showSearch
                                loading={loadingDropdown}
                                placeholder="Collection"
                                allowClear
                                filterOption={(input, option) =>
                                  (option?.label ?? "")
                                    .toLowerCase()
                                    .includes(input.toLowerCase())
                                }
                                options={mainItemsOptions}
                                {...input}
                              />
                            </Tooltip>
                          )}
                        </Field>
                      </div>
                    </Col>
                  </Row>
                  <Row
                    gutter={[16, 16]}
                    className="px-[8rem] py-5 flex justify-between"
                  >
                    <Col xs={12} md={8} lg={6}>
                      <Field name="wsp">
                        {({ input, meta }) => (
                          <InputFieldV2
                            input={input}
                            meta={meta}
                            type="number"
                            label="WSP (Wholesale Price)"
                            disabled
                          />
                        )}
                      </Field>
                    </Col>
                    <Col xs={12} md={8} lg={6}>
                      <Field name="mrp">
                        {({ input, meta }) => (
                          <InputFieldV2
                            input={{
                              ...input,
                              onChange: (e) => {
                                const value = Number(e.target.value) || 0;
                                setMrp(value);
                                input.onChange(e);
                              },
                            }}
                            meta={meta}
                            type="number"
                            label="MRP (Maximum Retail Price)"
                            required
                          />
                        )}
                      </Field>
                    </Col>
                    <Col xs={12} md={8} lg={6}>
                      <div>
                        <label className="block mb-1">Item Image</label>
                        <input
                          type="file"
                          accept="image/*"
                          className="border p-2 w-full rounded-md"
                          onChange={(e) => setImage(e.target.files[0])}
                        />
                        {item_images && (
                          <div className="mt-2">
                            <Text type="secondary">
                              Image selected: {item_images.name}
                            </Text>
                          </div>
                        )}
                      </div>
                    </Col>
                  </Row>
                </Card>

                {/* Colors Section */}
                <Card
                  className="mb-4"
                  type="inner"
                  title={
                    <div className="flex items-center">
                      <BgColorsOutlined className="mr-2 text-purple-500" />
                      <Text strong>Color Options</Text>
                    </div>
                  }
                >
                  <div className="mb-3 px-[4rem] py-10">
                    <Button
                      type="primary"
                      icon={<PlusOutlined />}
                      onClick={handleAddColor}
                    >
                      Add Colors
                    </Button>
                  </div>

                  {colors.length > 0 ? (
                    <div>
                      <ColourSelector
                        totalRatio={totalRatio}
                        setTotalRatio={setTotalRatio}
                        rawMaterialColours={colors}
                        removeItemImage={(index) => {
                          const updatedColors = colors.filter(
                            (_, i) => i !== index
                          );
                          setColors(updatedColors);
                        }}
                        isViewOnly={false}
                        setRawMaterialColours={setColors}
                        additionalColorImage={colorImage}
                      />
                    </div>
                  ) : (
                    <div className="text-center py-6 border border-dashed border-gray-300 rounded-md">
                      <Text type="secondary">
                        No colors added yet. Click 'Add Colors' to select
                        colors.
                      </Text>
                    </div>
                  )}

                  <Modal
                    title={
                      <div className="flex items-center">
                        <BgColorsOutlined className="mr-2 text-purple-500" />
                        <span>Select Colors</span>
                      </div>
                    }
                    visible={isColorModalVisible}
                    onOk={handleColorModalOk}
                    onCancel={handleColorModalCancel}
                    width={500}
                    bodyStyle={{ maxHeight: "60vh", overflow: "auto" }}
                    footer={[
                      <Button key="cancel" onClick={handleColorModalCancel}>
                        Cancel
                      </Button>,
                      <Button
                        key="ok"
                        type="primary"
                        icon={<PlusOutlined />}
                        onClick={handleColorModalOk}
                      >
                        Add Selected Colors
                      </Button>,
                    ]}
                  >
                    <div className="mb-2">
                      <Text>
                        Select one or more colors to add to this item:
                      </Text>
                    </div>
                    <div className="color-list flex flex-col max-h-96 overflow-y-auto border border-gray-200 rounded-md p-2">
                      {colorList?.map((color) => (
                        <div
                          key={color.id}
                          className="flex items-start py-3 px-2 border-b border-gray-200 last:border-b-0 hover:bg-gray-50 rounded-md"
                        >
                          <Checkbox
                            onChange={(e) =>
                              handleColorSelect(color.id, e.target.checked)
                            }
                            className="mt-1 mr-3"
                          />
                          <div>
                            <div className="font-medium text-base">
                              {color.colorName}
                            </div>
                            <div className="text-gray-500 text-sm">
                              {color.colorFamily?.colorFamily}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </Modal>

                  <div className="flex justify-end mt-4">
                    <Button
                      type="primary"
                      size="middle"
                      icon={<PlusOutlined />}
                      onClick={() => {
                        if (!values.category_name) {
                          message.error("Please select a category first");
                          return;
                        }

                        if (categorySpecificSizes.length === 0) {
                          message.error(
                            "No sizes available for the selected category!"
                          );
                          return;
                        }
                        setShowSizeGrid(true);
                      }}
                    >
                      Add Sizes and Rates
                    </Button>
                  </div>

                  {showSizeGrid && categorySpecificSizes.length > 0 && (
                    <div className="mt-4">
                      <SizeRatioGrid
                        sizes={categorySpecificSizes}
                        onClose={() => setShowSizeGrid(false)}
                        onUpdate={(rateMaster) => setSizeRatios(rateMaster)}
                        mainItemMRP={mrp}
                      />
                    </div>
                  )}
                </Card>

                <Card
                  type="inner"
                  title={
                    <div className="flex items-center">
                      <FileTextOutlined className="mr-2 text-orange-500" />
                      <Text strong>Additional Information</Text>
                    </div>
                  }
                >
                  <Row gutter={[16, 16]} className="px-[8rem] py-5 flex">
                    <Col xs={12} md={8} lg={6}>
                      <Field name="rawMaterialCode">
                        {({ input, meta }) => (
                          <InputFieldV2
                            input={input}
                            meta={meta}
                            type="text"
                            label="Raw Material Code"
                            disabled
                          />
                        )}
                      </Field>
                    </Col>
                    <Col xs={12} md={8} lg={8}>
                      <Field name="comment">
                        {({ input, meta }) => (
                          <TextareaFieldV2
                            input={input}
                            meta={meta}
                            label="General Comments"
                          />
                        )}
                      </Field>
                    </Col>

                    <Col xs={12} md={8} lg={6}>
                      <Field name="rawMaterialComment">
                        {({ input, meta }) => (
                          <TextareaFieldV2
                            input={input}
                            meta={meta}
                            label="Raw Material Comments"
                          />
                        )}
                      </Field>
                    </Col>
                  </Row>
                </Card>
                <div className="flex gap-3 justify-end px-10">
                  <Button
                    icon={<ClearOutlined />}
                    onClick={() => resetForm(form)}
                  >
                    Clear
                  </Button>
                  <Button
                    type="primary"
                    // icon={<SubmitOutlined />}
                    htmlType="submit"
                    loading={loading}
                    disabled={loading}
                  >
                    Submit
                  </Button>
                </div>
              </Card>
            </form>
          );
        }}
      />
    </>
  );
};

export default AddMainItem;
