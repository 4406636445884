import React, { useState, useCallback } from "react";
import { Pagination, Table, Button, Modal, Tooltip } from "antd";
import { Helmet } from "react-helmet";
import { renderMobileColumn } from "utils/renderMobileColumn";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Field, formValueSelector, reduxForm } from "redux-form";
import { getMasterList, masterSelectors } from "slices/masterSlice";
import useMasterLogic from "customHooks/useMasterLogic";
import { TfiReload } from "react-icons/tfi";

const masterName = "colorfamily";
export const masterStatuses = [
  { label: "Active", value: 1 },
  { label: "Inactive", value: 0 },
];

const ColorFamily = () => {
  const { status } = useParams();
  const formSelector = formValueSelector(masterName);
  const dispatch = useDispatch();
  const loading = useSelector(masterSelectors?.getIsFetchingMasterList);
  const importing = useSelector(masterSelectors?.getIsImportingExcel);
  const exporting = useSelector(masterSelectors?.getIsExportingExcel);
  const list = useSelector(masterSelectors?.getColorFamilyMasterList);
  const { pageSize, totalElements, currentPage } = useSelector(
    masterSelectors?.getColorFamilyPagination
  );

  console.log({ list });
  const getListData = useCallback(
    (pageNum = 1, itemsPerPage = 10) => {
      dispatch(
        getMasterList({
          masterName,
          query: {
            page: pageNum,
            pageSize: itemsPerPage,
            is_active: status,
          },
        })
      );
    },
    [status, dispatch]
  );

  const { handlePageChange } = useMasterLogic(getListData, masterName);

  const [data, setData] = useState({});
  const [type, setType] = useState("update");

  const handleSetData = (value) => {
    setType("update");
    setData(value);
  };

  const handleDuplicate = (value) => {
    setType("new");
    setData(value);
  };

  // const handleImport = () => {
  //   dispatch(
  //     ImportExcelFile({
  //       masterName: "category/export/excel",
  //       name: "category",
  //     })
  //   );
  // };
  const [show, setShow] = useState(false);
  // const excelFile = useSelector((state) => formSelector(state, "item_excel"));
  // const handleExport = () => {
  //   setShow(false);
  //   const form = new FormData();
  //   form.append("file", excelFile);
  //   dispatch(
  //     ExportExcelFile({
  //       masterName: "category/upload/import",
  //       formData: form,
  //       configType: "multipart/form-data",
  //     })
  //   );
  // };

  const columns = [
    {
      title: "ColorFamily",
      render: (data) => {
        const primaryText = data?.colorFamily || "-";
        return renderMobileColumn(primaryText);
      },
      responsive: ["xs"],
    },
    {
      title: "Color Family",
      dataIndex: "colorFamily",
      key: "colorFamily",
      responsive: ["sm"],
      render: (value, data) => (
        <p onDoubleClick={() => handleSetData(data)} className="cursor-pointer">
          {value || "-"}
        </p>
      ),
    },

    //
  ];

  return (
    <>
      <Helmet>
        <title>Color Family</title>
      </Helmet>
      <div className="flex justify-between items-center mb-2">
        <h1 className="text-2xl">Color Family List</h1>
        <div className="flex gap-5">
          <Tooltip title="Reload">
            <Button
              type="default"
              size="small"
              loading={loading}
              disabled={loading}
              onClick={() => getListData(1, 10)}
            >
              <TfiReload />
            </Button>
          </Tooltip>

          {/* <Button
            type="default"
            size="small"
            loading={exporting}
            disabled={exporting}
            onClick={() => setShow(true)}
          >
            Import Excel
          </Button>
          <Button
            onClick={handleImport}
            loading={importing}
            disabled={exporting}
            type="default"
            size="small"
          >
            Export Excel
          </Button> */}
          <Link to="/colorFamily/add" target="_blank">
            <Button type="primary" size="small" className="w-[80px]">
              Add New
            </Button>
          </Link>
        </div>
      </div>
      <Table
        dataSource={list}
        columns={columns}
        rowKey={(item) => item?.id}
        pagination={false}
        loading={loading}
        scroll={{ y: 400 }}
        bordered
        style={{ minHeight: "360px" }}
      />
      {list?.length ? (
        <div className="flex justify-end">
          <Pagination
            pageSize={pageSize}
            total={totalElements}
            current={currentPage}
            onChange={handlePageChange}
          />
        </div>
      ) : null}
      <div className="mb-20"></div>

      {/* <Modal
        title="Upload Excel File"
        open={show}
        onOk={handleExport}
        onCancel={() => setShow(false)}
      >
        <Field
          name="item_excel"
          component={ReduxFormFileField}
          className="mt-1"
          placeholder="Item Image"
          itemname="excel file"
        />
        <p className="mt-2 text-lg font-bold">{excelFile?.name}</p>
      </Modal> */}
    </>
  );
};

export default reduxForm({
  form: masterName,
  enableReinitialize: true,
})(ColorFamily);
