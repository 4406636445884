import { useDispatch, useSelector } from "react-redux";
import {
  Field,
  FieldArray,
  change,
  formValueSelector,
  reduxForm,
} from "redux-form";
import { getDropdownList, masterSelectors } from "slices/masterSlice";

import { Image } from "antd";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { MdDelete } from "react-icons/md";
import { ReduxFormFileField } from "utils/ReduxFormFileField";
import { ReduxFormTextField } from "utils/ReduxFormTextField";
import { onWheelHandler } from "utils/onWheelHandler";
import { renderModalButtons } from "utils/renderModalButtons";
import { RenderColours } from "./renderColours";
import { ReduxFormSelectField } from "utils/ReduxFormSelectField";

const formName = "mainItems";
const formSelector = formValueSelector(formName);

const MainItemForms = ({ title, onCancel, handleSubmit, isViewOnly }) => {
  const dispatch = useDispatch();
  const isUpdatingMaster = useSelector(
    masterSelectors?.getIsUpdatingMasterList
  );
  const rawMaterialColours = useSelector((state) =>
    formSelector(state, "raw_material_colors")
  );
  const seasonOptions = useSelector(masterSelectors?.getSeasonsDropdownList);
  const collectionsOptions = useSelector(
    masterSelectors?.getCollectionsDropdownList
  );
  const isFetchingDropdownList = useSelector(
    masterSelectors?.getIsFetchingDropdownList
  );
  useEffect(() => {
    dispatch(getDropdownList({ masterName: "season" }));
    dispatch(getDropdownList({ masterName: "collection" }));
  }, [dispatch]);
  const removeItemImage = (index) => {
    dispatch(
      change(formName, `raw_material_colors.[${index}].item_images`, null)
    );
    dispatch(
      change(formName, `raw_material_colors.[${index}].file_name`, null)
    );
  };

  const mainColours = useSelector((state) =>
    formSelector(state, "main_images")
  );

  const removeMainImage = () => {
    dispatch(change(formName, "main_images", null));
  };

  const imageUrl =
    mainColours instanceof File
      ? URL.createObjectURL(mainColours)
      : mainColours;

  return (
    <>
      <Helmet>
        <title>Main-Item Form</title>
      </Helmet>
      <form onSubmit={handleSubmit}>
        <h6 className="text-2xl font-bold md:mb-5 mb-10">{title}</h6>
        <div className="md:flex block md:flex-wrap">
          <div className="md:mr-10 mb-5">
            <Field
              component={ReduxFormTextField}
              label="Item Number"
              name="itm_no"
              disabled
            />
          </div>
          <div className="md:mr-10 mb-5">
            <Field
              component={ReduxFormTextField}
              label="Item Name"
              name="name"
              disabled
            />
          </div>
          <div className="md:mr-10 mb-5">
            <Field
              component={ReduxFormTextField}
              label="Category"
              name="category"
              disabled
            />
          </div>
          <div className="md:mr-10 mb-5">
            <Field
              component={ReduxFormTextField}
              label="Subcategory"
              name="subcategory"
              disabled
            />
          </div>
          <div className="md:mr-10 mb-5">
            <Field
              component={ReduxFormTextField}
              label="HSN Code"
              name="hsn_code"
              disabled
            />
          </div>
          <div className="md:mr-10 mb-5">
            <Field
              component={ReduxFormTextField}
              label="Comment"
              name="comment"
              disabled
            />
          </div>
          <div className="md:mr-10 mb-5">
            <Field
              component={ReduxFormTextField}
              name="type_of_item"
              label="Upload Type"
              disabled
            />
          </div>
          <div className="md:mr-10 mb-5">
						<Field
							component={ReduxFormSelectField}
							label="Season"
							name="season"
							options={seasonOptions}
							placeholder="Select Seasons"
							isLoading={isFetchingDropdownList}
						/>
					</div>
				<div className="md:mr-10 mb-5">
					<Field
						component={ReduxFormSelectField}
						label="Collection"
						name="collection"
						options={collectionsOptions}
						placeholder="Select Main Items"
						isLoading={isFetchingDropdownList}
					/>
				</div>
          <div className="md:mr-10 mb-5">
            <Field
              label="MRP"
              name="mrp"
              component={ReduxFormTextField}
              type="number"
              min={0}
              max={9999999999999.99}
              step={0.01}
              onWheel={(e) => onWheelHandler(e)}
              placeholder="Enter MRP"
              disabled={isViewOnly}
            />
          </div>
          <div className="md:mr-10 mb-5">
            <Field
              label="WSP"
              name="wsp"
              component={ReduxFormTextField}
              type="number"
              min={0}
              max={9999999999999.99}
              step={0.01}
              onWheel={(e) => onWheelHandler(e)}
              placeholder="Enter WSP"
              disabled={isViewOnly}
            />
          </div>
        </div>
        <div className="flex flex-wrap flex-col mb-5 w-80 max-w-[95%] rounded shadow-xl mr-10">
          {imageUrl ? (
            <>
              <Image
                src={imageUrl}
                alt="colour img"
                className="!object-contain !h-56"
                preview={{ toolbarRender: () => null }}
              />
            </>
          ) : (
            <Field
              name="main_images"
              component={ReduxFormFileField}
              className="mt-1"
              placeholder="Item Image"
              disabled={isViewOnly}
              itemname="image"
            />
          )}
          <div className="px-6 py-4 flex justify-between border-t border-[#d1d5db]">
            <div className="font-bold text-xl self-center">Cover Image</div>
            {imageUrl && (
              <button
                className="bg-redBase text-white font-semibold rounded py-2 px-4"
                type="button"
                onClick={() => removeMainImage()}
              >
                <MdDelete className="text-2xl" />
              </button>
            )}
          </div>
        </div>
        <div>
          <FieldArray
            name="raw_material_colors"
            component={RenderColours}
            rawMaterialColours={rawMaterialColours}
            removeItemImage={removeItemImage}
            isViewOnly={isViewOnly}
            isUsingReduxform={true}
          />
        </div>
        {renderModalButtons(onCancel, isViewOnly, isUpdatingMaster)}
      </form>
    </>
  );
};

export default reduxForm({
  form: formName,
})(MainItemForms);
