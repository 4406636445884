import React, { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  Select,
  Tooltip,
  Card,
  Divider,
  Typography,
  Row,
  Col,
  Space,
  Upload,
} from "antd";
import { Form, Field } from "react-final-form";
import InputFieldV2 from "utils/v2/InputFieldV2";
import { useDispatch, useSelector } from "react-redux";
import {
  addMasterList,
  getMasterDropDownList,
  getMasterList,
  masterSelectors,
} from "slices/masterSlice";
import { Helmet } from "react-helmet";
import TextareaFieldV2 from "utils/v2/TextareFieldV2";
import arrayMutators from "final-form-arrays";
import { FieldArray } from "react-final-form-arrays";
import { useHotkeys } from "react-hotkeys-hook";
import { ReduxFormSelectField2 } from "utils/ReduxFormSelectField";
import {
  UploadOutlined,
  UserOutlined,
  HomeOutlined,
  SettingOutlined,
  ContactsOutlined,
  CarOutlined,
} from "@ant-design/icons";
import { change, formValueSelector } from "redux-form";

const { Title, Text } = Typography;

const masterName = "customer";
const customerDropddown = "customer_dropdown";
const cityDropdownMaster = "city_dropdown";
const agentDropdownMaster = "agent_dropdown";
const transportDropdownMaster = "transport_dropdown";
const shoptypeDropdown = "shoptype_dropdown";
const dispatchcategoryDropdown = "dispatchcategory_dropdown";

const AddCustomer = () => {
  const dispatch = useDispatch();
  const loading = useSelector(masterSelectors?.getIsUpdatingMasterList);
  const cityDropdown = useSelector(masterSelectors?.getCityDropdown);
  const agentDropdown = useSelector(masterSelectors?.getAgentDropdown);
  const dispatchCategoryDropdown = useSelector(
    masterSelectors?.getDispatchCategoryDropdown
  );
  const shopTypeDropdown = useSelector(masterSelectors?.getShopTypeDropdown);
  const transportDropdown = useSelector(masterSelectors?.getTransportDropdown);
  const loadingDropdown = useSelector(masterSelectors?.getIsFetchingDropdown);
  const customerDropdown = useSelector(masterSelectors?.getCustomerDropdown);
  const countryDropdown = useSelector(masterSelectors?.getCountryDropdown);
  const stateDropdown = useSelector(masterSelectors?.getStateDropdown);

  const [whatsappEnable, setWhatsappEnable] = useState(false);
  const [merchandiseEnable, setMerchandiseEnable] = useState(false);
  const [salesInvoiceEnable, setSalesInvoiceEnable] = useState(false);
  const [lrEnable, setLrEnable] = useState(false);
  const [emailEnable, setEmailEnable] = useState(false);
  const [documentImg, setDocumentImg] = useState(null);
  const [selectedState, setSelectedState] = useState(null);

  useEffect(() => {
    dispatch(getMasterDropDownList({ masterName: agentDropdownMaster }));
    dispatch(getMasterDropDownList({ masterName: transportDropdownMaster }));
    dispatch(getMasterDropDownList({ masterName: customerDropddown }));
    dispatch(getMasterDropDownList({ masterName: shoptypeDropdown }));
    dispatch(getMasterDropDownList({ masterName: dispatchcategoryDropdown }));
    dispatch(getMasterDropDownList({ masterName: "country_dropdown" }));
    dispatch(getMasterDropDownList({ masterName: "state_dropdown" }));
  }, [dispatch]);

  useEffect(() => {
    console.log("Selected state changed:", selectedState);
    if (selectedState) {
      console.log("Fetching cities for state:", selectedState);
      dispatch(
        getMasterDropDownList({
          masterName: cityDropdownMaster,
          query: { state: selectedState },
        })
      );
    }
  }, [dispatch, selectedState]);

  useEffect(() => {
    console.log("City dropdown updated:", cityDropdown);
  }, [cityDropdown]);

  const onSubmit = async (values, form) => {
    //  debugger;
    // if (!values?.customerContactPerson?.length) {
    //   return alert("Please add Contact Persons.");
    // }
    // if (!values?.customerDeliveryDetails?.length) {
    //   return alert("Please add Delivery Details.");
    // }
    // if (!values?.agentDetails?.length) {
    //   return alert("Please add agent Details");
    // }

    // console.log({ values });

    const transformedValues = {
      partyName: values.partyName,
      addressLine1: values.addressLine1,
      addressLine2: values.addressLine2,
      cityId: values.cityId,
      mobile: values.mobile,
      pincode: values.pincode,
      telephone: values.telephone,
      country: values.country?.value,
      emailId: values.emailId,
      paymentTerms: values.paymentTerms,
      pan: values.pan,
      notes: values.notes,
      gstin: values.gstin,
      creditLimit: values.creditLimit,
      notes_SO: values.notes_SO || "",
      notes_SI: values.notes_SI || "",
      nickName: values.nickname,
      reference: values.reference,
      tallyGroup: values.tallyGroup,
      documentComments: values.documentComment,
      transportId: values.transportId,

      whatsappEnable: whatsappEnable ? "1" : "0",
      emailEnable: emailEnable ? "1" : "0",
      merchandiseExport: merchandiseEnable ? "1" : "0",

      shopTypeId: values.shopType?.value,
      dispatchCategoryId: values.dispatchCategory?.value,
      principlePartyId: values.principleParty?.value,

      discount1: values.discount1,
      discount2: values.discount2,
      discount3: values.discount3,

      customerAgent: values.agentDetails?.map((agent) => ({
        agentId: parseInt(agent.agentId?.value),
      })),

      customerContactPerson: values.customerContactPerson?.map((person) => ({
        name: person.name,
        mobileNo: person.mobileNo,
        emailId: person.emailId,
        department: person.department,
        designation: person.designation,
        salesInvoice: salesInvoiceEnable ? 1 : 0,
        lr: lrEnable ? 1 : 0,
      })),

      customerDeliveryDetails: values.customerDeliveryDetails?.map((detail) => ({
        addressLine1: detail.addressLine1,
        addressLine2: values.deliveryAddressLine2,
        country: detail.country,
        cityId: detail.cityId,
        pincode: detail.pincode,
      })),
    };

    console.log({ transformedValues });
    const formData = new FormData();
    formData.append(
      "customer",
      new Blob([JSON.stringify(transformedValues)], {
        type: "application/json",
      })
    );

    if (documentImg) {
      formData.append("document", documentImg);
    }

    const res = await dispatch(
      addMasterList({
        masterName,
        formData: formData,
        configType: "multipart/form-data",
      })
    );

    if (res?.type === "master/add/fulfilled") {
      form.restart();
      dispatch(getMasterList({ masterName }));
    }

    setEmailEnable(false);
    setLrEnable(false);
    setEmailEnable(false);
    setWhatsappEnable(false);
  };

  const required = (value) => (value ? undefined : "Required");

  useHotkeys("alt+l", () => window.location.reload());
  useHotkeys("alt+s", (event) => {
    event.preventDefault();
    document
      .getElementById("form")
      .dispatchEvent(new Event("submit", { cancelable: true, bubbles: true }));
  });

  return (
    <>
      <Helmet>
        <title>Customer | Add</title>
      </Helmet>
      <Form
        onSubmit={onSubmit}
        mutators={{
          ...arrayMutators,
        }}
        render={({ handleSubmit, form }) => (
          <form
            onSubmit={handleSubmit}
            id="form"
            className="bg-gray-50 py-6 px-[10rem]  rounded-lg"
          >
            <>
              <div className="flex justify-between items-center mb-8">
                <Title level={2} className="m-0">
                  <UserOutlined className="mr-2" />
                  Add Customer
                </Title>
              </div>

              {/* Basic Information Section */}
              <Card
                title={
                  <>
                    <UserOutlined className="mr-2" />
                    Basic Information
                  </>
                }
                className="mb-6 shadow-sm border "
                headStyle={{
                  backgroundColor: "#f0f5ff",
                  borderBottom: "1px solid #d9e6ff",
                }}
              >
                <Row
                  gutter={[16, 16]}
                  className="px-[8rem] py-5 flex justify-between"
                >
                  <Col xs={24} sm={12} md={8} lg={8}>
                    <Field name="partyName" validate={required}>
                      {({ input, meta }) => (
                        <InputFieldV2
                          input={input}
                          meta={meta}
                          type="text"
                          label=" Name"
                        />
                      )}
                    </Field>
                  </Col>
                  <Col xs={24} sm={12} md={8} lg={8}>
                    <Field name="nickname">
                      {({ input, meta }) => (
                        <InputFieldV2
                          input={input}
                          meta={meta}
                          type="text"
                          label="Nickname"
                        />
                      )}
                    </Field>
                  </Col>
                  <Col xs={24} sm={12} md={8} lg={8}>
                    <Field name="gstin">
                      {({ input, meta }) => (
                        <InputFieldV2
                          input={input}
                          meta={meta}
                          type="text"
                          label="GSTIN"
                        />
                      )}
                    </Field>
                  </Col>
                  <Col xs={24} sm={12} md={8} lg={8}>
                    <Field name="pan">
                      {({ input, meta }) => (
                        <InputFieldV2
                          input={input}
                          meta={meta}
                          type="text"
                          label="PAN"
                        />
                      )}
                    </Field>
                  </Col>
                  <Col xs={24} sm={12} md={8} lg={8}>
                    <Field name="mobile">
                      {({ input, meta }) => (
                        <InputFieldV2
                          input={input}
                          meta={meta}
                          type="number"
                          label="Mobile No."
                        />
                      )}
                    </Field>
                  </Col>
                  <Col xs={24} sm={12} md={8} lg={8}>
                    <Field name="telephone">
                      {({ input, meta }) => (
                        <InputFieldV2
                          input={input}
                          meta={meta}
                          type="number"
                          label="Telephone No."
                        />
                      )}
                    </Field>
                  </Col>
                  <Col xs={24} sm={12} md={8} lg={8}>
                    <Field name="emailId">
                      {({ input, meta }) => (
                        <InputFieldV2
                          input={input}
                          meta={meta}
                          type="text"
                          label="Email"
                        />
                      )}
                    </Field>
                  </Col>
                  <Col xs={24} sm={12} md={8} lg={8}>
                    <div className="flex items-center h-full">
                      <Checkbox
                        checked={emailEnable}
                        onChange={(e) => setEmailEnable(e.target.checked)}
                        className="mr-2"
                      />
                      <Text>Email Notifications</Text>
                    </div>
                  </Col>
                  <Col xs={24} sm={12} md={8} lg={8}>
                    <div className="flex items-center h-full">
                      <Checkbox
                        checked={whatsappEnable}
                        onChange={(e) => setWhatsappEnable(e.target.checked)}
                        className="mr-2"
                      />
                      <Text>WhatsApp Notifications</Text>
                    </div>
                  </Col>
                </Row>
              </Card>

              {/* Address & Location Section */}
              <Card
                title={
                  <>
                    <HomeOutlined className="mr-2" />
                    Address & Location
                  </>
                }
                className="mb-6 shadow-sm"
                headStyle={{
                  backgroundColor: "#f7f7ff",
                  borderBottom: "1px solid #e6e6ff",
                }}
              >
                <Row
                  gutter={[16, 16]}
                  className="px-[8rem] py-5 flex justify-between"
                >
                  <Col xs={24} sm={12} md={12} lg={8}>
                    <Field name="addressLine1">
                      {({ input, meta }) => (
                        <InputFieldV2
                          input={input}
                          meta={meta}
                          type="text"
                          label="Address Line 1"
                        />
                      )}
                    </Field>
                  </Col>
                  <Col xs={24} sm={12} md={12} lg={8}>
                    <Field name="addressLine2">
                      {({ input, meta }) => (
                        <InputFieldV2
                          input={input}
                          meta={meta}
                          type="text"
                          label="Address Line 2"
                        />
                      )}
                    </Field>
                  </Col>
                  <Col xs={24} sm={12} md={8} lg={6}>
                    <Field
                      component={ReduxFormSelectField2}
                      label="Country"
                      name="country"
                      placeholder="Select country"
                      options={countryDropdown}
                      isLoading={loadingDropdown}
                      isMulti={false}
                    />
                  </Col>
                  <Col xs={24} sm={12} md={8} lg={6}>
                    <div className="flex flex-col">
                      <Text className="mb-1">State</Text>
                      <Field name="state">
                        {({ input, meta }) => (
                          <Select
                            style={{
                              width: "100%",
                              border: `${
                                meta.touched && meta.error
                                  ? "1px solid red"
                                  : ""
                              }`,
                              borderRadius: "6px",
                            }}
                            showSearch
                            loading={loadingDropdown}
                            placeholder="Select State"
                            allowClear
                            options={stateDropdown}
                            value={input.value}
                            onChange={(value) => {
                              input.onChange(value);

                              console.log("State selected:", value);
                              setSelectedState(value);

                              form.change("cityId", null);
                            }}
                          />
                        )}
                      </Field>
                    </div>
                  </Col>
                  <Col xs={24} sm={12} md={8} lg={6}>
                    <div className="flex flex-col">
                      <Text className="mb-1">City</Text>
                      <Field name="cityId">
                        {({ input, meta }) => (
                          <Tooltip
                            title={
                              meta.touched && meta.error ? meta.error : null
                            }
                          >
                            <Select
                              style={{
                                width: "100%",
                                border: `${
                                  meta.touched && meta.error
                                    ? "1px solid red"
                                    : ""
                                }`,
                                borderRadius: "6px",
                              }}
                              showSearch
                              loading={loadingDropdown}
                              placeholder="Select City"
                              allowClear
                              onSearch={(e) =>
                                dispatch(
                                  getMasterDropDownList({
                                    masterName: cityDropdownMaster,
                                    query: { name: e },
                                  })
                                )
                              }
                              options={cityDropdown}
                              {...input}
                            />
                          </Tooltip>
                        )}
                      </Field>
                    </div>
                  </Col>

                  <Col xs={24} sm={12} md={8} lg={6}>
                    <Field name="pincode">
                      {({ input, meta }) => (
                        <InputFieldV2
                          input={input}
                          meta={meta}
                          type="number"
                          label="Pincode"
                        />
                      )}
                    </Field>
                  </Col>
                  <Col xs={24} sm={12} md={8} lg={6}>
                    <div className="flex flex-col">
                      <Text className="mb-1">Transport</Text>
                      <Field name="transportId">
                        {({ input, meta }) => (
                          <Tooltip
                            title={
                              meta.touched && meta.error ? meta.error : null
                            }
                          >
                            <Select
                              style={{
                                width: "100%",
                                border: `${
                                  meta.touched && meta.error
                                    ? "1px solid red"
                                    : ""
                                }`,
                                borderRadius: "6px",
                              }}
                              showSearch
                              loading={loadingDropdown}
                              placeholder="Select Transport"
                              allowClear
                              onSearch={(e) =>
                                dispatch(
                                  getMasterDropDownList({
                                    masterName: transportDropdownMaster,
                                    query: { name: e },
                                  })
                                )
                              }
                              options={transportDropdown}
                              {...input}
                            />
                          </Tooltip>
                        )}
                      </Field>
                    </div>
                  </Col>
                </Row>
              </Card>

              {/* Business Details Section */}
              <Card
                title={
                  <>
                    <SettingOutlined className="mr-2" />
                    Business Details
                  </>
                }
                className="mb-6 shadow-sm"
                headStyle={{
                  backgroundColor: "#f0fff0",
                  borderBottom: "1px solid #d9ffd9",
                }}
              >
                <Row
                  gutter={[16, 16]}
                  className="px-[8rem] py-5 flex justify-between"
                >
                  <Col xs={24} sm={12} md={8} lg={6}>
                    <Field name="paymentTerms">
                      {({ input, meta }) => (
                        <InputFieldV2
                          input={input}
                          meta={meta}
                          type="text"
                          label="Payment Terms"
                        />
                      )}
                    </Field>
                  </Col>
                  <Col xs={24} sm={12} md={8} lg={6}>
                    <Field name="creditLimit">
                      {({ input, meta }) => (
                        <InputFieldV2
                          input={input}
                          meta={meta}
                          type="number"
                          label="Credit Limit"
                        />
                      )}
                    </Field>
                  </Col>
                  <Col xs={24} sm={12} md={8} lg={6}>
                    <Field name="reference">
                      {({ input, meta }) => (
                        <InputFieldV2
                          input={input}
                          meta={meta}
                          type="text"
                          label="Reference"
                        />
                      )}
                    </Field>
                  </Col>
                  <Col xs={24} sm={12} md={8} lg={6}>
                    <Field name="tallyGroup">
                      {({ input, meta }) => (
                        <InputFieldV2
                          input={input}
                          meta={meta}
                          type="text"
                          label="Tally Group"
                        />
                      )}
                    </Field>
                  </Col>
                  <Col xs={24} sm={12} md={8} lg={8}>
                    <Field
                      component={ReduxFormSelectField2}
                      label="Shop Type"
                      name="shopType"
                      placeholder="Select shop type"
                      options={shopTypeDropdown}
                      isLoading={loadingDropdown}
                      isMulti={false}
                    />
                  </Col>
                  <Col xs={24} sm={12} md={8} lg={8}>
                    <Field
                      component={ReduxFormSelectField2}
                      label="Dispatch Category"
                      name="dispatchCategory"
                      placeholder="Select category"
                      options={dispatchCategoryDropdown}
                      isLoading={loadingDropdown}
                      isMulti={false}
                    />
                  </Col>
                  <Col xs={24} sm={12} md={8} lg={8}>
                    <Field
                      component={ReduxFormSelectField2}
                      label="Principle Party"
                      name="principleParty"
                      placeholder="Select customer"
                      options={customerDropdown}
                      isLoading={loadingDropdown}
                      isMulti={false}
                    />
                  </Col>
                </Row>

                <Divider orientation="left">Discount Information</Divider>

                <Row
                  gutter={[16, 16]}
                  className="px-[8rem] py-5 flex justify-between"
                >
                  <Col xs={24} sm={12} md={8} lg={8}>
                    <Field name="discount1">
                      {({ input, meta }) => (
                        <InputFieldV2
                          input={input}
                          meta={meta}
                          type="number"
                          label="Discount 1 (%)"
                        />
                      )}
                    </Field>
                  </Col>
                  <Col xs={24} sm={12} md={8} lg={8}>
                    <Field name="discount2">
                      {({ input, meta }) => (
                        <InputFieldV2
                          input={input}
                          meta={meta}
                          type="number"
                          label="Discount 2 (%)"
                        />
                      )}
                    </Field>
                  </Col>
                  <Col xs={24} sm={12} md={8} lg={8}>
                    <Field name="discount3">
                      {({ input, meta }) => (
                        <InputFieldV2
                          input={input}
                          meta={meta}
                          type="number"
                          label="Discount 3 (%)"
                        />
                      )}
                    </Field>
                  </Col>
                </Row>

                <Divider orientation="left">
                  Notes & Additional Information
                </Divider>

                <Row
                  gutter={[16, 16]}
                  className="px-[8rem] py-5 flex justify-between"
                >
                  <Col xs={24} sm={12} md={8}>
                    <Field name="notes">
                      {({ input, meta }) => (
                        <InputFieldV2
                          input={input}
                          meta={meta}
                          type="text"
                          label="General Notes"
                        />
                      )}
                    </Field>
                  </Col>
                  <Col xs={24} sm={12} md={8}>
                    <Field name="notes_SO">
                      {({ input, meta }) => (
                        <InputFieldV2
                          input={input}
                          meta={meta}
                          type="text"
                          label="Sales Order Notes"
                        />
                      )}
                    </Field>
                  </Col>
                  <Col xs={24} sm={12} md={8}>
                    <Field name="notes_SI">
                      {({ input, meta }) => (
                        <InputFieldV2
                          input={input}
                          meta={meta}
                          type="text"
                          label="Sales Invoice Notes"
                        />
                      )}
                    </Field>
                  </Col>
                  <Col xs={24} sm={12} md={8}>
                    {/* <Field name="documentComment">
                      {({ input, meta }) => (
                        <InputFieldV2
                          input={input}
                          meta={meta}
                          type="text"
                          label="Document Comment"
                        />
                      )}
                    </Field> */}
                  </Col>
                  <Col xs={24} sm={12} md={8}>
                    <div className="flex items-center h-full">
                      <Checkbox
                        checked={merchandiseEnable}
                        onChange={(e) => setMerchandiseEnable(e.target.checked)}
                        className="mr-2"
                      />
                      <Text>Merchandise Export</Text>
                    </div>
                  </Col>
                </Row>
              </Card>

              {/* Contact Persons Section */}
              <Card
                title={
                  <>
                    <ContactsOutlined className="mr-2" />
                    Contact Persons
                  </>
                }
                className="mb-6 shadow-sm"
                headStyle={{
                  backgroundColor: "#fff8f0",
                  borderBottom: "1px solid #ffe6cc",
                }}
                extra={
                  <FieldArray name="customerContactPerson">
                    {({ fields }) => (
                      <Button
                        type="primary"
                        onClick={() => fields.push({})}
                        className="bg-orange-500"
                      >
                        Add Contact Person
                      </Button>
                    )}
                  </FieldArray>
                }
              >
                <FieldArray name="customerContactPerson">
                  {({ fields }) => (
                    <>
                      {fields.length === 0 ? (
                        <Text className="text-secondaryBase italic ml-10">
                          No contact persons added yet. Please add at least one
                          contact person.
                        </Text>
                      ) : (
                        fields.map((name, index) => (
                          <Card
                            key={name}
                            className="mb-4 border border-orange-200"
                            size="small"
                            extra={
                              <Button
                                danger
                                onClick={() => fields.remove(index)}
                                size="small"
                              >
                                Remove
                              </Button>
                            }
                          >
                            <Row
                              gutter={[16, 16]}
                              className="px-[8rem] py-5 flex justify-between"
                            >
                              <Col xs={24} sm={12} md={8} lg={6}>
                                <Field name={`${name}.name`}>
                                  {({ input, meta }) => (
                                    <InputFieldV2
                                      input={input}
                                      meta={meta}
                                      type="text"
                                      label="Name"
                                    />
                                  )}
                                </Field>
                              </Col>
                              <Col xs={24} sm={12} md={8} lg={6}>
                                <Field name={`${name}.mobileNo`}>
                                  {({ input, meta }) => (
                                    <InputFieldV2
                                      input={input}
                                      meta={meta}
                                      type="number"
                                      label="Mobile No."
                                    />
                                  )}
                                </Field>
                              </Col>
                              <Col xs={24} sm={12} md={8} lg={6}>
                                <Field name={`${name}.emailId`}>
                                  {({ input, meta }) => (
                                    <InputFieldV2
                                      input={input}
                                      meta={meta}
                                      type="text"
                                      label="Email"
                                    />
                                  )}
                                </Field>
                              </Col>
                              <Col xs={24} sm={12} md={8} lg={6}>
                                <Field name={`${name}.department`}>
                                  {({ input, meta }) => (
                                    <InputFieldV2
                                      input={input}
                                      meta={meta}
                                      type="text"
                                      label="Department"
                                    />
                                  )}
                                </Field>
                              </Col>
                              <Col xs={24} sm={12} md={8} lg={6}>
                                <Field name={`${name}.designation`}>
                                  {({ input, meta }) => (
                                    <InputFieldV2
                                      input={input}
                                      meta={meta}
                                      type="text"
                                      label="Designation"
                                    />
                                  )}
                                </Field>
                              </Col>
                              <Col xs={24} sm={12} md={8} lg={6}>
                                <div className="mt-6">
                                  <Space>
                                    <Checkbox
                                      checked={salesInvoiceEnable}
                                      onChange={(e) =>
                                        setSalesInvoiceEnable(e.target.checked)
                                      }
                                    >
                                      Sales Invoice
                                    </Checkbox>
                                    <Checkbox
                                      checked={lrEnable}
                                      onChange={(e) =>
                                        setLrEnable(e.target.checked)
                                      }
                                    >
                                      LR
                                    </Checkbox>
                                  </Space>
                                </div>
                              </Col>
                            </Row>
                          </Card>
                        ))
                      )}
                    </>
                  )}
                </FieldArray>
              </Card>

              {/* Delivery Details Section */}
              <Card
                title={
                  <>
                    <CarOutlined className="mr-2" />
                    Delivery Details
                  </>
                }
                className="mb-6 shadow-sm"
                headStyle={{
                  backgroundColor: "#f0f8ff",
                  borderBottom: "1px solid #d1e6ff",
                }}
                extra={
                  <FieldArray name="customerDeliveryDetails">
                    {({ fields }) => (
                      <Button
                        type="primary"
                        onClick={() => fields.push({})}
                        className="bg-blue-500"
                      >
                        Add Delivery Address
                      </Button>
                    )}
                  </FieldArray>
                }
              >
                <FieldArray name="customerDeliveryDetails">
                  {({ fields }) => {
                    return (
                      <>
                        {fields.length === 0 ? (
                          <Text className="text-secondaryBase  italic ml-10">
                            No delivery details added yet. Please add at least
                            one delivery address.
                          </Text>
                        ) : (
                          fields.map((name, index) => (
                            <Card
                              key={name}
                              className="mb-4 border border-blue-200"
                              size="small"
                              extra={
                                <Button
                                  danger
                                  onClick={() => fields.remove(index)}
                                  size="small"
                                >
                                  Remove
                                </Button>
                              }
                            >
                              <Row
                                gutter={[16, 16]}
                                className="px-[8rem] py-5 flex justify-between"
                              >
                                <Col xs={24} sm={12} md={4}>
                                  {/* <p>{name}</p> */}
                                  <Field name={`${name}.addressLine1`}>
                                    {({ input, meta }) => (
                                      <InputFieldV2
                                        input={input}
                                        meta={meta}
                                        type="text"
                                        label="Address Line 1"
                                      />
                                    )}
                                  </Field>
                                </Col>
                                <Col xs={24} sm={12} md={4}>
                                  {/* <p>{`${name}`}</p> */}
                                  <Field name="deliveryAddressLine2">
                                    {({ input, meta }) => (
                                      <InputFieldV2
                                        input={input}
                                        meta={meta}
                                        type="text"
                                        label="Address Line 2"
                                      />
                                    )}
                                  </Field>
                                </Col>
                                <Col xs={24} sm={12} md={4}>
                                  <Field name={`${name}.country`}>
                                    {({ input, meta }) => (
                                      <InputFieldV2
                                        input={input}
                                        meta={meta}
                                        type="text"
                                        label="Country"
                                      />
                                    )}
                                  </Field>
                                </Col>
                              </Row>
                              <Row
                                gutter={[16, 16]}
                                className="px-[8rem] py-5 flex"
                              >
                                <Col xs={24} sm={12} md={6} className="mr-32">
                                  <div className="flex flex-col mr-10">
                                    <Text className="mb-1">City</Text>
                                    <Field name={`${name}.cityId`}>
                                      {({ input, meta }) => (
                                        <Select
                                          style={{ width: "100%" }}
                                          showSearch
                                          loading={loadingDropdown}
                                          placeholder="Select City"
                                          allowClear
                                          onSearch={(e) =>
                                            dispatch(
                                              getMasterDropDownList({
                                                masterName: cityDropdownMaster,
                                                query: { name: e },
                                              })
                                            )
                                          }
                                          options={cityDropdown}
                                          {...input}
                                        />
                                      )}
                                    </Field>
                                  </div>
                                </Col>
                                <Col xs={24} sm={12} md={4}>
                                  <Field name={`${name}.pincode`}>
                                    {({ input, meta }) => (
                                      <InputFieldV2
                                        input={input}
                                        meta={meta}
                                        type="number"
                                        label="Pincode"
                                      />
                                    )}
                                  </Field>
                                </Col>
                              </Row>
                            </Card>
                          ))
                        )}
                      </>
                    );
                  }}
                </FieldArray>
              </Card>

              {/* Agent Details Section */}
              <Card
                title={
                  <>
                    <UserOutlined className="mr-2" />
                    Agent Details
                  </>
                }
                className="mb-6 shadow-sm"
                headStyle={{
                  backgroundColor: "#f5f0ff",
                  borderBottom: "1px solid #e5d9ff",
                }}
                extra={
                  <FieldArray name="agentDetails">
                    {({ fields }) => (
                      <Button
                        type="primary"
                        onClick={() => fields.push({})}
                        className="bg-purple-500"
                      >
                        Add Agent
                      </Button>
                    )}
                  </FieldArray>
                }
              >
                <FieldArray name="agentDetails">
                  {({ fields }) => (
                    <>
                      {fields.length === 0 ? (
                        <Text className="text-secondaryBase  italic ml-10">
                          No agent details added yet. Please add at least one
                          agent.
                        </Text>
                      ) : (
                        fields.map((name, index) => (
                          <Card
                            key={name}
                            className="mb-4 border border-purple-200"
                            size="small"
                            extra={
                              <Button
                                danger
                                onClick={() => fields.remove(index)}
                                size="small"
                              >
                                Remove
                              </Button>
                            }
                          >
                            <Row
                              gutter={[16, 16]}
                              className="px-[8rem] py-5 flex justify-between"
                            >
                              <Col xs={24} sm={24} md={24} lg={8}>
                                <Field
                                  component={ReduxFormSelectField2}
                                  label="Agent"
                                  name={`${name}.agentId`}
                                  placeholder="Select agent"
                                  options={agentDropdown}
                                  isLoading={loadingDropdown}
                                />
                              </Col>
                            </Row>
                          </Card>
                        ))
                      )}
                    </>
                  )}
                </FieldArray>
              </Card>

              {/* Document Upload Section */}
              <Card
                title={
                  <>
                    <UploadOutlined className="mr-2" />
                    Document Upload
                  </>
                }
                className="mb-6 shadow-sm"
                headStyle={{
                  backgroundColor: "#f0f0f0",
                  borderBottom: "1px solid #e0e0e0",
                }}
              >
                <Row
                  gutter={[16, 16]}
                  className="px-[8rem] py-5 flex justify-between"
                >
                  <Col xs={24} sm={24} md={12} lg={12}>
                    {documentImg ? (
                      <div className="flex flex-col items-center">
                        <img
                          src={URL.createObjectURL(documentImg)}
                          className="w-48 h-48 object-cover rounded-md mb-4"
                          alt="Document preview"
                        />
                        <Button
                          danger
                          onClick={() => setDocumentImg(null)}
                          className="w-48"
                        >
                          Remove Image
                        </Button>
                      </div>
                    ) : (
                      <Upload
                        beforeUpload={(file) => {
                          setDocumentImg(file);
                          return false;
                        }}
                        showUploadList={false}
                      >
                        <Button
                          icon={<UploadOutlined />}
                          className="w-full h-32 flex flex-col items-center justify-center bg-gray-50 border-dashed border-2 border-gray-300 rounded-lg"
                        >
                          <p className="mt-2 text-gray-600">Upload Document</p>
                          <p className="text-xs text-gray-400">
                            Click or drag file to this area
                          </p>
                        </Button>
                      </Upload>
                    )}
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12}>
                    <div className="h-full flex flex-col">
                      {/* <Title level={5} className="mb-2">
                        Document Information
                      </Title>
                      <Text className="text-gray-500 mb-4">
                        Upload any relevant documents such as company
                        registration, credit agreements, or other important
                        files.
                      </Text> */}
                      <Field name="documentComment">
                        {({ input, meta }) => (
                          <TextareaFieldV2
                            input={input}
                            meta={meta}
                            label="Document Comments"
                            rows={4}
                          />
                        )}
                      </Field>
                    </div>
                  </Col>
                </Row>
              </Card>

              {/* Form Actions */}
              <div className="flex justify-end gap-4 mt-8 mr-8">
                <Button
                  type="default"
                  onClick={form.reset}
                  size="large"
                  className="px-8"
                >
                  Clear Form
                </Button>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={loading}
                  disabled={loading}
                  size="large"
                  className="bg-blue-600 px-8"
                >
                  Submit
                </Button>
              </div>
            </>
          </form>
        )}
      />
    </>
  );
};

export default AddCustomer;
