export function formatDateTime(dateString) {
  const date = new Date(dateString);

  const timeString = date
    .toLocaleString("en-US", {
      hour: "numeric",
      minute: "2-digit",
      hour12: true,
    })
    .toLowerCase();

  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear();

  return `${timeString}, ${day}-${month}-${year}`;
}
