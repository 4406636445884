import React, { useEffect } from "react";
import { Button, Select, Tooltip } from "antd";
import { Form, Field } from "react-final-form";
import InputFieldV2 from "utils/v2/InputFieldV2";
import { useDispatch, useSelector } from "react-redux";
import {
  addMasterList,
  getMasterDropDownList,
  getMasterList,
  masterSelectors,
} from "slices/masterSlice";
import { Helmet } from "react-helmet";
import TextareaFieldV2 from "utils/v2/TextareFieldV2";
import arrayMutators from "final-form-arrays";
import { FieldArray } from "react-final-form-arrays";
import { useHotkeys } from "react-hotkeys-hook";

const masterName = "agent";
const cityDropdownMaster = "city_dropdown";
const AddAgent = () => {
  const dispatch = useDispatch();
  const loading = useSelector(masterSelectors?.getIsUpdatingMasterList);
  const cityDropdown = useSelector(masterSelectors?.getCityDropdown);
  const loadingDropdown = useSelector(masterSelectors?.getIsFetchingDropdown);

  useEffect(() => {
    dispatch(getMasterDropDownList({ masterName: cityDropdownMaster }));
  }, []);

  const onSubmit = async (values, form) => {
    // if (
    //   values?.agentContactPerson === undefined ||
    //   values?.agentContactPerson?.length === 0
    // ) {
    //   return alert("Please add Agent Contact Persons.");
    // }

    let res = await dispatch(
      addMasterList({
        masterName,
        formData: values,
      })
    );
    if (res?.type === "master/add/fulfilled") {
      form.restart();
      dispatch(
        getMasterList({
          masterName,
        })
      );
    }
  };

  const required = (value) => (value ? undefined : "Required");
  useHotkeys("alt+l", () => window.location.reload());
  useHotkeys("alt+s", (event) => {
    event.preventDefault();
    document
      .getElementById("form")
      .dispatchEvent(new Event("submit", { cancelable: true, bubbles: true }));
  });

  return (
    <>
      <Helmet>
        <title>Agent | Add</title>
      </Helmet>
      <Form
        onSubmit={onSubmit}
        mutators={{
          ...arrayMutators,
        }}
        render={({ handleSubmit, form }) => (
          <form onSubmit={handleSubmit} id="form">
            <>
              <h1 className="text-2xl mb-5">Add Agent</h1>
              <div className="flex gap-5">
                <div className="">
                  <Field name="agentName" validate={required}>
                    {({ input, meta }) => (
                      <InputFieldV2
                        input={input}
                        meta={meta}
                        type="text"
                        label="Name"
                      />
                    )}
                  </Field>
                </div>
                <div className="">
                  <Field name="telephone">
                    {({ input, meta }) => (
                      <InputFieldV2
                        input={input}
                        meta={meta}
                        type="number"
                        label="Telephone No."
                      />
                    )}
                  </Field>
                </div>
                <div className="">
                  <Field name="emailId">
                    {({ input, meta }) => (
                      <InputFieldV2
                        input={input}
                        meta={meta}
                        type="text"
                        label="Email"
                      />
                    )}
                  </Field>
                </div>
                <div className="">
                  <Field name="gstin">
                    {({ input, meta }) => (
                      <InputFieldV2
                        input={input}
                        meta={meta}
                        type="text"
                        label="GSTIN"
                      />
                    )}
                  </Field>
                </div>
                <div className="">
                  <Field name="pan">
                    {({ input, meta }) => (
                      <InputFieldV2
                        input={input}
                        meta={meta}
                        type="text"
                        label="PAN"
                      />
                    )}
                  </Field>
                </div>
                <div className="">
                  <Field name="mobile">
                    {({ input, meta }) => (
                      <InputFieldV2
                        input={input}
                        meta={meta}
                        type="number"
                        label="Mobile No."
                      />
                    )}
                  </Field>
                </div>
              </div>
              <div className="flex gap-5 mt-4">
                <div className="">
                  <Field name="addressLine1">
                    {({ input, meta }) => (
                      <InputFieldV2
                        input={input}
                        meta={meta}
                        type="text"
                        label="Address"
                        style="w-[385px]"
                      />
                    )}
                  </Field>
                </div>

                <div>
                  <label>City</label>
                  <br></br>
                  <Field name="cityId">
                    {({ input, meta }) => (
                      <Tooltip
                        title={meta.touched && meta.error ? meta.error : null}
                      >
                        <Select
                          style={{
                            width: "192px",
                            border: `${
                              meta.touched && meta.error ? "1px solid red" : ""
                            }`,
                            borderRadius: "6px",
                          }}
                          showSearch
                          loading={loadingDropdown}
                          placeholder="City"
                          allowClear
                          onSearch={(e) =>
                            dispatch(
                              getMasterDropDownList({
                                masterName: cityDropdownMaster,
                                query: { name: e },
                              })
                            )
                          }
                          options={cityDropdown}
                          {...input}
                        />
                      </Tooltip>
                    )}
                  </Field>
                </div>

                <div className="">
                  <Field name="country">
                    {({ input, meta }) => (
                      <InputFieldV2
                        input={input}
                        meta={meta}
                        type="text"
                        label="Country"
                      />
                    )}
                  </Field>
                </div>
                <div className="">
                  <Field name="pincode">
                    {({ input, meta }) => (
                      <InputFieldV2
                        input={input}
                        meta={meta}
                        type="number"
                        label="Pincode"
                      />
                    )}
                  </Field>
                </div>
              </div>
              <div className="flex my-4">
                <div className="w-full">
                  <Field name="notes">
                    {({ input, meta }) => (
                      <TextareaFieldV2
                        input={input}
                        meta={meta}
                        label="Notes"
                        style="w-[805px]"
                      />
                    )}
                  </Field>
                </div>
              </div>
              <div className="w-full my-4">
                <FieldArray name="agentContactPerson">
                  {({ fields }) => (
                    <div>
                      <div className="flex justify-between">
                        <p className="font-bold">Contact Persons : </p>
                        <Button
                          type="primary"
                          htmlType="button"
                          className="bg-primaryBase px-5"
                          size="small"
                          onClick={() => fields.push({})}
                        >
                          Add Contact Person
                        </Button>
                      </div>
                      {fields.map((name, index) => (
                        <div
                          key={name}
                          className="flex gap-5 mb-3 items-center"
                        >
                          <div>
                            <Field name={`${name}.name`}>
                              {({ input, meta }) => (
                                <InputFieldV2
                                  input={input}
                                  meta={meta}
                                  type="text"
                                  label="Name"
                                />
                              )}
                            </Field>
                          </div>
                          <div>
                            <Field name={`${name}.designation`}>
                              {({ input, meta }) => (
                                <InputFieldV2
                                  input={input}
                                  meta={meta}
                                  type="text"
                                  label="Designation"
                                />
                              )}
                            </Field>
                          </div>
                          <div>
                            <Field name={`${name}.department`}>
                              {({ input, meta }) => (
                                <InputFieldV2
                                  input={input}
                                  meta={meta}
                                  type="text"
                                  label="Department"
                                />
                              )}
                            </Field>
                          </div>
                          <div>
                            <Field name={`${name}.mobileNo`}>
                              {({ input, meta }) => (
                                <InputFieldV2
                                  input={input}
                                  meta={meta}
                                  type="number"
                                  label="Mobile No."
                                />
                              )}
                            </Field>
                          </div>
                          <div>
                            <Field name={`${name}.emailId`}>
                              {({ input, meta }) => (
                                <InputFieldV2
                                  input={input}
                                  meta={meta}
                                  type="text"
                                  label="Email"
                                />
                              )}
                            </Field>
                          </div>
                          <Button
                            type="primary"
                            htmlType="button"
                            size="small"
                            className="bg-redBase mt-5"
                            onClick={() => fields.remove(index)}
                          >
                            Remove
                          </Button>
                        </div>
                      ))}
                    </div>
                  )}
                </FieldArray>
              </div>
              <div className="flex gap-5 justify-end mt-10">
                <Button
                  type="primary"
                  size="small"
                  className="bg-secondaryBase w-[80px]"
                  htmlType="button"
                  onClick={form.reset}
                >
                  Clear
                </Button>
                <Button
                  type="primary"
                  size="small"
                  className="bg-redBase w-[80px]"
                  htmlType="submit"
                  loading={loading}
                  disabled={loading}
                >
                  Add
                </Button>
              </div>
            </>
          </form>
        )}
      />
    </>
  );
};

export default AddAgent;
