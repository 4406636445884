import React, { useState, useCallback } from "react";
import { Pagination, Table, Button, Tooltip } from "antd";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { formValueSelector, reduxForm } from "redux-form";
import { getMasterList, masterSelectors } from "slices/masterSlice";
import useMasterLogic from "customHooks/useMasterLogic";
import { TfiReload } from "react-icons/tfi";
import { useHotkeys } from "react-hotkeys-hook";
import { formatDateTime } from "utils/dateTime";

const masterName = "pricelist";
const Price = () => {
  const formSelector = formValueSelector(masterName);
  const dispatch = useDispatch();
  const loading = useSelector(masterSelectors?.getIsFetchingMasterList);
  const list = useSelector(masterSelectors?.getPriceMasterList);
  const { pageSize, totalElements, currentPage } = useSelector(
    masterSelectors?.getPricePagination
  );

  const getListData = useCallback(
    (pageNum = 1, itemsPerPage = 10) => {
      dispatch(
        getMasterList({
          masterName,
          query: {
            page: pageNum,
            pageSize: itemsPerPage,
          },
        })
      );
    },
    [dispatch]
  );

  const { handlePageChange } = useMasterLogic(getListData, masterName);

  const [data, setData] = useState({});
  const [type, setType] = useState("update");

  const handleSetData = (value) => {
    setType("update");
    setData(value);
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (value, data) => (
        <p onDoubleClick={() => handleSetData(data)} className="cursor-pointer">
          {value || "-"}
        </p>
      ),
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (value) => (
        <p className="cursor-pointer">{formatDateTime(value) || "-"}</p>
      ),
    },
  ];

  const expandedRowRender = (record) => {
    const childColumns = [
      {
        title: "From MRP",
        dataIndex: "fromMrp",
        key: "fromMrp",
        render: (value) => (
          <p className="cursor-pointer">
            {value !== undefined && value !== null ? value.toFixed(2) : "-"}
          </p>
        ),
      },
      {
        title: "To MRP",
        dataIndex: "toMrp",
        key: "toMrp",
        render: (value) => (
          <p className="cursor-pointer">
            {value !== undefined && value !== null ? value.toFixed(2) : "-"}
          </p>
        ),
      },
      {
        title: "Markdown",
        dataIndex: "markDown",
        key: "markDown",
        render: (value) => (
          <p className="cursor-pointer">
            {value !== undefined && value !== null ? value.toFixed(2) : "-"}
          </p>
        ),
      },
    ];

    return (
      <div className="mt-4">
        {record.priceListTable && record.priceListTable.length > 0 ? (
          <Table
            columns={childColumns}
            dataSource={record.priceListTable}
            pagination={false}
            rowKey={(item) => `${record.id}-${item?.id}`}
            className="child-table"
          />
        ) : (
          <div className="py-4 text-center text-gray-500">No price rules found</div>
        )}
      </div>
    );
  };

  useHotkeys("alt+n", () => window.open("/price/add", "_blank"));

  return (
    <>
      <Helmet>
        <title>Price</title>
      </Helmet>
      <div className="flex justify-between items-center mb-2">
        <h1 className="text-2xl">Price List</h1>
        <div className="flex gap-5">
          <Tooltip title="Reload">
            <Button
              type="default"
              size="small"
              loading={loading}
              disabled={loading}
              onClick={() => getListData(1, 10)}
            >
              <TfiReload />
            </Button>
          </Tooltip>
          <Link to="/price/add" target="_blank">
            <Button type="primary" size="small" className="w-[80px]">
              Add New
            </Button>
          </Link>
        </div>
      </div>
      <Table
        dataSource={list}
        columns={columns}
        rowKey={(item) => item?.id}
        pagination={false}
        loading={loading}
        scroll={{ y: 400 }}
        bordered
        style={{ minHeight: "360px" }}
        expandable={{
          expandedRowRender,
          expandRowByClick: true,
          expandedRowClassName: () => "expanded-row-padding",
        }}
        className="parent-table"
      />
      {list?.length ? (
        <div className="flex justify-end mt-4">
          <Pagination
            pageSize={pageSize}
            total={totalElements}
            current={currentPage}
            onChange={handlePageChange}
          />
        </div>
      ) : null}
      <div className="mb-20"></div>
      <style jsx global>{`
        .expanded-row-padding .ant-table-expanded-row-fixed {
          padding: 12px 24px;
        }
        .child-table .ant-table {
          margin-top: 3px;
        }
      `}</style>
    </>
  );
};

export default reduxForm({
  form: masterName,
  enableReinitialize: true,
})(Price);