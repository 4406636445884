import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { Field, formValueSelector, reduxForm } from "redux-form";
import { getDropdownList, masterSelectors } from "slices/masterSlice";
import { ReduxFormSelectField } from "utils/ReduxFormSelectField";

import { Image } from "antd";
import { MdDelete } from "react-icons/md";
import { change } from "redux-form";
import { ReduxFormFileField } from "utils/ReduxFormFileField";
import { ReduxFormTextField } from "utils/ReduxFormTextField";
import { renderModalButtons } from "utils/renderModalButtons";
import { isRequired } from "utils/validations";

const formName = "collections";
const formSelector = formValueSelector(formName);
const CollectionsForm = ({ title, onCancel, handleSubmit, isViewOnly }) => {
  const dispatch = useDispatch();
  const isUpdatingMaster = useSelector(
    masterSelectors?.getIsUpdatingMasterList
  );
  const isFetchingDropdownList = useSelector(
    masterSelectors?.getIsFetchingDropdownList
  );
  const mainItemsOptions = useSelector(
    masterSelectors?.getMainItemsDropdownList
  );
  const subcategoryOptions = useSelector(
    masterSelectors?.getSubCategoriesDropdownList
  );

  useEffect(() => {
    dispatch(getDropdownList({ masterName: "mainitem" }));
    dispatch(getDropdownList({ masterName: "subcategory" }));
  }, [dispatch]);

  const collectionColours = useSelector((state) =>
    formSelector(state, "item_images")
  );

  const removeItemImage = () => {
    dispatch(change(formName, "item_images", null));
  };

  const imageUrl =
    collectionColours instanceof File
      ? URL.createObjectURL(collectionColours)
      : collectionColours;
  return (
    <>
      <Helmet>
        <title>Collection Form</title>
      </Helmet>
      <form onSubmit={handleSubmit}>
        <h6 className="text-2xl font-bold md:mb-5 mb-10">{title}</h6>
        <div className="md:flex block">
          <div className="md:mr-10 mb-5">
            <Field
              component={ReduxFormTextField}
              label="Collection Name"
              name="collection"
              placeholder="Enter Collection Name"
              disabled={isViewOnly}
            />
          </div>
          <div className="md:mr-10 mb-5">
            <Field
              component={ReduxFormSelectField}
              label="Main Item"
              name="mainItemList"
              options={mainItemsOptions}
              placeholder="Select Main Items"
              isLoading={isFetchingDropdownList}
              isMulti
            />
          </div>
          <div className="md:mr-10 mb-5">
            <Field
              component={ReduxFormSelectField}
              label="Sub-Category"
              name="subcategory"
              options={subcategoryOptions}
              placeholder="Select Subcategory "
              isLoading={isFetchingDropdownList}
              isMulti
            />
          </div>

          <div className="flex flex-wrap flex-col mb-5 w-80 max-w-[95%] rounded shadow-xl mr-10">
            {imageUrl ? (
              <>
                <Image
                  src={imageUrl}
                  alt="colour img"
                  className="!object-contain !h-56"
                  preview={{ toolbarRender: () => null }}
                />
              </>
            ) : (
              <Field
                name="item_images"
                component={ReduxFormFileField}
                className="mt-1"
                placeholder="Item Image"
                disabled={isViewOnly}
                itemname="image"
              />
            )}
            <div className="px-6 py-4 flex justify-between border-t border-[#d1d5db]">
              {imageUrl && (
                <button
                  className="bg-redBase text-white font-semibold rounded py-2 px-4"
                  type="button"
                  onClick={() => removeItemImage()}
                >
                  <MdDelete className="text-2xl" />
                </button>
              )}
            </div>
          </div>
        </div>
        {renderModalButtons(onCancel, isViewOnly, isUpdatingMaster)}
      </form>
    </>
  );
};

export default reduxForm({
  form: formName,
  validate: (values) => {
    const errors = {};
    isRequired(values?.collection) && (errors.collection = "Required");
    return errors;
  },
})(CollectionsForm);
