import React, {
  useState,
  useCallback,
  useMemo,
  useRef,
  useEffect,
} from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import SaleOrderFilter from "./SaleOrderFilter";

const SaleOrderSheet = () => {
  const [columnDefs, setColumnDefs] = useState([
    {
      headerName: "",
      field: "row",
      editable: false,
    },
    { headerName: "", field: "row2", width: 150, editable: false },
  ]);

  const [rowData, setRowData] = useState([]);
  const [showTable, setShowTable] = useState(false);
  const [totalItems, setTotalItems] = useState(0);
  const [orderType, setOrderType] = useState(null);
  const [storedRatios, setStoredRatios] = useState({});

  const defaultColDef = useMemo(() => {
    return {
      editable: true,
    };
  }, []);

  const ref = useRef();

  const handleAddSubCategory = useCallback((newRows) => {
    setRowData((prevRows) => [...prevRows, ...newRows]);
  }, []);

  const handleAddCustomer = useCallback((newCustomers) => {
    const newCustomerColumns = newCustomers.map((customer) => ({
      headerName: customer.label,
      customerId: customer.value,
      editable: false,
      children: [
        { headerName: "34", field: `${customer.label}34`, width: 70 },
        { headerName: "36", field: `${customer.label}36`, width: 70 },
        { headerName: "38", field: `${customer.label}38`, width: 70 },
        { headerName: "40", field: `${customer.label}40`, width: 70 },
        { headerName: "42", field: `${customer.label}42`, width: 70 },
        { headerName: "44", field: `${customer.label}44`, width: 70 },
        { headerName: "46", field: `${customer.label}46`, width: 70 },
        { headerName: "48", field: `${customer.label}48`, width: 70 },
        { headerName: "Total", field: `${customer.label}Total`, width: 100 },
      ],
    }));

    setColumnDefs((prevCols) => [...prevCols, ...newCustomerColumns]);

    setRowData((prevRows) =>
      prevRows.map((row) => {
        const newFields = {};
        newCustomers.forEach((customer) => {
          newFields[`${customer.label}34`] = 0;
          newFields[`${customer.label}36`] = 0;
          newFields[`${customer.label}38`] = 0;
          newFields[`${customer.label}40`] = 0;
          newFields[`${customer.label}42`] = 0;
          newFields[`${customer.label}44`] = 0;
          newFields[`${customer.label}46`] = 0;
          newFields[`${customer.label}48`] = 0;
          newFields[`${customer.label}Total`] = 0;
        });
        return { ...row, ...newFields };
      })
    );
  }, []);

  const handleOrderTypeChange = useCallback((newOrderType) => {
    setOrderType(newOrderType);
  }, []);

  function getMainItem(str) {
    return str.split("-")[1]?.trim();
  }

  function shouldEditMainItemRow(rowNode, currentMainItem) {
    return getMainItem(rowNode.data.row2) === currentMainItem;
  }

  const calculateCustomerTotals = useCallback(() => {
    if (!ref.current?.api) return;

    ref.current.api.forEachNode((rowNode) => {
      const updatedData = { ...rowNode.data };
      columnDefs.forEach((colDef) => {
        if (colDef.children) {
          const customerLabel = colDef.headerName;
          let total = 0;
          colDef.children.forEach((child) => {
            if (!child.field.includes("Total")) {
              total += Number(updatedData[child.field] || 0);
            }
          });
          updatedData[`${customerLabel}Total`] = total;
        }
      });
      rowNode.setData(updatedData);
    });
  }, [columnDefs]);

  function cellValueChanged(e) {
    const columnToEdit = e.colDef.field;
    const newValue = e.newValue;
    const currentRowIndex = e.rowIndex;

    const currentRow = ref.current.api.getRowNode(currentRowIndex).data;

    // If it's a direct cell edit (not a ratio row), just calculate totals
    if (currentRow.row !== "Common Ratio" && currentRow.row !== "Ratio") {
      calculateCustomerTotals();
      return;
    }

    const ratio = parseFloat(newValue) || 0;
    const distributedValue = Math.ceil(totalItems * ratio);
    const remainder = distributedValue % (totalItems || 1);
    const baseValue = Math.floor(distributedValue / (totalItems || 1));

    let remainderToDistribute = remainder;

    // Handle Common Ratio
    if (currentRow.row === "Common Ratio") {
      setStoredRatios((prev) => ({
        ...prev,
        [columnToEdit]: ratio,
      }));

      ref.current.api.forEachNode((rowNode) => {
        if (rowNode.data.row !== "Common Ratio") {
          let valueToSet = baseValue;
          if (remainderToDistribute > 0) {
            valueToSet += 1;
            remainderToDistribute--;
          }

          const updatedRowNode = {
            ...rowNode.data,
            [columnToEdit]: valueToSet,
          };
          rowNode.setData(updatedRowNode);
        }
      });
    }

    // Handle category-wise distribution
    else if (orderType === "categorywise" && currentRow.row === "Ratio") {
      const categoryToEdit = ref.current.api.getRowNode(currentRowIndex + 1)
        ?.data?.row;

      ref.current.api.forEachNode((rowNode) => {
        if (
          rowNode.data.row === categoryToEdit &&
          rowNode.data.row !== "Ratio"
        ) {
          let valueToSet = baseValue;
          if (remainderToDistribute > 0) {
            valueToSet += 1;
            remainderToDistribute--;
          }

          const updatedRowNode = {
            ...rowNode.data,
            [columnToEdit]: valueToSet,
          };
          rowNode.setData(updatedRowNode);
        }
      });
    }

    // Handle item-wise distribution
    else if (orderType === "itemwise" && currentRow.row === "Ratio") {
      const currentMainItem = getMainItem(
        ref.current.api.getRowNode(currentRowIndex + 1)?.data?.row2
      );

      ref.current.api.forEachNode((rowNode) => {
        if (
          shouldEditMainItemRow(rowNode, currentMainItem) &&
          rowNode.data.row !== "Ratio"
        ) {
          let valueToSet = baseValue;
          if (remainderToDistribute > 0) {
            valueToSet += 1;
            remainderToDistribute--;
          }

          const updatedRowNode = {
            ...rowNode.data,
            [columnToEdit]: valueToSet,
          };
          rowNode.setData(updatedRowNode);
        }
      });
    }

    // Calculate totals after any changes
    calculateCustomerTotals();
  }

  // Calculate totals whenever rowData changes
  useEffect(() => {
    calculateCustomerTotals();
  }, [rowData, calculateCustomerTotals]);

  return (
    <div className="ag-theme-alpine" style={{ height: 500, width: "100%" }}>
      <SaleOrderFilter
        setColumnDefs={setColumnDefs}
        columnDefs={columnDefs}
        onAddSubCategory={handleAddSubCategory}
        onAddCustomer={handleAddCustomer}
        setRowData={setRowData}
        rowData={rowData}
        setShowTable={setShowTable}
        totalItems={totalItems}
        setTotalItems={setTotalItems}
        onOrderTypeChange={handleOrderTypeChange}
        storedRatios={storedRatios}
      />

      {showTable && (
        <AgGridReact
          ref={ref}
          columnDefs={columnDefs}
          rowData={rowData}
          defaultColDef={defaultColDef}
          onCellValueChanged={cellValueChanged}
        />
      )}
    </div>
  );
};

export default SaleOrderSheet;