import React, { useState, useCallback } from "react";
import { Pagination, Table, Button, Modal, Tooltip } from "antd";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Field, formValueSelector, reduxForm } from "redux-form";
import {
  getMasterList,
  masterSelectors,
  ImportExcelFile,
  ExportExcelFile,
} from "slices/masterSlice";
import useMasterLogic from "customHooks/useMasterLogic";
import { ReduxFormFileField } from "utils/ReduxFormFileField";
import { TfiReload } from "react-icons/tfi";
import { renderActions } from "utils/renderActions";
import { useHotkeys } from "react-hotkeys-hook";
import HsnDetails from "./HsnDetails";

const masterName = "hsn";

const Hsn = () => {
  const formSelector = formValueSelector(masterName);
  const dispatch = useDispatch();
  const loading = useSelector(masterSelectors?.getIsFetchingMasterList);
  const importing = useSelector(masterSelectors?.getIsImportingExcel);
  const exporting = useSelector(masterSelectors?.getIsExportingExcel);
  const list = useSelector(masterSelectors?.getHsnMasterList);
  const { pageSize, totalElements, currentPage } = useSelector(
    masterSelectors?.getHsnPagination
  );

  const getListData = useCallback(
    (pageNum = 1, itemsPerPage = 10) => {
      dispatch(
        getMasterList({
          masterName,
          query: {
            page: pageNum,
            pageSize: itemsPerPage,
          },
        })
      );
    },
    [dispatch]
  );

  const { handlePageChange } = useMasterLogic(getListData, masterName);

  const [data, setData] = useState({});
  const [type, setType] = useState("update");

  const handleSetData = (value) => {
    setType("update");
    setData(value);
  };

  const handleDuplicate = (value) => {
    setType("new");
    setData(value);
  };

  const handleImport = () => {
    dispatch(
      ImportExcelFile({
        masterName: "hsn/export/excel",
        name: "hsn",
      })
    );
  };

  const [show, setShow] = useState(false);
  const excelFile = useSelector((state) => formSelector(state, "item_excel"));

  const handleExport = () => {
    setShow(false);
    const form = new FormData();
    form.append("file", excelFile);
    dispatch(
      ExportExcelFile({
        masterName: "hsn/upload/import",
        formData: form,
        configType: "multipart/form-data",
      })
    );
  };

  const columns = [
    {
      title: "HSN Code",
      dataIndex: "hsncode",
      key: "hsncode",
      render: (value, data) => (
        <p onDoubleClick={() => handleSetData(data)} className="cursor-pointer">
          {value || "-"}
        </p>
      ),
    },
    {
      title: "Actions",
      key: "Actions",
      dataIndex: "action",
      width: "20%",
      render: (_, data, index) => {
        const items = [
          {
            label: "Duplicate",
            onClick: () => handleDuplicate(data),
          },
        ];

        return renderActions(items);
      },
    },
  ];

  const expandedRowRender = (record) => {
    const childColumns = [
      {
        title: "From MRP",
        dataIndex: "fromMrp",
        key: "fromMrp",
        render: (value) => value !== undefined && value !== null ? value.toFixed(2) : "-",
      },
      {
        title: "To MRP",
        dataIndex: "toMrp",
        key: "toMrp",
        render: (value) => value !== undefined && value !== null ? value.toFixed(2) : "-",
      },
      {
        title: "Tax",
        dataIndex: "taxId",
        key: "tax",
        render: (value) => value?.slab || "-",
      },
      {
        title: "IGST",
        dataIndex: "igst",
        key: "igst",
        render: (value) => value !== undefined && value !== null ? value.toFixed(2) : "-",
      },
      {
        title: "SGST",
        dataIndex: "sgst",
        key: "sgst",
        render: (value) => value !== undefined && value !== null ? value.toFixed(2) : "-",
      },
      {
        title: "CGST",
        dataIndex: "cgst",
        key: "cgst",
        render: (value) => value !== undefined && value !== null ? value.toFixed(2) : "-",
      },
    ];

    return (
      <div className="mt-4">
        {record.hsnTable && record.hsnTable.length > 0 ? (
          <Table
            columns={childColumns}
            dataSource={record.hsnTable}
            pagination={false}
            rowKey={(item) => `${record.id}-${item.id}`}
            bordered
            className="child-table"
          />
        ) : (
          <div className="py-4 text-center text-gray-500">No HSN rules found</div>
        )}
      </div>
    );
  };

  useHotkeys("alt+n", () => window.open("/hsn/add", "_blank"));

  return (
    <>
      <Helmet>
        <title>HSN Codes</title>
      </Helmet>
      <div className="flex justify-between items-center mb-2">
        <h1 className="text-2xl">HSN Code List</h1>
        <div className="flex gap-5">
          <Tooltip title="Reload">
            <Button
              type="default"
              size="small"
              loading={loading}
              disabled={loading}
              onClick={() => getListData(1, 10)}
            >
              <TfiReload />
            </Button>
          </Tooltip>

          <Button
            type="default"
            size="small"
            loading={exporting}
            disabled={exporting}
            onClick={() => setShow(true)}
          >
            Import Excel
          </Button>
          <Button
            onClick={handleImport}
            loading={importing}
            disabled={exporting}
            type="default"
            size="small"
          >
            Export Excel
          </Button>
          <Link to="/hsn/add" target="_blank">
            <Button type="primary" size="small" className="w-[80px]">
              Add New
            </Button>
          </Link>
        </div>
      </div>
      <Table
        dataSource={list}
        columns={columns}
        rowKey={(item) => item?.id}
        pagination={false}
        loading={loading}
        scroll={{ y: 400 }}
        bordered
        style={{ minHeight: "360px" }}
        expandable={{
          expandedRowRender,
          expandRowByClick: true,
          expandedRowClassName: () => "expanded-row-padding",
        }}
      />
      {list?.length ? (
        <div className="flex justify-end mt-4">
          <Pagination
            pageSize={pageSize}
            total={totalElements}
            current={currentPage}
            onChange={handlePageChange}
          />
        </div>
      ) : null}
      <div className="mb-20"></div>
      <HsnDetails data={data} setData={setData} type={type} setType={setType} />
      <Modal
        title="Upload Excel File"
        open={show}
        onOk={handleExport}
        onCancel={() => setShow(false)}
      >
        <Field
          name="item_excel"
          component={ReduxFormFileField}
          className="mt-1"
          placeholder="Item Image"
          itemname="excel file"
        />
        <p className="mt-2 text-lg font-bold">{excelFile?.name}</p>
      </Modal>
      <style jsx global>{`
        .expanded-row-padding .ant-table-expanded-row-fixed {
          padding: 12px 24px;
        }
        .child-table .ant-table {
          margin-top: 3px;
        }
      `}</style>
    </>
  );
};

export default reduxForm({
  form: masterName,
  enableReinitialize: true,
})(Hsn);