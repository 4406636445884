import React, { useState, useEffect } from "react";
import { Button, Select, Tooltip } from "antd";
import { Form, Field } from "react-final-form";
import InputFieldV2 from "utils/v2/InputFieldV2";
import { useDispatch, useSelector } from "react-redux";
import {
  addMasterList,
  getMasterList,
  masterSelectors,
  getMasterDropDownList,
} from "slices/masterSlice";
import { Helmet } from "react-helmet";
import TextareaFieldV2 from "utils/v2/TextareFieldV2";

const masterName = "subcategory";
const hsnDropdownMaster = "hsn_dropdown";
const categoryDropdownMaster = "categorydropdown";

const AddSubCategory = () => {
  const dispatch = useDispatch();
  const loading = useSelector(masterSelectors?.getIsUpdatingMasterList);
  const loadingDropdown = useSelector(masterSelectors?.getIsFetchingDropdown);
  const hsnDropdown = useSelector(masterSelectors?.getHsnDropdown);
  const categoryDropdown = useSelector(masterSelectors?.getCategoryDropdown);
  const [item_images, setImage] = useState(null);

  useEffect(() => {
    dispatch(getMasterDropDownList({ masterName: categoryDropdownMaster }));
    dispatch(getMasterDropDownList({ masterName: hsnDropdownMaster }));
  }, []);

  const onSubmit = async (values, form) => {
    try {
      const payload = {
        name: values.name,
        code: values.code,
        categoryId: values.categoryId,
        hsnId: values.hsnId,
        comment: values.comment || null,
      };

      const formData = new FormData();

      formData.append(
        "subcategory",
        new Blob([JSON.stringify(payload)], { type: "application/json" })
      );

      if (item_images) {
        formData.append("item_image", item_images);
      }

      console.log([...formData]);
      const res = await dispatch(
        addMasterList({
          masterName,
          formData,
          configType: "multipart/form-data",
        })
      );

      if (res?.type === "master/add/fulfilled") {
        setImage(null);
        form.restart();
        dispatch(getMasterList({ masterName }));
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  const required = (value) => (value ? undefined : "Required");

  return (
    <>
      <Helmet>
        <title>Sub-Categories | Add</title>
      </Helmet>
      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit, form }) => (
          <form onSubmit={handleSubmit}>
            <>
              <h1 className="text-2xl mb-5">Add Sub-Category</h1>
              <div className="flex gap-5">
                <div className="">
                  <Field name="name" validate={required}>
                    {({ input, meta }) => (
                      <InputFieldV2
                        input={input}
                        meta={meta}
                        type="text"
                        label="Sub Category Name"
                      />
                    )}
                  </Field>
                </div>
                <div className="">
                  <Field name="code" validate={required}>
                    {({ input, meta }) => (
                      <InputFieldV2
                        input={input}
                        meta={meta}
                        type="text"
                        label="Code"
                      />
                    )}
                  </Field>
                </div>
                <div>
                  <label>Category</label>
                  <Field name="categoryId" validate={required}>
                    {({ input, meta }) => (
                      <Tooltip
                        title={meta.touched && meta.error ? meta.error : null}
                      >
                        <Select
                          style={{
                            width: "100%",
                            border: `${
                              meta.touched && meta.error ? "1px solid red" : ""
                            }`,
                            borderRadius: "6px",
                          }}
                          showSearch
                          loading={loadingDropdown}
                          placeholder="Category"
                          allowClear
                          onSearch={(e) =>
                            dispatch(
                              getMasterDropDownList({
                                masterName: categoryDropdownMaster,
                                query: { name: e },
                              })
                            )
                          }
                          options={categoryDropdown}
                          {...input}
                        />
                      </Tooltip>
                    )}
                  </Field>
                </div>

                <div className="">
                  <label>HSN Code</label>
                  <Field name="hsnId" validate={required}>
                    {({ input, meta }) => (
                      <Tooltip
                        title={meta.touched && meta.error ? meta.error : null}
                      >
                        <Select
                          style={{
                            width: "100%",
                            border: `${
                              meta.touched && meta.error ? "1px solid red" : ""
                            }`,
                            borderRadius: "6px",
                          }}
                          className={`${
                            meta.error &&
                            meta.touched &&
                            "border border-redBase"
                          }`}
                          showSearch
                          loading={loadingDropdown}
                          placeholder="HSN code"
                          allowClear
                          onSearch={(e) =>
                            dispatch(
                              getMasterDropDownList({
                                masterName: hsnDropdownMaster,
                                query: { name: e },
                              })
                            )
                          }
                          options={hsnDropdown}
                          {...input}
                        />
                      </Tooltip>
                    )}
                  </Field>
                </div>
                <div>
                  <label>Image</label>
                  <br />
                  <input
                    type="file"
                    className=""
                    accept="image/*"
                    onChange={(e) => setImage(e.target.files[0])}
                  />
                </div>
              </div>
              <div className="flex gap-5 my-3">
                <div className="w-full">
                  <Field name="comment">
                    {({ input, meta }) => (
                      <TextareaFieldV2
                        input={input}
                        meta={meta}
                        label="Comment"
                        style="w-1/2"
                      />
                    )}
                  </Field>
                </div>
              </div>

              <div className="flex gap-5 justify-end mt-10">
                <Button
                  type="primary"
                  size="small"
                  className="bg-secondaryBase w-[80px]"
                  htmlType="button"
                  onClick={() => {
                    setImage(null);
                    form.reset();
                  }}
                >
                  Clear
                </Button>
                <Button
                  type="primary"
                  size="small"
                  className="bg-redBase w-[80px]"
                  htmlType="submit"
                  loading={loading}
                  disabled={loading}
                >
                  Add
                </Button>
              </div>
            </>
          </form>
        )}
      />
    </>
  );
};

export default AddSubCategory;
