import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { masterSelectors } from "slices/masterSlice";
import OpenStock from "./OpenStock";
import Inward from "./Inward";
import Outward from "./Outward";
import Filter from "./Filter";
import Loading from "utils/Loading";

const masterName = "ledger";

function LedgerReportv2() {
  const dispatch = useDispatch();
  const ledgerMain = useSelector(masterSelectors?.getLedgerList);
  const [isLoading, setIsLoading] = useState(false);
  const [ledger, setLedger] = useState([]);

  const inward = ledger?.Inward?.flatMap((entry) =>
    entry.stock.map((stockItem) => ({
      ...entry,
      ...stockItem,
    }))
  );

  const outward = ledger?.Outward?.flatMap((entry) =>
    entry.stock.map((stockItem) => ({
      ...entry,
      ...stockItem,
    }))
  );

  const openStock = ledger?.openingStock;
  const inwardTotal = ledger?.inwardStockTotals;
  const outwardTotal = ledger?.outwardStockTotals;
  const openStockTotal = ledger?.openingStockTotals;

  return (
    <div>
      <h1 className="text-2xl font-bold py-4">Ledger Report</h1>
      <Filter
        setLedger={setLedger}
        ledgerMain={ledgerMain}
        masterName={masterName}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
      />
      {isLoading ? (
        <Loading />
      ) : !ledger || ledger.length === 0 ? (
        <p className="text-center text-gray-500 mt-4">No Data</p>
      ) : (
        <>
          <div className="mb-20 w-[1310px]">
            <OpenStock openStock={openStock} openStockTotal={openStockTotal} />
          </div>
          <div className="flex">
            <div className="grow">
              <Inward inward={inward} inwardTotal={inwardTotal} />
            </div>
            <div className="grow">
              <Outward outward={outward} outwardTotal={outwardTotal} />
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default LedgerReportv2;
