import { Button, Table } from "antd";
import { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getMasterList, masterSelectors } from "slices/masterSlice";
import { formatDateTime } from "utils/dateTime";

const masterName = "color";

function Color() {
  const dispatch = useDispatch();

  const list = useSelector(masterSelectors?.getColorMasterList);
  const loading = useSelector(masterSelectors?.getIsFetchingMasterList);

  const isFetchingMasterList = useSelector(
    masterSelectors?.getIsFetchingMasterList
  );

  useEffect(() => {
    getListData();
  }, [, dispatch]);

  const getListData = useCallback(
    (pageNum = 1, itemsPerPage = 10) => {
      dispatch(
        getMasterList({
          masterName,
          query: {
            page: pageNum,
            pageSize: itemsPerPage,
          },
        })
      );
    },
    [dispatch]
  );

  const columns = [
    {
      title: "Color Name",
      dataIndex: "colorName",
      key: "colorName",
      responsive: ["sm"],
      render: (value) => value || "-",
    },

    {
      title: "Color Family",
      dataIndex: "colorFamily",
      key: "colorFamily",
      responsive: ["sm"],
      render: (value) => value?.colorFamily || "-",
    },
    {
      title: "Ratio",
      dataIndex: "ratio",
      key: "ratio",
      responsive: ["sm"],
      render: (value) => value || "-",
    },
  ];

  return (
    <>
      <Helmet>
        <title>Color</title>
      </Helmet>
      {/* <TableOptions masterName={masterName} showAdd={false} /> */}
      {/* <StatusSegments masterName={masterName} options={masterStatuses} /> */}
      <div className="flex justify-between items-center mb-2">
        <h1 className="text-2xl">Color List</h1>
        <div className="flex gap-5">
          {/* <StatusSegments masterName={masterName} options={masterStatuses} /> */}
          {/* <Tooltip title="Reload">
            <Button
              type="default"
              size="small"
              loading={loading}
              disabled={loading}
              onClick={() => getListData(1, 10)}
            >
              <TfiReload />
            </Button>
          </Tooltip>

          <Button
            type="default"
            size="small"
            loading={exporting}
            disabled={exporting}
            onClick={() => setShow(true)}
          >
            Import Excel
          </Button>
          <Button
            onClick={handleImport}
            loading={importing}
            disabled={exporting}
            type="default"
            size="small"
          >
            Export Excel
          </Button> */}
          <Link to="/color/add" target="_blank">
            <Button type="primary" size="small" className="w-[80px]">
              Add New
            </Button>
          </Link>
        </div>
      </div>
      <Table
        dataSource={list}
        columns={columns}
        rowKey={(item) => item?.id}
        pagination={false}
        loading={loading}
        scroll={{ y: 400 }}
        bordered
        style={{ minHeight: "360px" }}
      />
    </>
  );
}

export default Color;
