import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getMasterDropDownList,
  getMasterList,
  masterSelectors,
} from "slices/masterSlice";
import useMasterLogic from "customHooks/useMasterLogic";
import { Button } from "antd";

const SizeRatioGrid = ({ sizes = [], onClose, mainItemMRP, onUpdate }) => {
  const [selectedSizeRatio, setSelectedSizeRatio] = useState("");
  const [mrp, setMrp] = useState({});
  const [showRatioRows, setShowRatioRows] = useState(false);
  const [priceListRows, setPriceListRows] = useState([]);
  const [selectedSizeRatioId, setSelectedSizeRatioId] = useState(null);
  const [ratios, setRatios] = useState({});

  const dispatch = useDispatch();
  const sizeratioOptions = useSelector(masterSelectors?.getSizeRatioDropdown);
  const list = useSelector(masterSelectors?.getSizeRatioMasterList);
  const pricelistDropdown = useSelector(masterSelectors?.getPriceListDropdown);

  const getListData = useCallback(
    (pageNum = 1, itemsPerPage = 10) => {
      dispatch(
        getMasterList({
          masterName: "sizeratio",
          query: {
            page: pageNum,
            pageSize: itemsPerPage,
          },
        })
      );
    },
    [dispatch]
  );

  const { handlePageChange } = useMasterLogic(getListData, "sizeratio");

  useEffect(() => {
    dispatch(getMasterDropDownList({ masterName: "sizeratio_dropdown" }));
    dispatch(getMasterDropDownList({ masterName: "pricelist_dropdown" }));
    getListData();
  }, [dispatch, getListData]);

  useEffect(() => {
    if (selectedSizeRatio && mainItemMRP) {
      recalculateValues(selectedSizeRatio, mainItemMRP);
    }
  }, [mainItemMRP]);

  useEffect(() => {
    if (showRatioRows) {
      updateRateMaster();
    }
  }, [mrp, priceListRows, selectedSizeRatioId]);

  const updateRateMaster = () => {
    const rateMaster = priceListRows.flatMap((row) =>
      sizes.map((size) => ({
        sizeId: size.id.toString(),
        mrp: mrp[size.id]?.toString() || "0",
        wsp: row.wsp[size.id]?.toString() || "0",
        ratio: ratios[size.id]?.toString() || "0",
        priceListId: row.id.toString(),
        sizeRatioId: selectedSizeRatioId,
        markDown: row.markdown?.toString() || "0",
      }))
    );

    onUpdate(rateMaster);
  };

  const getValidPriceLists = (priceListData, mainItemMRP) => {
    return priceListData.filter((priceList) => {
      // Only include price lists that have priceListTable with items
      if (!priceList.priceListTable || priceList.priceListTable.length === 0) {
        return false;
      }

      // Check if any range in priceListTable matches the MRP
      return priceList.priceListTable.some(
        (range) =>
          (range.toMrp === null && mainItemMRP >= range.fromMrp) ||
          (mainItemMRP >= range.fromMrp && mainItemMRP <= range.toMrp)
      );
    });
  };

  const getMarkdownForMrp = (priceList, mrpValue) => {
    // Find the matching range for the MRP
    const matchingRange = priceList.priceListTable.find(
      (range) =>
        (range.toMrp === null && mrpValue >= range.fromMrp) ||
        (mrpValue >= range.fromMrp && mrpValue <= range.toMrp)
    );

    return matchingRange ? matchingRange.markDown : 0;
  };

  const recalculateValues = (sizeRatioName, currentMRP) => {
    const selectedRatio = list.find((item) => item.name === sizeRatioName);

    if (!selectedRatio) return;

    const newRatios = {};
    const newMrp = {};

    sizes.forEach((size) => {
      newRatios[size.id] = 0;
      newMrp[size.id] = currentMRP;
    });

    selectedRatio?.sizeRatioSubForm?.forEach((subItem) => {
      const size = sizes.find((s) => s.size === subItem.sizeId.size);
      if (size) {
        newRatios[size.id] = subItem.ratio;
        newMrp[size.id] = currentMRP + subItem.ratio;
      }
    });

    setRatios(newRatios);
    setMrp(newMrp);

    const validPriceLists = getValidPriceLists(pricelistDropdown, currentMRP);

    const newPriceListRows = validPriceLists.map((priceListItem) => {
      const markdown = getMarkdownForMrp(priceListItem, currentMRP);

      const wspValues = Object.keys(newMrp).reduce((acc, sizeId) => {
        const mrpValue = newMrp[sizeId];
        const wsp = markdown
          ? mrpValue - (mrpValue * markdown) / 100
          : mrpValue;
        acc[sizeId] = Math.round(wsp);
        return acc;
      }, {});

      return {
        id: priceListItem.value,
        name: priceListItem.label,
        markdown,
        wsp: wspValues,
      };
    });

    setPriceListRows(newPriceListRows);
  };

  const handleSizeRatioChange = (event) => {
    const value = event.target.value;

    const selectedRatio = list.find((item) => item.name === value);
    setSelectedSizeRatio(value);
    setSelectedSizeRatioId(selectedRatio?.id || null);
    setShowRatioRows(!!value);

    if (value && mainItemMRP) {
      recalculateValues(value, mainItemMRP);
    }
  };

  return (
    <div className="p-4 border rounded-lg shadow-sm">
      <div className="flex justify-between mb-4">
        <h3 className="text-lg font-semibold">Size and Rate Details</h3>
        <Button variant="outline" size="sm" onClick={onClose}>
          Cancel
        </Button>
      </div>

      <div className="mb-4 overflow-auto">
        <table className="border-collapse border border-grey w-full text-center overflow-scroll">
          <thead>
            <tr className="bg-secondaryBase border border-grey">
              <th className="p-2 w-[100px]">Type</th>
              <th className="p-2 w-[100px]">Category</th>
              <th className="border border-grey p-2 w-[80px]">Markdown</th>
              {sizes.map((size) => (
                <th key={size.id} className="border border-grey p-2 w-[80px]">
                  {size.size}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            <tr className="bg-secondaryBase border border-grey">
              <td colSpan="2">
                <select
                  className="p-2 border rounded w-full"
                  value={selectedSizeRatio}
                  onChange={handleSizeRatioChange}
                  disabled={!mainItemMRP}
                >
                  <option value="">Select Size Ratio</option>
                  {sizeratioOptions.map((option) => (
                    <option key={option.value} value={option.label}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </td>
              <td className="border border-grey p-2">-</td>
              {sizes.map((size) => (
                <td key={size.id} className="border border-grey p-2">
                  -
                </td>
              ))}
            </tr>

            {showRatioRows && (
              <>
                <tr className="border border-grey">
                  <td className="border border-grey p-2" colSpan="2">
                    MRP
                  </td>
                  <td className="border border-grey p-2">-</td>
                  {sizes.map((size) => (
                    <td
                      key={size.id}
                      className="border border-grey p-2 w-[80px]"
                    >
                      <input
                        type="number"
                        className="w-[50px] p-1 border rounded"
                        value={mrp[size.id] || ""}
                        disabled
                      />
                    </td>
                  ))}
                </tr>

                {priceListRows.map((row) => (
                  <tr key={row.id} className="border border-grey">
                    <td className="border border-grey p-2">{row.name}</td>
                    <td className="border border-grey p-2">WSP</td>
                    <td className="border border-grey p-2">
                      {row.markdown || "-"}
                    </td>
                    {sizes.map((size) => (
                      <td
                        key={size.id}
                        className="border border-grey p-2 w-[120px]"
                      >
                        <input
                          type="number"
                          className="w-[50px] p-1 border rounded"
                          value={row.wsp[size.id] || ""}
                          disabled
                        />
                      </td>
                    ))}
                  </tr>
                ))}
              </>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default SizeRatioGrid;
