import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { Field, reduxForm } from "redux-form";
import {
  addMasterList,
  getDropdownList,
  getMasterDropDownList,
  getMasterList,
  masterSelectors,
} from "slices/masterSlice";
import { ReduxFormSelectField } from "utils/ReduxFormSelectField";
import { ReduxFormTextField } from "utils/ReduxFormTextField";
import { ReduxFormFileField } from "utils/ReduxFormFileField";
import { renderModalButtons } from "utils/renderModalButtons";
import { isRequired } from "utils/validations";
import { Image } from "antd";
import { MdDelete } from "react-icons/md";
import { change } from "redux-form";
import ColourSelector from "../mainItem/ColourSelector";
import ColorImageUpload from "./ColorImageUpload";

const formName = "color";

const AddColor = ({
  title,
  handleSubmit,
  onCancel,
  isViewOnly,
  pristine,
  submitting,
}) => {
  const dispatch = useDispatch();
  const inputRef = useRef(null);

  const [colorImage, setColorImage] = useState(null);
  const [colors, setColors] = useState([]);

  const isUpdatingMaster = useSelector(
    masterSelectors?.getIsUpdatingMasterList
  );
  const isFetchingDropdownList = useSelector(
    masterSelectors?.getIsFetchingDropdownList
  );
  const mainItemsOptions = useSelector(
    masterSelectors?.getMainItemsNewDropdownList
  );

  const colorfamilyOptions = useSelector(
    masterSelectors?.getColorFamilyDropdown
  );

  useEffect(() => {
    dispatch(getMasterDropDownList({ masterName: "colorfamily_dropdown" }));
  }, [dispatch]);

  function openFileExplorer() {
    inputRef.current?.click();
  }

  const onSubmitHandler = async (values) => {
    const payload = {
      colorName: values.colorName || "",
      colorFamilyId: values.colorFamily?.value || "",
      ratio: Number(values.ratio) || 100,
    };

    console.log({ payload });

    try {
      const res = await dispatch(
        addMasterList({
          masterName: "color",
          formData: payload,
        })
      );

      if (res?.type === "master/add/fulfilled") {
        dispatch(getMasterList({ masterName: "color" }));
      }
    } catch (error) {
      console.error("Error adding color:", error);
    }
  };

  // const handleColorModalOk = () => {
  //   // setColors([...colors, newColor]);
  //   setColorImage(null);
  //   colorForm.resetFields();
  //   setIsColorModalVisible(false);
  // };
  return (
    <>
      <Helmet>
        <title>Add Color</title>
      </Helmet>
      <form onSubmit={handleSubmit(onSubmitHandler)}>
        <h6 className="text-2xl font-bold md:mb-5 mb-10">{title}</h6>
        <div className="md:flex block">
          <div className="md:mr-10 mb-5">
            <Field
              component={ReduxFormTextField}
              label="Color Name"
              name="colorName"
              placeholder="Enter Color Name"
              disabled={isViewOnly}
            />
          </div>
          <div className="md:mr-10 mb-5">
            <Field
              component={ReduxFormSelectField}
              label="Color Family"
              name="colorFamily"
              options={colorfamilyOptions}
              placeholder="Select Color Family"
              isLoading={isFetchingDropdownList}
              isMulti={false}
            />
          </div>
          <div className="md:mr-10 mb-5">
            <Field
              component={ReduxFormTextField}
              label="Ratio"
              name="ratio"
              placeholder="Enter Ratio"
              type="number"
              disabled={isViewOnly}
            />
          </div>
          {/* <div className="md:mr-10 mb-5">
            <Field
              component={ReduxFormSelectField}
              label="Main Item"
              name="mainItem"
              options={mainItemsOptions}
              placeholder="Select Main Items"
              isLoading={isFetchingDropdownList}
              isMulti={false}
            />
          </div>

          <div className="md:mr-10 mb-5">
            <Field
              name="item_images"
              component={ColorImageUpload}
              disabled={isViewOnly}
            />
          </div> */}
        </div>
        {renderModalButtons(onCancel, isViewOnly, isUpdatingMaster)}
      </form>
    </>
  );
};

export default reduxForm({
  form: formName,
  initialValues: { ratio: 100 },
  validate: (values) => {
    const errors = {};
    isRequired(values?.colorName) && (errors.colorName = "Required");
    return errors;
  },
})(AddColor);
