import React from "react";
import { Tooltip } from "antd";

const TextareaFieldV2 = ({
  input,
  meta,
  label,
  placeholder,
  style = "",
  disabled = false,
}) => {
  return (
    <div>
      <label>{label}</label>
      <br></br>
      <Tooltip title={meta.touched && meta.error ? meta.error : null}>
        <textarea
          {...input}
          disabled={disabled}
          placeholder={placeholder ? placeholder : label}
          className={`c-text-area ${
            meta.error && meta.touched && "border border-redBase"
          } ${style}`}
        />
      </Tooltip>
    </div>
  );
};

export default TextareaFieldV2;
