import React, { useEffect } from "react";
import { Button, Select, Tooltip } from "antd";
import { Form, Field } from "react-final-form";
import InputFieldV2 from "utils/v2/InputFieldV2";
import { useDispatch, useSelector } from "react-redux";
import {
  addMasterList,
  getMasterDropDownList,
  getMasterList,
  masterSelectors,
} from "slices/masterSlice";
import { Helmet } from "react-helmet";
import { useHotkeys } from "react-hotkeys-hook";

const categoryDropdownMaster = "categorydropdown";

const masterName = "size";
const AddSize = () => {
  const dispatch = useDispatch();
  const loading = useSelector(masterSelectors?.getIsUpdatingMasterList);

  const categoryDropdown = useSelector(masterSelectors?.getCategoryDropdown);
  const loadingDropdown = useSelector(masterSelectors?.getIsFetchingDropdown);

  useEffect(() => {
    dispatch(getMasterDropDownList({ masterName: categoryDropdownMaster }));
  }, [dispatch]);

  const onSubmit = async (values, form) => {
    let res = await dispatch(
      addMasterList({
        masterName,
        formData: values,
      })
    );
    if (res?.type === "master/add/fulfilled") {
      form.restart();
      dispatch(
        getMasterList({
          masterName,
        })
      );
    }
  };

  const required = (value) => (value ? undefined : "Required");
  useHotkeys("alt+l", () => window.location.reload());
  useHotkeys("alt+s", (event) => {
    event.preventDefault();
    document
      .getElementById("form")
      .dispatchEvent(new Event("submit", { cancelable: true, bubbles: true }));
  });

  return (
    <>
      <Helmet>
        <title>SIZE | Add</title>
      </Helmet>
      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit, form }) => (
          <form onSubmit={handleSubmit} id="form">
            <>
              <h1 className="text-2xl mb-5">Add Size</h1>
              <div className="flex gap-5">
                <div>
                  <label>Category</label>
                  <Field name="categoryId" validate={required}>
                    {({ input, meta }) => (
                      <Tooltip
                        title={meta.touched && meta.error ? meta.error : null}
                      >
                        <Select
                          style={{
                            width: "100%",
                            border: `${
                              meta.touched && meta.error ? "1px solid red" : ""
                            }`,
                            borderRadius: "6px",
                          }}
                          showSearch
                          loading={loadingDropdown}
                          placeholder="Category"
                          allowClear
                          onSearch={(e) =>
                            dispatch(
                              getMasterDropDownList({
                                masterName: categoryDropdownMaster,
                                query: { name: e },
                              })
                            )
                          }
                          options={categoryDropdown}
                          {...input}
                          onChange={(value) => {
                            input.onChange(value);
                          }}
                        />
                      </Tooltip>
                    )}
                  </Field>
                </div>
                <div>
                  <Field name="size" validate={required}>
                    {({ input, meta }) => (
                      <InputFieldV2
                        input={input}
                        meta={meta}
                        type="number"
                        label="Size"
                      />
                    )}
                  </Field>
                </div>
              </div>
              <div className="flex gap-5 justify-end mt-10">
                <Button
                  type="primary"
                  size="small"
                  className="bg-secondaryBase w-[80px]"
                  htmlType="button"
                  onClick={form.reset}
                >
                  Clear
                </Button>
                <Button
                  type="primary"
                  size="small"
                  className="bg-redBase w-[80px]"
                  htmlType="submit"
                  loading={loading}
                  disabled={loading}
                >
                  Add
                </Button>
              </div>
            </>
          </form>
        )}
      />
    </>
  );
};

export default AddSize;
