import React, { useState, useEffect } from "react";
import { Form, Field } from "react-final-form";
// import { Select, Tooltip } from "@/components/ui/button";
import { useDispatch, useSelector } from "react-redux";
import {
  addMasterList,
  getMasterDropDownList,
  getMasterList,
  masterSelectors,
} from "slices/masterSlice";
import { Helmet } from "react-helmet";
import SizeRatioTable from "./SizeRatioTable";
import { Button, Select, Tooltip } from "antd";

const masterName = "sizeratio";
const categoryDropdownMaster = "categorydropdown";

const AddSizeRatio = () => {
  const dispatch = useDispatch();
  const [showSizeGrid, setShowSizeGrid] = useState(false);
  const [categorySpecificSizes, setCategorySpecificSizes] = useState([]);
  const [sizeRatios, setSizeRatios] = useState([]);

  const loading = useSelector(masterSelectors?.getIsUpdatingMasterList);
  const loadingDropdown = useSelector(
    masterSelectors?.getIsFetchingDropdownList
  );
  const categoryDropdown = useSelector(masterSelectors?.getCategoryDropdown);

  useEffect(() => {
    dispatch(getMasterDropDownList({ masterName: categoryDropdownMaster }));
  }, [dispatch]);

  useEffect(() => {
    if (categorySpecificSizes.length > 0) {
      setShowSizeGrid(true);
    }
  }, [categorySpecificSizes]);

  useEffect(() => {
    console.log("sizeRatios updated:", sizeRatios);
    console.log("Button should be disabled:", loading || !sizeRatios.length);
  }, [sizeRatios, loading]);

  const fetchSizesForCategory = async (categoryName) => {
    if (!categoryName) return;

    try {
      const response = await dispatch(
        getMasterList({
          masterName: "size",
          query: { is_active: 1 },
        })
      );

      if (response?.payload?.data?.data) {
        const filteredSizes = response?.payload?.data?.data
          .filter(
            (size) =>
              size.categoryId?.name?.toUpperCase() ===
              categoryName?.toUpperCase()
          )
          .map((item) => ({
            id: item.id,
            size: item.size,
          }))
          .sort((a, b) => a.id - b.id);

        console.log("Fetching sizes for:", categoryName);
        console.log("Filtered Sizes:", filteredSizes);
        setCategorySpecificSizes(filteredSizes);
      }
    } catch (error) {
      console.error("Error fetching sizes:", error);
    }
  };

  // console.log({ sizeRatios });
  // console.log("Button Disabled:", loading || !sizeRatios.length);
  // console.log("Loading State:", loading);
  // console.log("Size Ratios Length:", sizeRatios.length);

  const required = (value) => (value ? undefined : "Required");

  const onSubmit = async (values) => {
    console.log("onSubmit triggered with:", values);
    if (!sizeRatios.length) {
      console.warn("onSubmit blocked: sizeRatios is empty");
      return;
    }

    const sizeRatioSubForm = sizeRatios
      .filter((item) => item.ratio !== "0")
      .map((item) => ({
        ratio: item.ratio,
        categoryId: values.category_name,
        sizeId: parseInt(item.id),
      }));

    const payload = {
      name: values.name,
      sizeRatioSubForm: sizeRatioSubForm,
    };

    console.log("Transformed payload:", payload);

    const res = await dispatch(
      addMasterList({
        masterName,
        formData: payload,
      })
    );

    if (res?.type === "master/add/fulfilled") {
      setShowSizeGrid(false);
      setCategorySpecificSizes([]);
      setSizeRatios([]);
      dispatch(getMasterList({ masterName }));
    }
  };
  const handleGridUpdate = (data) => {
    console.log("handleGridUpdate called with:", data);
    setSizeRatios(data);
  };

  const handleGridClose = () => {
    setShowSizeGrid(false);
    setCategorySpecificSizes([]);
    setSizeRatios([]);
  };

  return (
    <>
      <Helmet>
        <title>Size & Ratio | Add</title>
      </Helmet>

      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit, form }) => (
          <form onSubmit={handleSubmit} id="form" className="space-y-6">
            <h1 className="text-2xl font-semibold">Add Size & Ratio</h1>

            <div className="flex ">
              <div>
                <label className="block text-sm font-medium mb-1 ">Name</label>
                <Field name="name" validate={required}>
                  {({ input, meta }) => (
                    <div>
                      <input
                        {...input}
                        type="text"
                        className="p-2 border rounded-md focus:ring-2 focus:ring-blue-500 w-[200px] mr-10"
                        placeholder="Enter name"
                      />
                      {meta.touched && meta.error && (
                        <span className="text-red-500 text-sm">
                          {meta.error}
                        </span>
                      )}
                    </div>
                  )}
                </Field>
              </div>

              <div>
                <label className="block text-sm font-medium mb-1">
                  Category
                </label>
                <Field name="category_name" validate={required}>
                  {({ input, meta }) => (
                    <Tooltip
                      title={meta.touched && meta.error ? meta.error : null}
                    >
                      <Select
                        className="w-[200px]"
                        showSearch
                        loading={loadingDropdown}
                        placeholder="Select category"
                        allowClear
                        disabled={showSizeGrid}
                        onSearch={(e) =>
                          dispatch(
                            getMasterDropDownList({
                              masterName: categoryDropdownMaster,
                              query: { name: e },
                            })
                          )
                        }
                        options={categoryDropdown}
                        {...input}
                        onChange={(value) => {
                          input.onChange(value);
                          const selectedCategory = categoryDropdown.find(
                            (cat) => cat.value === value
                          )?.label;
                          fetchSizesForCategory(selectedCategory);
                        }}
                      />
                    </Tooltip>
                  )}
                </Field>
              </div>
            </div>

            {showSizeGrid && categorySpecificSizes.length > 0 && (
              <div className="mt-6">
                <SizeRatioTable
                  sizes={categorySpecificSizes}
                  onClose={handleGridClose}
                  onUpdate={handleGridUpdate}
                />
              </div>
            )}

            <div className="flex justify-end gap-4">
              <Button
                variant="outline"
                onClick={() => {
                  form.reset();
                  handleGridClose();
                }}
              >
                Clear
              </Button>
              <button
                type="submit"
                disabled={loading || !sizeRatios.length}
                className="bg-redBase w-[80px] text-white p-2 rounded"
              >
                {loading ? "Adding..." : "Add"}
              </button>
            </div>
          </form>
        )}
      />
    </>
  );
};

export default AddSizeRatio;
