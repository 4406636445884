import React, { useState, useCallback } from "react";
import { Pagination, Table, Button, Tooltip } from "antd";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { reduxForm } from "redux-form";
import { getMasterList, masterSelectors } from "slices/masterSlice";
import useMasterLogic from "customHooks/useMasterLogic";
import { TfiReload } from "react-icons/tfi";
import { EyeIcon } from "lucide-react";
import { formatDateTime } from "utils/dateTime";

const masterName = "sizeratio";

const SizeRatio = () => {
  const dispatch = useDispatch();
  const [expandedRows, setExpandedRows] = useState([]);

  const loading = useSelector(masterSelectors?.getIsFetchingMasterList);
  const list = useSelector(masterSelectors?.getSizeRatioMasterList);
  const { pageSize, totalElements, currentPage } = useSelector(
    masterSelectors?.getSizeRatioPagination
  );

  console.log({ list });

  const getListData = useCallback(
    (pageNum = 1, itemsPerPage = 10) => {
      dispatch(
        getMasterList({
          masterName,
          query: {
            page: pageNum,
            pageSize: itemsPerPage,
          },
        })
      );
    },
    [dispatch]
  );

  const { handlePageChange } = useMasterLogic(getListData, masterName);

  const expandedRowRender = (record) => (
    <div className="p-4">
      <table className="w-full min-w-[400px] border-collapse">
        <thead>
          <tr className="bg-secondaryBase">
            <th className="border px-4 py-2 text-sm font-medium text-black">
              Size
            </th>
            <th className="border px-4 py-2 text-sm font-medium text-black">
              Ratio
            </th>
            <th className="border px-4 py-2 text-sm font-medium text-black">
              Category
            </th>
          </tr>
        </thead>
        <tbody>
          {record.sizeRatioSubForm.map((item, index) => (
            <tr key={index}>
              <td className="border border-grey px-4 py-2 text-center">
                {item.sizeId.size}
              </td>
              <td className="border border-grey  px-4 py-2 text-center">
                {item.ratio.toFixed(2)}
              </td>
              <td className="border border-grey  px-4 py-2 text-center">
                {item.categoryId.name}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );

  const columns = [
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      responsive: ["sm"],

      render: (value) => (
        <p className="cursor-pointer">{formatDateTime(value) || "-"}</p>
      ),
      width: "200px",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (value, record) => (
        <div className="flex items-center gap-4">
          <span>{value || "-"}</span>
        </div>
      ),
    },
  ];

  return (
    <>
      <Helmet>
        <title>Size & Ratio</title>
      </Helmet>

      <div className="flex justify-between items-center mb-4">
        <h1 className="text-2xl">Size Ratio List</h1>
        <div className="flex gap-4">
          <Tooltip title="Reload">
            <Button
              type="default"
              size="small"
              loading={loading}
              disabled={loading}
              onClick={() => getListData(1, 10)}
            >
              <TfiReload />
            </Button>
          </Tooltip>
          <Link to="/sizeratio/add" target="_blank">
            <Button type="primary" size="small" className="w-20">
              Add New
            </Button>
          </Link>
        </div>
      </div>

      <Table
        dataSource={list}
        columns={columns}
        rowKey="id"
        expandable={{
          expandedRowRender,
          expandedRowKeys: expandedRows,
          onExpand: (expanded, record) => {
            const newExpandedRows = expanded
              ? [...expandedRows, record.id]
              : expandedRows.filter((id) => id !== record.id);
            setExpandedRows(newExpandedRows);
          },
        }}
        pagination={false}
        loading={loading}
        scroll={{ x: "max-content", y: 400 }}
        bordered
        style={{ minHeight: "360px" }}
      />

      {list?.length ? (
        <div className="flex justify-end mt-4">
          <Pagination
            pageSize={pageSize}
            total={totalElements}
            current={currentPage}
            onChange={handlePageChange}
          />
        </div>
      ) : null}
    </>
  );
};

export default reduxForm({
  form: masterName,
  enableReinitialize: true,
})(SizeRatio);
