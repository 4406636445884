import React from "react";
import { Tooltip } from "antd";

const InputFieldV2 = ({
  input,
  meta,
  label,
  type,
  placeholder,
  style = "",
  disabled = false,
  customWidth,
}) => {
  return (
    <div>
      <label>{label}</label>
      <br></br>
      <Tooltip title={meta.touched && meta.error ? meta.error : null}>
        <input
          {...input}
          disabled={disabled}
          type={type}
          placeholder={placeholder ? placeholder : label}
          className={`c-input w-${customWidth} ${
            meta.error && meta.touched && "border border-redBase"
          } ${style}`}
        />
      </Tooltip>
    </div>
  );
};

export default InputFieldV2;
