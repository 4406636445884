import { Button, Modal, Table } from "antd";
import Subcategories from "components/subcategory/Subcategories";

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  change,
  Field,
  formValueSelector,
  getFormValues,
  reduxForm,
} from "redux-form";
import {
  addSaleOrder,
  getDropdownList,
  getMasterDropDownList,
  getSaleOrderStock,
  masterSelectors,
} from "slices/masterSlice";
import { ReduxFormSelectField } from "utils/ReduxFormSelectField";

const customerDropdownMaster = "customer_dropdown";
const subcategoryDropdownMaster = "subcategory_dropdown";
const formName = "saleorder-filter";
const formSelector = formValueSelector(formName);

function SaleOrderFilter({
  setColumnDefs,
  columnDefs,
  onAddCustomer,
  setRowData,
  rowData,
  setShowTable,
  setTotalItems,
  onOrderTypeChange,
  totalItems,
  storedRatios,
}) {
  const dispatch = useDispatch();

  const [rowBucket, setRowBucket] = useState([]);
  const [isOrderTypeEnabled, setIsOrderTypeEnabled] = useState(false);
  const [colors, setColors] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [emptyTableData, setEmptyTableData] = useState([]);
  const [tableData, setTableData] = useState({ columns: [], rows: [] });

  const isFetchingDropdownList = useSelector(
    masterSelectors?.getIsFetchingDropdownList
  );

  const subCategoryOptions = useSelector(
    masterSelectors?.getSubCategoriesDropdownList
  );

  const generatedRawMaterialColorsOptions = useSelector(
    masterSelectors?.getGeneratedRawMaterialColorsDropdownList
  );

  const category = useSelector((state) => formSelector(state, "category"));
  const orderType = useSelector((state) => formSelector(state, "orderType"));
  const subCategory = useSelector((state) =>
    formSelector(state, "subCategory")
  );

  const generatedColors = useSelector((state) =>
    formSelector(state, "generatedColors")
  );

  const formValues = useSelector(getFormValues("saleorder-filter"));

  const customerDropDown = useSelector(masterSelectors?.getCustomerDropdown);

  useEffect(() => {
    dispatch(getMasterDropDownList({ masterName: customerDropdownMaster }));
    dispatch(getMasterDropDownList({ masterName: subcategoryDropdownMaster }));
    dispatch(getDropdownList({ masterName: "category" }));
  }, [dispatch]);

  useEffect(() => {
    let categoryList = category?.map((cat) => cat?.value);
    if (categoryList?.[0] === "*") categoryList = categoryList?.splice(1);

    dispatch(
      getDropdownList({
        masterName: "subcategory",
        query: { categoryList: categoryList },
      })
    );
  }, [category, dispatch]);

  useEffect(() => {
    let subcategoryList = subCategory?.map((subCat) => subCat?.value);
    if (subcategoryList?.[0] === "*")
      subcategoryList = subcategoryList?.splice(1);

    if (subcategoryList?.length) {
      dispatch(
        getDropdownList({
          masterName: "generatedraw_material_colors",
          query: {
            subcategoryList,
          },
        })
      );
    }
  }, [subCategory, dispatch]);

  useEffect(() => {
    dispatch(change(formName, "generatedColors", null));
  }, [dispatch]);

  useEffect(() => {
    if (orderType) {
      onOrderTypeChange(orderType.value);
    }
  }, [orderType, onOrderTypeChange]);

  const handleAddCustomer = () => {
    const selectedCustomers = formValues?.customer || [];
    if (selectedCustomers.length) {
      onAddCustomer(selectedCustomers);
      dispatch(change(formName, "customer", []));
    }
    setShowTable(true);
  };

  const handleAddSubCategory = () => {
    const selectedSubcategories = formValues?.subCategory || [];
    const selectedColors = formValues?.generatedColors || [];
    const totalColor = selectedColors.length;
    setTotalItems(totalColor);

    setColors(selectedColors);
    console.log(selectedColors);
    const newRows = [];

    if (selectedSubcategories?.length && selectedColors?.length) {
      const commonRatioRow = {
        row: "Common Ratio",
        row2: "-",
        mainitem: selectedColors[0].mainitem,
        color: selectedColors[0].color,
      };

      columnDefs.forEach((columnDef) => {
        if (columnDef.children) {
          columnDef.children.forEach((child) => {
            const customerSizeField = child.field;
            commonRatioRow[customerSizeField] = 0;
          });
        }
      });
      newRows.push(commonRatioRow);

      selectedSubcategories.forEach((subCat) => {
        const matchingColors = selectedColors.filter(
          (color) => color.subcategory === subCat.label
        );

        matchingColors.forEach((color) => {
          const newRow = {
            row: subCat.label,
            row2: color.label,
            mainitem: color.mainitem,
            color: color.color,
          };

          columnDefs.forEach((columnDef) => {
            if (columnDef.children) {
              columnDef.children.forEach((child) => {
                const customerSizeField = child.field;
                newRow[customerSizeField] = 0;
              });
            }
          });

          newRows.push(newRow);
        });
      });

      dispatch(change(formName, "generatedColors", []));
      dispatch(change(formName, "subCategory", []));
      setRowData((prevRowData) => [...prevRowData, ...newRows]);
      setRowBucket((prev) => [...prev, newRows]);
      setIsOrderTypeEnabled(true);
    }
    setShowTable(true);
  };

  const handleOrderTypeChange = () => {
    const selectedOrderType = orderType?.value;
    const newRows = [];
    const processedSubcategories = new Set();

    if (selectedOrderType === "categorywise") {
      const categoryGroups = colors.reduce((acc, color) => {
        if (!acc[color.subcategory]) {
          acc[color.subcategory] = [];
        }
        acc[color.subcategory].push(color);
        return acc;
      }, {});

      Object.keys(categoryGroups).forEach((subcategory) => {
        if (!processedSubcategories.has(subcategory)) {
          const matchingColors = categoryGroups[subcategory];
          const colorCount = matchingColors.length;
          setTotalItems(colorCount);

          const ratioRow = {
            row: "Ratio",
            row2: "-",
            ratioId: matchingColors[0].subcategory, // Set as subcategory label
            orderType: "categorywise",
            subcategoryId: matchingColors[0].subcategoryId, // Set dynamically for Ratio row
            mainitemId: matchingColors[0].mainitemId, // Set dynamically for Ratio row
          };

          // Set stored ratios in ratio row
          columnDefs.forEach((columnDef) => {
            if (columnDef.children) {
              columnDef.children.forEach((child) => {
                const customerSizeField = child.field;
                ratioRow[customerSizeField] =
                  storedRatios[customerSizeField] || 0;
              });
            }
          });

          newRows.push(ratioRow);

          const remainderDistributed = {};

          matchingColors.forEach((color, colorIndex) => {
            // Create a new row for each color with dynamic values for mainitemId, subcategoryId, and colorId
            const newRow = {
              row: subcategory,
              row2: color.label,
              ratioId: color.subcategory,
              color: color.color,
              mainitem: color.mainitem,
              orderType: "categorywise",
              colorId: color.colorId, // Set dynamically based on each color
              subcategoryId: color.subcategoryId, // Set dynamically based on each color
              mainitemId: color.mainitemId, // Set dynamically based on each color
            };

            columnDefs.forEach((columnDef) => {
              if (columnDef.children) {
                columnDef.children.forEach((child) => {
                  const customerSizeField = child.field;
                  const ratio = storedRatios[customerSizeField] || 0;
                  const distributedValue = Math.ceil(colorCount * ratio);
                  const remainder = distributedValue % colorCount;
                  const baseValue = Math.floor(distributedValue / colorCount);

                  if (!remainderDistributed[customerSizeField]) {
                    remainderDistributed[customerSizeField] = 0;
                  }

                  let extraValue = 0;
                  if (
                    remainder > 0 &&
                    remainderDistributed[customerSizeField] < remainder
                  ) {
                    extraValue = 1;
                    remainderDistributed[customerSizeField]++;
                  }

                  newRow[customerSizeField] = baseValue + extraValue;
                });
              }
            });

            newRows.push(newRow);
          });

          processedSubcategories.add(subcategory);
        }
      });
    }

    if (selectedOrderType === "itemwise") {
      const mainItemGroups = colors.reduce((acc, color) => {
        const { mainitem } = color;
        if (!acc[mainitem]) {
          acc[mainitem] = [];
        }
        acc[mainitem].push(color);
        return acc;
      }, {});

      Object.keys(mainItemGroups).forEach((mainitem) => {
        const matchingColors = mainItemGroups[mainitem];
        const colorCount = matchingColors.length;
        setTotalItems(colorCount);

        const ratioRow = {
          row: "Ratio",
          row2: "-",
          ratioId: matchingColors[0].mainitem,
          orderType: "itemwise",
          mainitemId: matchingColors[0].mainitemId, // Set dynamically for Ratio row
          subcategoryId: matchingColors[0].subcategoryId, // Set dynamically for Ratio row
        };

        // Set stored ratios in ratio row
        columnDefs.forEach((columnDef) => {
          if (columnDef.children) {
            columnDef.children.forEach((child) => {
              const customerSizeField = child.field;
              ratioRow[customerSizeField] =
                storedRatios[customerSizeField] || 0;
            });
          }
        });

        newRows.push(ratioRow);

        const remainderDistributed = {};

        matchingColors.forEach((color, colorIndex) => {
          // Create a new row for each color with dynamic values for mainitemId, subcategoryId, and colorId
          const newRow = {
            row: color.subcategory,
            row2: color.label,
            color: color.color,
            mainitem: color.mainitem,
            orderType: "itemwise",
            ratioId: color.mainitem,
            colorId: color.colorId, // Set dynamically based on each color
            subcategoryId: color.subcategoryId, // Set dynamically based on each color
            mainitemId: color.mainitemId, // Set dynamically based on each color
          };

          columnDefs.forEach((columnDef) => {
            if (columnDef.children) {
              columnDef.children.forEach((child) => {
                const customerSizeField = child.field;
                const ratio = storedRatios[customerSizeField] || 0;
                const distributedValue = Math.ceil(colorCount * ratio);
                const remainder = distributedValue % colorCount;
                const baseValue = Math.floor(distributedValue / colorCount);

                if (!remainderDistributed[customerSizeField]) {
                  remainderDistributed[customerSizeField] = 0;
                }

                let extraValue = 0;
                if (
                  remainder > 0 &&
                  remainderDistributed[customerSizeField] < remainder
                ) {
                  extraValue = 1;
                  remainderDistributed[customerSizeField]++;
                }

                newRow[customerSizeField] = baseValue + extraValue;
              });
            }
          });

          newRows.push(newRow);
        });
      });
    }

    setRowData([...newRows]);
    onOrderTypeChange(selectedOrderType);
  };

  console.log(rowData);
  const orderTypeOptions = [
    { label: "Category wise", value: "categorywise" },
    { label: "Item wise", value: "itemwise" },
  ];

  const generatePayload = (rowData, columnDefs) => {
    return columnDefs
      .filter((colDef) => colDef.customerId)
      .map((colDef) => {
        const customerId = colDef.customerId;
        const headerName = colDef.headerName;

        const saleOrderRatio = {};
        const saleOrderSubForm = [];

        rowData.forEach((row) => {
          if (row.row === "Ratio") {
            Object.entries(row).forEach(([key, value]) => {
              if (key.startsWith(headerName)) {
                const sizeKey = key.replace(headerName, "size");
                saleOrderRatio[sizeKey] = value;
              }
            });
          } else {
            const subFormEntry = {
              subcategoryId: row.subcategoryId,
              colorId: row.colorId,
              mainitemId: row.mainitemId,
            };

            let sizeTotal = 0;
            Object.entries(row).forEach(([key, value]) => {
              if (key.startsWith(headerName)) {
                const sizeKey = key.replace(headerName, "size");
                subFormEntry[sizeKey] = value;
                sizeTotal += value;
              }
            });

            subFormEntry.sizeTotal = sizeTotal;

            saleOrderSubForm.push(subFormEntry);
          }
        });

        return {
          id: customerId.toString(),
          partyId: customerId.toString(),
          orderType: rowData.find((row) => row.orderType)?.orderType || "",
          saleOrderSubForm,
          saleOrderRatio,
        };
      });
  };

  const saleAddPayload = generatePayload(rowData, columnDefs);

  function handleSubmit() {
    const sizeLabelMap = {
      size34: "size85",
      size36: "size90",
      size38: "size95",
      size40: "size100",
      size42: "size105",
      size44: "size110",
      size46: "size115",
      size48: "size120",
    };

    const saleOrderPayload = saleAddPayload.map((customerData) => {
      const totalPcs = customerData.saleOrderSubForm.reduce(
        (sum, item) => sum + item.sizeTotal,
        0
      );

      return {
        partyId: customerData.partyId,
        totalPcs: totalPcs.toString(),
        orderType: customerData.orderType,
        saleOrderSubForm: customerData.saleOrderSubForm.map((item) => {
          const { subcategoryId, colorId, mainitemId, sizeTotal, ...sizes } =
            item;

          const mappedSizes = Object.fromEntries(
            Object.entries(sizes).map(([sizeKey, sizeValue]) => [
              sizeLabelMap[sizeKey] || sizeKey,
              sizeValue.toString(),
            ])
          );

          return {
            totalPcs: sizeTotal.toString(),
            subcategoryId: subcategoryId.toString(),
            colorId: colorId.toString(),
            mainitemId: mainitemId.toString(),
            ...mappedSizes,
          };
        }),
        saleOrderRatio: Object.fromEntries(
          Object.entries(customerData.saleOrderRatio).map(
            ([sizeKey, sizeValue]) => [
              sizeLabelMap[sizeKey] || sizeKey,
              sizeValue.toString(),
            ]
          )
        ),
      };
    });

    dispatch(
      addSaleOrder({
        formData: saleOrderPayload,
        configType: "application/json",
      })
    );
  }

  const generateStockPayload = (rowData) => {
    const payload = [];
    const uniqueItems = new Set(); // To track unique entries

    rowData.forEach((row) => {
      if (row.mainitem && row.color) {
        const item = `${row.mainitem}-${row.color}`;

        if (!uniqueItems.has(item)) {
          payload.push({
            main_item: row.mainitem.toString(),
            color_name: row.color,
          });
          uniqueItems.add(item);
        }
      }
    });

    return payload;
  };

  const stockPayload = generateStockPayload(rowData);
  console.log("stock paylaod", stockPayload);

  const handleStockSubmit = () => {
    const columns = [
      {
        title: "Sub-Category",
        dataIndex: "subcategory",
        key: "subcategory",
        width: 120,
      },
      { title: "Color", dataIndex: "color", key: "color", width: 100 },
      {
        title: "Main Item",
        dataIndex: "mainItem",
        key: "mainItem",
        width: 100,
      },

      { title: "85", dataIndex: "size85", key: "size85", width: 60 },
      { title: "90", dataIndex: "size90", key: "size90", width: 60 },
      { title: "95", dataIndex: "size95", key: "size95", width: 60 },
      { title: "100", dataIndex: "size100", key: "size100", width: 60 },
      { title: "105", dataIndex: "size105", key: "size105", width: 60 },
      { title: "110", dataIndex: "size110", key: "size110", width: 60 },
      { title: "115", dataIndex: "size115", key: "size115", width: 60 },
      { title: "120", dataIndex: "size120", key: "size120", width: 60 },
    ];

    if (stockPayload.length === 0) {
      setTableData({ columns, rows: [] });
      setIsModalVisible(true);
    } else {
      dispatch(
        getSaleOrderStock({
          formData: stockPayload,
          configType: "application/json",
        })
      )
        .then((response) => {
          console.log(response);

          const rows = response.payload.map((item, index) => ({
            ...item,
            key: index,
          }));
          setTableData({ columns, rows });
          setIsModalVisible(true);
        })
        .catch((error) => {
          console.error("Error fetching stock data:", error);
          setTableData({ columns, rows: [] });
          setIsModalVisible(true);
        });
    }
  };
  const handleCloseModal = () => {
    setIsModalVisible(false);
    setTableData({ columns: [], rows: [] });
  };

  return (
    <div className="flex justify-between">
      <div className="flex">
        <div className="flex">
          <div>
            <Field
              component={ReduxFormSelectField}
              label="Order Type"
              name="orderType"
              options={orderTypeOptions}
              placeholder="Select Order type"
              disabled={!isOrderTypeEnabled}
            />
            <Button onClick={handleOrderTypeChange}>Apply Order Type</Button>
          </div>
        </div>

        <div className="md:mr-10 mb-5">
          <Field
            component={ReduxFormSelectField}
            label="Customer List"
            name="customer"
            options={customerDropDown}
            placeholder="Select Customer list"
            isMulti
          />
          <Button onClick={handleAddCustomer}>Add Customer</Button>
        </div>

        <div className="md:mr-10 mb-5">
          <Field
            component={ReduxFormSelectField}
            label="Sub-Category"
            name="subCategory"
            options={subCategoryOptions}
            placeholder="Select Subcategories"
            isLoading={isFetchingDropdownList}
            isMulti
          />
        </div>
        <div>
          <Field
            component={ReduxFormSelectField}
            label="Generated Colors"
            name="generatedColors"
            options={generatedRawMaterialColorsOptions}
            placeholder="Select Colors"
            isLoading={isFetchingDropdownList}
            isMulti
            disabled={!subCategory?.length}
          />
          <Button className="mt-2" onClick={handleAddSubCategory}>
            Add Sub-Category
          </Button>
        </div>

        <Button
          className="ml-10 text-center"
          disabled={!orderType}
          onClick={handleSubmit}
        >
          Submit
        </Button>
      </div>

      <Modal
        title="Ready Stock Information"
        visible={isModalVisible}
        onCancel={handleCloseModal}
        width={900}
        footer={[
          <Button key="close" onClick={handleCloseModal}>
            Close
          </Button>,
        ]}
      >
        <Table
          columns={tableData.columns}
          dataSource={tableData.rows}
          pagination={false}
          bordered
        />
      </Modal>

      <Button className="ml-10 text-center" onClick={handleStockSubmit}>
        Show Stock
      </Button>
    </div>
  );
}

export default reduxForm({
  form: "saleorder-filter",
  enableReinitialize: true,
})(SaleOrderFilter);
