import { Button } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Field, change, getFormValues, reduxForm } from "redux-form";
import {
  getDropdownList,
  masterSelectors,
  getLedger,
  ledgerExcelList,
} from "slices/masterSlice";
import { ReduxFormTextField } from "utils/ReduxFormTextField";
import { ReduxFormSelectField2 } from "utils/ReduxFormSelectField";

function Filter({
  setLedger,
  ledgerMain,
  masterName,
  handleSubmit,
  isLoading,
  setIsLoading,
}) {
  const formValues = useSelector(getFormValues("Ledger"));
  const dispatch = useDispatch();

  const [show, setShow] = useState(false);

  const mainItemsOptions = useSelector(
    masterSelectors?.getMainItemsDropdownList
  );

  const colorsOptions = useSelector(masterSelectors?.getColorsDropdownList);

  useEffect(() => {
    const mainitemdrop = dispatch(getDropdownList({ masterName: "mainitem" }));
    dispatch(getDropdownList({ masterName: "colors" }));
    console.log(mainitemdrop);
  }, [dispatch]);

  useEffect(() => {
    setLedger(ledgerMain);
    setIsLoading(false);
  }, [ledgerMain, setLedger]);

  const isAnyFilterApplied = () => {
    return !!(
      formValues?.startDate ||
      formValues?.endDate ||
      (formValues?.mainItem && formValues.mainItem.length > 0) ||
      (formValues?.color && formValues.color.length > 0)
    );
  };

  const handleFilter = (values) => {
    setIsLoading(true);
    const val = {
      startDate: values?.startDate || "",
      endDate: values?.endDate || "",
      mainItem: values?.mainItem?.map((item) => item?.label) || [],
      color: values?.color?.map((item) => item?.label) || [],
    };

    dispatch(
      getLedger({
        masterName: "ledger",
        formdata: val,
      })
    );
  };

  const handleResetFilter = () => {
    setIsLoading(true);
    const val = {
      startDate: "",
      endDate: "",
      mainItem: [],
      color: [],
      dispatchno: [],
      party: [],
    };

    setLedger([]); // Set empty array instead of ledgerMain

    dispatch(change("Ledger", "startDate", null));
    dispatch(change("Ledger", "endDate", null));
    dispatch(change("Ledger", "mainItem", null));
    dispatch(change("Ledger", "color", null));

    setIsLoading(false);
  };

  const handleReport = () => {
    const val = {
      startDate: formValues?.startDate || "",
      endDate: formValues?.endDate || "",
      mainItem: formValues?.mainItem?.map((item) => item?.label) || [],
      color: formValues?.color?.map((item) => item?.label) || [],
    };

    dispatch(
      ledgerExcelList({
        masterName: "export/ledger",
        formData: val,
      })
    );
  };

  return (
    <div>
      {show ? (
        <>
          <div className="flex justify-end items-center md:mr-10">
            <Button
              className="text-redBase border-redBase"
              onClick={() => setShow(false)}
            >
              Hide Filter
            </Button>
          </div>
          <div className="md:flex block md:flex-wrap">
            <div className="md:mr-10 mb-5">
              <Field
                label="Start Date"
                name="startDate"
                component={ReduxFormTextField}
                type="date"
                placeholder="Enter Date"
              />
            </div>
            <div>
              <Field
                label="End Date"
                name="endDate"
                component={ReduxFormTextField}
                type="date"
                placeholder="Enter Date"
              />
            </div>
            <div className="md:mr-10 mb-5">
              <Field
                component={ReduxFormSelectField2}
                label="Main Item"
                name="mainItem"
                options={mainItemsOptions}
                placeholder="Select Main Items"
                isMulti
              />
            </div>
            <div className="md:mr-10 mb-5">
              <Field
                component={ReduxFormSelectField2}
                label="Color"
                name="color"
                placeholder="Enter color"
                options={colorsOptions}
                isMulti
              />
            </div>

            <div className="md:mr-10 ">
              <Button
                className="mt-2 text-primaryBase border-primaryDark font-semibold"
                onClick={handleSubmit(handleFilter)}
                disabled={!isAnyFilterApplied()}
              >
                Apply Filters
              </Button>
            </div>
            <div className="md:mr-10 ">
              <Button
                className="mt-2 text-primaryBase border-primaryDark font-semibold"
                onClick={handleResetFilter}
              >
                Reset Filters
              </Button>
            </div>
            <div className="md:mr-10 ">
              <Button
                className="mt-2 text-primaryBase border-primaryDark font-semibold"
                onClick={handleReport}
                disabled={!isAnyFilterApplied()}
              >
                Generate Report
              </Button>
            </div>
          </div>
        </>
      ) : (
        <div className="flex justify-end items-center md:mr-10">
          <Button
            className="text-primaryBase border-primaryDark"
            onClick={() => setShow(true)}
          >
            Show Filter
          </Button>
        </div>
      )}
    </div>
  );
}

export default reduxForm({
  form: "Ledger",
  enableReinitialize: true,
})(Filter);
