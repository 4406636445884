import React, { useState, useEffect } from "react";
import { Pagination, Table, Button, Tooltip } from "antd";
import { Helmet } from "react-helmet";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Field, formValueSelector, reduxForm } from "redux-form";
import { getMasterList, masterSelectors } from "slices/masterSlice";

import StatusSegments from "components/StatusSegments";
import useSearchFilter from "customHooks/useSearchFilter";
import { TfiReload } from "react-icons/tfi";
import { formatDateTime } from "utils/dateTime";

const masterName = "size";
export const masterStatuses = [
  { label: "Active", value: 1 },
  { label: "Inactive", value: 0 },
];

const Size = () => {
  const { status } = useParams();
  const formSelector = formValueSelector(masterName);
  const dispatch = useDispatch();
  const loading = useSelector(masterSelectors?.getIsFetchingMasterList);
  const list = useSelector(masterSelectors?.getMainItemsMasterList);
  const { getColumnSearchProps, tableFilters } = useSearchFilter();

  const [data, setData] = useState({});
  const [listData, setListData] = useState([]);

  // Fetch list data
  const getListData = () => {
    dispatch(
      getMasterList({
        masterName,
        query: {
          status: status || 1,
          ...tableFilters,
        },
      })
    ).then((response) => {
      console.log("API Response:", response.payload);
      if (response?.payload?.data?.data) {
        setListData(response.payload.data.data); // Ensure correct data assignment
      }
    });
  };

  useEffect(() => {
    getListData();
  }, [status, tableFilters, dispatch]);

  const handleSetData = (value) => {
    let temp = { ...value };
    temp.category_name = value?.subcategory_id;
    temp.sub_category_name = value?.subcategory_id;
    setData(temp);
  };

  const columns = [
   
    {
      title: "Category Name",
      dataIndex: ["categoryId", "name"], // Extracting nested category name
      key: "category_name",
      responsive: ["sm"],
      render: (value, data) => (
        <p onDoubleClick={() => handleSetData(data)} className="cursor-pointer">
          {value || "-"}
        </p>
      ),
    },
    {
      title: "Size",
      dataIndex: "size", // Direct access to `size`
      key: "size",
      responsive: ["sm"],
      render: (value, data) => (
        <p onDoubleClick={() => handleSetData(data)} className="cursor-pointer">
          {value || "-"}
        </p>
      ),
    },
  ];

  return (
    <>
      <Helmet>
        <title>Size</title>
      </Helmet>
      <div className="flex justify-between items-center mb-2">
        <h1 className="text-2xl">Size List</h1>
        <div className="flex gap-5">
          <StatusSegments
            masterName={masterName + "s"}
            options={masterStatuses}
            activeStatus={status} // Pass the active status to StatusSegments
          />
          <Tooltip title="Reload">
            <Button
              type="default"
              size="small"
              loading={loading}
              disabled={loading}
              onClick={() => getListData()}
            >
              <TfiReload />
            </Button>
          </Tooltip>

          <Link to="/size/add" target="_blank">
            <Button type="primary" size="small" className="w-[80px]">
              Add New
            </Button>
          </Link>
        </div>
      </div>

      <Table
        dataSource={listData} // Ensuring correct data usage
        columns={columns}
        rowKey={(item) => item?.id}
        loading={loading}
        scroll={{ y: 440 }}
        bordered
        style={{ minHeight: "400px" }}
        pagination={{
          pageSize: 100,
          showSizeChanger: false,
        }}
      />
    </>
  );
};

export default reduxForm({
  form: masterName,
  enableReinitialize: true,
})(Size);
