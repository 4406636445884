import React, { useState, useCallback, useEffect } from "react";
import { Pagination, Table, Button, Modal, Tooltip, message } from "antd";
import { Helmet } from "react-helmet";
import { renderMobileColumn } from "utils/renderMobileColumn";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Field, formValueSelector, reduxForm } from "redux-form";
import {
  getMasterList,
  masterSelectors,
  ImportExcelFile,
  ExportExcelFile,
  editMasterListMain,
  exportChalan,
  exportExcel,
  exportReadyStock,
  uploadNewItemExcel,
  generateBarcode,
  printBarcode,
} from "slices/masterSlice";

import useMasterLogic from "customHooks/useMasterLogic";
import { ReduxFormFileField } from "utils/ReduxFormFileField";
import { TfiReload } from "react-icons/tfi";
import StatusSegments from "components/StatusSegments";
import useSearchFilter from "customHooks/useSearchFilter";
import { renderActions } from "utils/renderActions";
import MainItemDetails from "./MainItemDetails";
import { formatDateTime } from "utils/dateTime";

const masterName = "newMainitem";
export const masterStatuses = [
  { label: "Active", value: 1 },
  { label: "Inactive", value: 0 },
];

const MainItem = () => {
  const { status } = useParams();
  const formSelector = formValueSelector(masterName);
  const dispatch = useDispatch();
  const loading = useSelector(masterSelectors?.getIsFetchingMasterList);
  const importing = useSelector(masterSelectors?.getIsImportingExcel);
  const exporting = useSelector(masterSelectors?.getIsExportingExcel);
  const isExportExcel = useSelector(masterSelectors?.getIsExportExcel);
  const isExportChalan = useSelector(masterSelectors?.getIsExportChalan);
  const isExportingReadyStock = useSelector(masterSelectors?.getIsExportReady);
  const list = useSelector(masterSelectors?.getNewMainItemMasterList);
  console.log("List data:", list);
  const { pageSize, totalElements, currentPage } = useSelector(
    masterSelectors?.getMainItemsNewPagination
  );

  console.log({ pageSize, totalElements, currentPage });

  const { getColumnSearchProps, tableFilters } = useSearchFilter();

  const [barcodeGeneratedItems, setBarcodeGeneratedItems] = useState({});

  const [itemColorCounts, setItemColorCounts] = useState({});

  const [hasNewColors, setHasNewColors] = useState(false);

  const getListData = useCallback(
    (pageNum = 1, itemsPerPage = 10) => {
      dispatch(
        getMasterList({
          masterName,
          query: {
            page: pageNum,
            pageSize: itemsPerPage,
            status: status,
            ...tableFilters,
          },
        })
      );
    },
    [status, tableFilters, dispatch]
  );

  useEffect(() => {
    console.log("Updated status:", status);
    getListData(1, 10);
  }, [status, tableFilters]);

  useEffect(() => {
    console.log("Status from useParams:", status);
  }, [status]);

  // Load barcode data from localStorage on component mount
  useEffect(() => {
    const savedBarcodeData = localStorage.getItem("barcodeGeneratedItems");
    if (savedBarcodeData) {
      setBarcodeGeneratedItems(JSON.parse(savedBarcodeData));
    }
  }, []);

  useEffect(() => {
    if (Object.keys(barcodeGeneratedItems).length > 0) {
      localStorage.setItem(
        "barcodeGeneratedItems",
        JSON.stringify(barcodeGeneratedItems)
      );
    }
  }, [barcodeGeneratedItems]);

  useEffect(() => {
    if (list && list.length > 0) {
      const colorCountsMap = {};
      list.forEach((item) => {
        colorCountsMap[item.id] = item.colorsCount || 0;
      });
      setItemColorCounts(colorCountsMap);
    }
  }, [list]);

  const { handleEditMaster } = useMasterLogic(getListData, masterName);

  const { handlePageChange } = useMasterLogic(getListData, masterName);

  const [data, setData] = useState({});
  const [type, setType] = useState("update");

  const handleSetData = (value) => {
    console.log(value);
    setType("update");
    let temp = { ...value };
    temp.category_name = value?.subcategory_id;
    temp.sub_category_name = value?.subcategory_id;
    // temp.hsnId = value?.hsn_code
    setData(temp);
  };

  const handleDuplicate = (value) => {
    setType("new");
    let temp = { ...value };
    temp.category_name = value?.subcategory_id;
    temp.sub_category_name = value?.subcategory_id;
    // temp.hsnId = value?.hsn_code
    setData(temp);

    setHasNewColors(true);
  };

  const handleBarcodeGenerate = async (value) => {
    console.log("hitting api for ", value.id);
    try {
      const res = await dispatch(
        generateBarcode({
          masterName: `generateBarcode/${value.id}`,
        })
      );

      console.log(res);
      if (res?.type === "master/barcode/fulfilled") {
        const successMessage =
          res.payload.data?.message ||
          res.payload?.message ||
          "Barcode generated successfully";
        message.success(successMessage);

        const generatedCount = parseInt(
          successMessage.match(/(\d+) new barcodes/)?.[1] || "0"
        );

        setBarcodeGeneratedItems((prev) => ({
          ...prev,
          [value.id]: {
            generated: true,
            count: generatedCount,
          },
        }));

        getListData(currentPage, pageSize);
      } else if (res?.type?.endsWith("/rejected")) {
        const errorMsg =
          res.payload?.response?.data?.message || "Failed to generate Barcode";
        message.error(errorMsg);
      }
    } catch (error) {
      console.error("Failed to generate Barcode");
    }
  };

  const handlePrint = async (value) => {
    console.log("Printing barcode for item:", value.id);
    try {
      const res = await dispatch(
        printBarcode({
          masterName: `printBarcode/${value.id}`,
        })
      );

      if (res?.type === "master/printBarcode/fulfilled") {
        message.success("Barcode downloaded successfully");
      } else if (res?.type?.endsWith("/rejected")) {
        const errorMsg =
          res.payload?.response?.data?.message || "Failed to print barcode";
        message.error(errorMsg);
      }
    } catch (error) {
      console.error("Failed to print barcode:", error);
      message.error("Failed to print barcode");
    }
  };

  const handleImport = () => {
    dispatch(
      ImportExcelFile({
        masterName: "mainitem/export/excel",
        name: "mainitem",
      })
    );
  };
  const [show, setShow] = useState(false);
  const excelFile = useSelector((state) => formSelector(state, "item_excel"));
  const handleExport = () => {
    setShow(false);
    const form = new FormData();
    form.append("file", excelFile);
    dispatch(
      ExportExcelFile({
        masterName: "mainitem/upload/import",
        formData: form,
        configType: "multipart/form-data",
      })
    );
  };

  const columns = [
    {
      title: "Created at",
      dataIndex: "createdAt",
      key: "createdAt",
      responsive: ["sm"],
      render: (value, data) => (
        <p onDoubleClick={() => handleSetData(data)} className="cursor-pointer">
          {formatDateTime(value) || "-"}
        </p>
      ),
    },
    {
      title: "Main Item",
      render: (data) => {
        const primaryText = data?.name || "-";
        const secondaryText = `${data?.categoryId?.name || "-"}, ${
          data?.subcategoryId?.name || "-"
        }`;
        return renderMobileColumn(primaryText, secondaryText);
      },
      responsive: ["xs"],
      ...getColumnSearchProps("Search", "universal_search"),
    },
    {
      title: "Item Name",
      dataIndex: "name",
      key: "name",
      responsive: ["sm"],
      render: (value, data) => (
        <p onDoubleClick={() => handleSetData(data)} className="cursor-pointer">
          {value || "-"}
        </p>
      ),
      onFilter: (value, record) => record.name.startsWith(value),
      sorter: (a, b) => a.name.localeCompare(b.name),
      filterSearch: true,
    },
    {
      title: "Category Name",
      dataIndex: ["categoryId", "name"],
      key: "categoryId",
      responsive: ["sm"],
      render: (value, data) => (
        <p onDoubleClick={() => handleSetData(data)} className="cursor-pointer">
          {value || "-"}
        </p>
      ),
      onFilter: (value, record) => record.categoryId?.name.startsWith(value),
      filterSearch: true,
    },
    {
      title: "Subcategory Name",
      dataIndex: ["subcategoryId", "name"], // Access nested subcategory name
      key: "subcategoryId",
      responsive: ["sm"],
      render: (value, data) => (
        <p onDoubleClick={() => handleSetData(data)} className="cursor-pointer">
          {value || "-"}
        </p>
      ),
      onFilter: (value, record) => record.subcategoryId?.name.startsWith(value),
      sorter: (a, b) =>
        a.subcategoryId?.name.localeCompare(b.subcategoryId?.name),
      filterSearch: true,
    },
    {
      title: "MRP",
      dataIndex: "mrp",
      key: "mrp",
      responsive: ["sm"],
      render: (value, data) => (
        <p onDoubleClick={() => handleSetData(data)} className="cursor-pointer">
          {value ? value.toFixed(2) : "-"}
        </p>
      ),
    },
    {
      title: "WSP",
      dataIndex: "wsp",
      key: "wsp",
      responsive: ["sm"],
      render: (value, data) => (
        <p onDoubleClick={() => handleSetData(data)} className="cursor-pointer">
          {value ? value.toFixed(2) : "-"}
        </p>
      ),
    },
    {
      title: "Raw Material code",
      dataIndex: "rawMaterialCode",
      key: "rawMaterialCode",
      responsive: ["sm"],
      render: (value, data) => (
        <p onDoubleClick={() => handleSetData(data)} className="cursor-pointer">
          {value || "-"}
        </p>
      ),
    },
    {
      title: "Raw Material Comment",
      dataIndex: "rawMaterialComment",
      key: "rawMaterialComment",
      responsive: ["sm"],
      render: (value, data) => (
        <p onDoubleClick={() => handleSetData(data)} className="cursor-pointer">
          {value || "-"}
        </p>
      ),
    },
    {
      title: "Comment",
      dataIndex: "comment",
      key: "comment",
      responsive: ["sm"],
      render: (value, data) => (
        <p onDoubleClick={() => handleSetData(data)} className="cursor-pointer">
          {value || "-"}
        </p>
      ),
    },

    {
      title: "Actions",
      key: "Actions",
      dataIndex: "action",
      width: "20%",
      render: (_, data, index) => {
        const barcodeInfo = barcodeGeneratedItems[data.id];
        const hasBarcode = barcodeInfo?.generated;
        const generatedCount = barcodeInfo?.count || 0;
        const totalColors = itemColorCounts[data.id] || 0;

        const items = [
          {
            label: "Duplicate",
            onClick: () => handleDuplicate(data),
          },
        ];

        // If we have barcode info and all colors have barcodes
        if (hasBarcode && generatedCount >= totalColors) {
          items.push({
            label: "Print",
            onClick: () => handlePrint(data),
          });
        }
        // If we have barcode info but not all colors have barcodes
        else if (hasBarcode && generatedCount < totalColors) {
          items.push({
            label: "Generate Barcode",
            onClick: () => handleBarcodeGenerate(data),
          });
          items.push({
            label: "Print",
            onClick: () => handlePrint(data),
          });
        }
        // If no barcodes generated yet
        else {
          items.push({
            label: "Generate Barcode",
            onClick: () => handleBarcodeGenerate(data),
          });
        }

        return renderActions(items);
      },
    },
  ];

  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
    const form = new FormData();
    form.append("file", excelFile);
    dispatch(
      uploadNewItemExcel({
        masterName,
        formData: form,
        configType: "multipart/form-data",
      })
    );
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Helmet>
        <title>Main Items</title>
      </Helmet>
      <div className="flex justify-between items-center mb-2">
        <h1 className="text-2xl">Main Item List</h1>
        <div className="flex gap-5">
          <StatusSegments masterName={masterName} options={masterStatuses} />
          <Tooltip title="Reload">
            <Button
              type="default"
              size="small"
              loading={loading}
              disabled={loading}
              onClick={() => getListData(1, 10)}
            >
              <TfiReload />
            </Button>
          </Tooltip>

          <Button
            onClick={() => dispatch(exportExcel({}))}
            loading={isExportExcel}
            type="default"
            size="small"
          >
            Export Excel
          </Button>
          <Button
            onClick={() => dispatch(exportChalan({}))}
            loading={isExportChalan}
            type="default"
            size="small"
          >
            Export Challan
          </Button>
          <Button
            onClick={() => dispatch(exportReadyStock({}))}
            loading={isExportingReadyStock}
            type="default"
            size="small"
          >
            Export Ready
          </Button>
          <Button onClick={showModal} type="default" size="small">
            Upload New Item
          </Button>
          <Link to="/newMainitem/add" target="_blank">
            <Button type="primary" size="small" className="w-[80px]">
              Add New
            </Button>
          </Link>
        </div>
      </div>
      <Table
        dataSource={list}
        columns={columns}
        rowKey={(item) => item?.id}
        loading={loading}
        scroll={{ y: 440 }}
        bordered
        style={{ minHeight: "400px" }}
        pagination={false}
      />
      {list?.length ? (
        <div className="flex justify-end">
          <Pagination
            pageSize={pageSize}
            total={totalElements}
            current={currentPage}
            onChange={handlePageChange}
          />
        </div>
      ) : null}
      <div className="mb-5"></div>
      <MainItemDetails
        data={data}
        setData={setData}
        type={type}
        setType={setType}
      />
      <Modal
        title="Upload Excel File"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Field
          name="item_excel"
          component={ReduxFormFileField}
          className="mt-1"
          placeholder="Item Image"
          itemname="excel file"
        />
        <p className="mt-2 text-lg font-bold">{excelFile?.name}</p>
      </Modal>
    </>
  );
};

export default reduxForm({
  form: masterName,
  enableReinitialize: true,
})(MainItem);
