import { Modal } from "antd";
import React, { useRef, useState, useEffect } from "react";
import { useReactToPrint } from "react-to-print";
import { useDispatch, useSelector } from "react-redux";
import { GetDispatchPrintData, masterSelectors } from "slices/masterSlice";

const OutwardPrintV2 = ({
  modalPrint,
  setModalPrint,
  setDispatchRowSelections,
  setExpandedDispatchRowSelections,
  setRevertSelections,
  setStatusDispatch,
  setStatusPacking,
  selectedId,
}) => {
  const dispatch = useDispatch();
  const isFetching = useSelector(masterSelectors?.getDispatchPrintLoading);
  const printData = useSelector(masterSelectors?.getDispatchPrintData);
  const totalPages = printData.totalPages;
  useEffect(() => {
    if (selectedId?.length) {
      dispatch(
        GetDispatchPrintData({
          masterName: "DispatchStock/print",
          formData: { dispatchOverviewId: selectedId },
        })
      );
    }
  }, [selectedId]);

  const [confirmLoading, setConfirmLoading] = useState(false);
  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const handleSubmit = () => {
    handlePrint();
    setConfirmLoading(true);
    setTimeout(() => {
      setConfirmLoading(false);
      setModalPrint(false);
      setExpandedDispatchRowSelections({});
      setDispatchRowSelections({});
      setStatusDispatch([]);
      setStatusPacking([]);
      setRevertSelections({});
    }, 500);
  };

  const groupByMainItem = (outwardDetails) => {
    const groupedItems = {};

    outwardDetails.forEach((detail) => {
      // Group subDetails by mainItem
      detail.subDetails.forEach((subDetail) => {
        const mainItem = subDetail.mainItem;
        if (!groupedItems[mainItem]) {
          groupedItems[mainItem] = {
            items: [],
            // Find the corresponding total for this mainItem from the total array
            total: detail.total.find((t) => t.mainItem === mainItem) || {
              size85: 0,
              size90: 0,
              size95: 0,
              size100: 0,
              size105: 0,
              size110: 0,
              size115: 0,
              size120: 0,
              total: 0,
            },
          };
        }

        groupedItems[mainItem].items.push({
          ...subDetail,
          outwardDate: detail.outwardDate,
          outwardNo: detail.outwardNo,
        });
      });
    });

    return groupedItems;
  };

  return (
    <Modal
      title="Print"
      open={modalPrint}
      onOk={handleSubmit}
      onCancel={() => setModalPrint(false)}
      width={1500}
      confirmLoading={confirmLoading}
    >
      <p>Outward Dispatch Print</p>
      <hr />
      <div className="p-5" ref={componentRef}>
        {printData?.data?.map((dispatch, index) => {
          const groupedItems = groupByMainItem(dispatch.outwardDetails);

          return (
            <div key={index} className="mb-4 p-5">
              <table className="table1">
                <thead className="table1">
                  <tr className="table1">
                    <th style={{ width: "220px", paddingLeft: "20px" }}>
                      {dispatch.partyName}
                    </th>
                    <th style={{ width: "220px" }}>
                      Dispatch Date: {dispatch.dispatchDate}
                    </th>
                    <th style={{ width: "220px" }}>
                      Dispatch No: {dispatch.dispatchNo}
                    </th>
                    <th>
                      <div className="">
                        <h3 style={{ width: "80px" }} className="text-center">
                          Comment:
                        </h3>
                        <input
                          type="text"
                          name="comment"
                          id="comment"
                          className="w-full border-0 border-b-2 text-center py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          style={{ width: "300px" }}
                        />
                      </div>
                    </th>
                  </tr>
                  <tr className="table1">
                    <th className="table1 text-center">OUTWARD DATE</th>
                    <th className="table1 text-center">OUTWARD NO.</th>
                    <th className="table1 text-center">COLOR</th>
                    <th className="table1 text-center">85</th>
                    <th className="table1 text-center">90</th>
                    <th className="table1 text-center">95</th>
                    <th className="table1 text-center">100</th>
                    <th className="table1 text-center">105</th>
                    <th className="table1 text-center">110</th>
                    <th className="table1 text-center">115</th>
                    <th className="table1 text-center">120</th>
                    <th className="table1 text-center">TOTAL</th>
                  </tr>
                </thead>
                <tbody>
                  {Object.entries(groupedItems).map(
                    ([mainItem, group], groupIndex) => (
                      <React.Fragment key={groupIndex}>
                        {/* Display items for this mainItem */}
                        {group.items.map((item, itemIndex) => (
                          <tr
                            key={`${groupIndex}-${itemIndex}`}
                            className="table1"
                          >
                            <td className="table1 text-center">
                              {item.outwardDate}
                            </td>
                            <td className="table1 text-center">
                              {item.outwardNo}
                            </td>
                            <td className="table1 text-center">{`${mainItem}-${item.color}`}</td>
                            <td className="table1 text-center">
                              {item.size85 || 0}
                            </td>
                            <td className="table1 text-center">
                              {item.size90 || 0}
                            </td>
                            <td className="table1 text-center">
                              {item.size95 || 0}
                            </td>
                            <td className="table1 text-center">
                              {item.size100 || 0}
                            </td>
                            <td className="table1 text-center">
                              {item.size105 || 0}
                            </td>
                            <td className="table1 text-center">
                              {item.size110 || 0}
                            </td>
                            <td className="table1 text-center">
                              {item.size115 || 0}
                            </td>
                            <td className="table1 text-center">
                              {item.size120 || 0}
                            </td>
                            <td className="table1 text-center">
                              {item.total || 0}
                            </td>
                          </tr>
                        ))}
                        {/* Display pre-calculated total for this mainItem */}
                        <tr className="table1">
                          <td
                            colSpan="3"
                            className="table1 text-center font-bold"
                          >
                            Total for {mainItem}
                          </td>
                          <td className="table1 text-center font-bold">
                            {group.total.size85 || 0}
                          </td>
                          <td className="table1 text-center font-bold">
                            {group.total.size90 || 0}
                          </td>
                          <td className="table1 text-center font-bold">
                            {group.total.size95 || 0}
                          </td>
                          <td className="table1 text-center font-bold">
                            {group.total.size100 || 0}
                          </td>
                          <td className="table1 text-center font-bold">
                            {group.total.size105 || 0}
                          </td>
                          <td className="table1 text-center font-bold">
                            {group.total.size110 || 0}
                          </td>
                          <td className="table1 text-center font-bold">
                            {group.total.size115 || 0}
                          </td>
                          <td className="table1 text-center font-bold">
                            {group.total.size120 || 0}
                          </td>
                          <td className="table1 text-center font-bold">
                            {group.total.total || 0}
                          </td>
                        </tr>
                      </React.Fragment>
                    )
                  )}
                  {/* Display final total */}
                  <tr>
                    <td
                      colSpan="3"
                      style={{ fontWeight: "bold", textAlign: "center" }}
                    >
                      Final Total
                    </td>
                    <td className="table1 text-center font-bold">
                      {dispatch.finalTotal.size85 || 0}
                    </td>
                    <td className="table1 text-center font-bold">
                      {dispatch.finalTotal.size90 || 0}
                    </td>
                    <td className="table1 text-center font-bold">
                      {dispatch.finalTotal.size95 || 0}
                    </td>
                    <td className="table1 text-center font-bold">
                      {dispatch.finalTotal.size100 || 0}
                    </td>
                    <td className="table1 text-center font-bold">
                      {dispatch.finalTotal.size105 || 0}
                    </td>
                    <td className="table1 text-center font-bold">
                      {dispatch.finalTotal.size110 || 0}
                    </td>
                    <td className="table1 text-center font-bold">
                      {dispatch.finalTotal.size115 || 0}
                    </td>
                    <td className="table1 text-center font-bold">
                      {dispatch.finalTotal.size120 || 0}
                    </td>
                    <td className="table1 text-center font-bold">
                      {dispatch.finalTotal.total || 0}
                    </td>
                  </tr>
                </tbody>
              </table>
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  right: 5,
                  height: `${totalPages * 768}px`,
                }}
              >
                {Array.from({ length: totalPages }).map((item, index) => (
                  <div style={{ height: "870px" }} key={index}>
                    <p
                      className="rounded-md p-1"
                      style={{ background: "lightgrey" }}
                    >
                      Page {index + 1} of {totalPages}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          );
        })}
      </div>
    </Modal>
  );
};

export default OutwardPrintV2;
